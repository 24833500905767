
import api from "../config/api";
import BaseService from './base-service';

export default class ReportsService extends BaseService {

    // Get a report of the specified type ====================================================================================================

    /**
     * @param data the parameters for the report
     * @param type the report type
     * @description Gets a report of the specified type
     */

     static getReport(data, type) {
        const url = `/reports/${type}`;

        return api.get(url,{
            params: data,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        });
    }

}
