import React from 'react'

import './Styles.scss';

export default function UploadBalanceAlert({data}) {
  return (
    <p className="form-error-message upload-balance-error-message">
        <strong className='error-message-heading'>Por favor revise el archivo y corrija los siguientes errores:</strong>
        <span className='error-message-row'>
            {/* <strong>Fila:</strong> <span className='row-number'>
                {data.error?.row}
            </span><br/>
            <strong>Error:</strong><br/> */}
            {data.error?.errors?.map((err, i) => (
                <span key={i} className='row-number'>{err}<br/></span>
            ))}
        </span>
    </p>
  )
}
