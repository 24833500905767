// API
import UsersService from '../../services/users.service';

import { 
  setGetCustomersWithUsersLoading, setGetCustomersWithUsersScuccess, setGetCustomersWithUsersFailure,
  setGetSingleCustomerWithUsersLoading, setGetSingleCustomerWithUsersScuccess, setGetSingleCustomerWithUsersFailure,
  setGetUsersLoading, setGetUsersScuccess, setGetUsersFailure,
  setGetAdminUsersLoading, setGetAdminUsersScuccess, setGetAdminUsersFailure,
  setCreateUserLoading, setCreateUserScuccess, setCreateUserFailure, createUserCleanup,
  setUpdateUserLoading, setUpdateUserScuccess, setUpdateUserFailure, updateUserCleanup,
  setDeleteUserLoading, setDeleteUserScuccess, setDeleteUserFailure, deleteUserCleanup,
  setTransferWalletToUserLoading, setTransferWalletToUserScuccess, setTransferWalletToUserFailure, transferWalletToUserCleanup,
  setGetRolesAndPermissionsLoading, setGetRolesAndPermissionsScuccess, setRolesAndPermissionsFailure,
 } from './users.reducers';


 // Get Customers With Users ====================================================================================================

  /**
   * @param page optional page number
   * @description Gets the list of all customers with their users
   */


  export const getCustomersWithUsers = (page) => async (dispatch) => {
    dispatch(setGetCustomersWithUsersLoading());

    try {

      const response = await UsersService.getCustomersWithUsers(page);

      dispatch(setGetCustomersWithUsersScuccess(response.data));

    } catch(error) {
      dispatch(setGetCustomersWithUsersFailure(error.response?.data?.message || error.message));
    }

  }


  // Search Customers with Users ====================================================================================================

  /**
   * @param query the terms of the search query
   * @description Gets the list of all the customers with users that match the search query
   */

   export const searchCustomersWithUsers = (query) => async (dispatch) => {
    dispatch(setGetCustomersWithUsersLoading());

    try {

      const response = await UsersService.searchCustomersWithUsers(query);

      dispatch(setGetCustomersWithUsersScuccess(response.data));

    } catch(error) {
      dispatch(setGetCustomersWithUsersFailure(error.response?.data?.message));
    }

  }


  // Get All Users ====================================================================================================

  /**
   * @param page optional page number
   * @description Gets the list of all the users
   */


   export const getSingleCustomerWithUsers = (customerId) => async (dispatch) => {
    dispatch(setGetSingleCustomerWithUsersLoading());

    try {

      const response = await UsersService.getSingleCustomerWithUsers(customerId);

      dispatch(setGetSingleCustomerWithUsersScuccess(response.data));

    } catch(error) {
      dispatch(setGetSingleCustomerWithUsersFailure(error.response?.data?.message || error.message));
    }

  }


  // Get All Users ====================================================================================================

  /**
   * @description Gets the list of all the users
   */


  export const getUsers = (page) => async (dispatch) => {
    dispatch(setGetUsersLoading());

    try {

      const response = await UsersService.getUsers(page);

      dispatch(setGetUsersScuccess(response.data?.data));

    } catch(error) {
      dispatch(setGetUsersFailure(error.response?.data?.message || error.message));
    }

  }


    // Get All Admin Users ====================================================================================================

  /**
   * @description Gets the list of all the admin users
   */


  export const getAdminUsers = () => async (dispatch) => {
    dispatch(setGetAdminUsersLoading());

    try {

      const response = await UsersService.getAllAdminUsers();

      dispatch(setGetAdminUsersScuccess(response.data));

    } catch(error) {
      dispatch(setGetAdminUsersFailure(error.response?.data?.message || error.message));
    }

  }


  // Search All Users ====================================================================================================

  /**
   * @description Gets the list of all the users that match the search query
   */


   export const searchUsers = (query) => async (dispatch) => {
    dispatch(setGetUsersLoading());

    try {

      const response = await UsersService.searchUsers(query);

      dispatch(setGetUsersScuccess(response.data?.data));

    } catch(error) {
      dispatch(setGetUsersFailure(error.response?.data?.message));
    }

  }


  // Create a User ====================================================================================================

  /**
   * @param data {
          @number role,
          @number customer_id
          @string name,
          @string lastname,
          @string email,
          @string password,
          @number status,
      }
  * @returns Creates a new user
  */

 export const createUser = (data) => async (dispatch) => {
  dispatch(setCreateUserLoading());

  try {

    const response = await UsersService.createUser(data);

    dispatch(setCreateUserScuccess(response.data));

    // Refresh the users list

    dispatch(getUsers());

  } catch(error) {
    dispatch(setCreateUserFailure(error.response?.data?.message || error.message));
  }

}


// Create a User Cleanup ====================================================================================================

/**
 * @description Cleans up Create a User store
 */

 export const cleanUpCreateUser = () => (dispatch) => {
  dispatch(createUserCleanup())

};


  // Edit a User ====================================================================================================

  /**
   * @param data {
          @number role,
          @number customer_id
          @string name,
          @string lastname,
          @string email,
          @string password,
          @number status,
      }
  * @param userId the id of the user
  * @returns Edits a user info
  */

  export const editUser = (data, userId) => async (dispatch) => {
    dispatch(setUpdateUserLoading());
  
    try {
  
      await UsersService.editUser(data, userId);
  
      dispatch(setUpdateUserScuccess());
  
    } catch(error) {
      dispatch(setUpdateUserFailure(error.response?.data?.message || error.message));
    }
  
  }
  
  
// Update a User Cleanup ====================================================================================================

/**
 * @description Cleans up Update a User store
 */

  export const cleanUpUpdateUser = () => (dispatch) => {
  dispatch(updateUserCleanup())

};



// Delete a User ====================================================================================================

/**
* @param userId the id of the user
* @returns True or false if the user was deleted
*/

export const deleteUser = (userId) => async (dispatch) => {
  dispatch(setDeleteUserLoading());

  try {

    await UsersService.deleteUser(userId);

    dispatch(setDeleteUserScuccess());

  } catch(error) {
    dispatch(setDeleteUserFailure(error.response?.data?.message || error.message));
  }

}


// Delete a User Cleanup ====================================================================================================

/**
 * @description Cleans up Delete a User store
 */

  export const cleanUpDeleteUser = () => (dispatch) => {
  dispatch(deleteUserCleanup())

};


// Transfer all clients from one user's wallet to another ====================================================================================================

/**
 * @param data {
        @number user_from,
        @number user_to
    }
* @returns True or false if the request was succesful
*/

export const transferWalletClientsToUser = (data) => async (dispatch) => {
  dispatch(setTransferWalletToUserLoading());

  try {

    await UsersService.transferWalletClientsToUser(data);

    dispatch(setTransferWalletToUserScuccess());

  } catch(error) {
    dispatch(setTransferWalletToUserFailure(error.response?.data?.message || error.message));
  }

}


// Transfer Wallet a User Cleanup ====================================================================================================

/**
 * @description Cleans up Transer wallet store
 */

  export const cleantransferWalletToUser = () => (dispatch) => {
  dispatch(transferWalletToUserCleanup())

};


// Get user's roles and permissions ====================================================================================================

/**
* @returns A list of all the user roles with thier permissions
*/

export const getUserRolesAndPermissions = () => async (dispatch) => {
  dispatch(setGetRolesAndPermissionsLoading());

  try {

    const response = await UsersService.getUserRolesAndPermissions();

    dispatch(setGetRolesAndPermissionsScuccess(response.data));

  } catch(error) {
    dispatch(setRolesAndPermissionsFailure(error.response?.data?.message || error.message));
  }

}