import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import Moment from 'moment';
import 'moment/locale/es';

// Styles

import './Styles.scss';

export default function DownloadReportButton({fileUrl, fileName}) {
    const date = Moment().format('MMM Do YYYY, h:mm:ss a');

    return (
        <div className='download-report-button-wrapper'>
            <p className='download-report-button-heading'>Reporte generado exitosamente:</p>
            <a href={fileUrl} className='download-report-button' download={`${fileName}-${date}.xlsx`}>
                <FontAwesomeIcon className='download-report-button-icon' icon={faFileInvoice} />
                <p>
                    Descargar reporte 
                    <span>{date}</span>
                </p>
            </a>
        </div>
    )
}
