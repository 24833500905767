import axios from 'axios';
import { store } from '../store/store';


// Define the API root url

import { API_URL } from './constants';
import { signOut } from '../store/auth/auth.reducers';


// Setup Axios Instance

const api = axios.create({
    baseURL: API_URL
});


// Automagically put OAuth token on requests if it exists

api.interceptors.request.use(config => {
    const state = store.getState();
    const token = state.auth?.user?.data?.token;
    if (token) {
        config.headers.Authorization =  `Bearer ${token}`;
    }
    return config;
});


// Sign Out the user if the authentication token has expired

api.interceptors.response.use(response => {
    return response
}, error => {
    const state = store.getState();
    const token = state.auth?.user?.data?.token;
    if (token && error.response?.status === 401) {
        store.dispatch(signOut('Su sesión ha expirado'))
    }

    return Promise.reject(error)
})


export default api;