import { createSlice } from '@reduxjs/toolkit'

// Setup the initial state

const initialState = {
  logs: {},
  logDetail: {},
  changesLogs: {},
  exportChangesLogs: {},
};

export const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {

    // Get the list of all the logs

    setgetLogsLoading: (state) => {
      state.logs = {
        isLoading: true,
      };
    },
    setgetLogsScuccess: (state, action) => {
      state.logs = {
        data: action.payload
      };
    },
    setgetLogsFailure: (state, action) => {
      state.logs = {
        error: action.payload,
      }
    },

    // Get a Single log

    setgetSingleLogLoading: (state) => {
      state.logDetail = {
        isLoading: true,
      };
    },
    setgetSingleLogScuccess: (state, action) => {
      state.logDetail = {
        data: action.payload
      };
    },
    setgetSingleLogFailure: (state, action) => {
      state.logDetail = {
        error: action.payload,
      }
    },


  // Get the list of all the changes logs

  setgetChangesLogsLoading: (state) => {
    state.changesLogs = {
      isLoading: true,
    };
  },
  setgetChangesLogsScuccess: (state, action) => {
    state.changesLogs = {
      data: action.payload
    };
  },
  setgetChangesLogsFailure: (state, action) => {
    state.changesLogs = {
      error: action.payload,
    }
  },

  // Export changes logs

  setExportChangesLogsLoading: (state) => {
    state.exportChangesLogs = {
      isLoading: true,
    };
  },
  setExportChangesLogsScuccess: (state, action) => {
    state.exportChangesLogs = {
      data: action.payload
    };
  },
  setExportChangesLogsFailure: (state, action) => {
    state.exportChangesLogs = {
      error: action.payload,
    }
  },
  exportChangesLogsCleanup: (state) => {
    state.exportChangesLogs = {}
  },

  },
})

export const { 
  setgetLogsLoading, setgetLogsScuccess, setgetLogsFailure,
  setgetSingleLogLoading, setgetSingleLogScuccess, setgetSingleLogFailure,
  setgetChangesLogsLoading, setgetChangesLogsScuccess, setgetChangesLogsFailure,
  setExportChangesLogsLoading, setExportChangesLogsScuccess, setExportChangesLogsFailure, exportChangesLogsCleanup,
} = logsSlice.actions

export default logsSlice.reducer;