import { createSlice } from '@reduxjs/toolkit'

// Setup the initial state

const initialState = {
  users: {},
  adminUsers: {},
  customersUsers: {},
  singleCustomerUsers: {},
  createUser: {},
  updateUser: {},
  deleteUser: {},
  transferWallet: {},
  rolesAndPermissions: {},
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {

    // Get Customers With Users

    setGetCustomersWithUsersLoading: (state) => {
      state.customersUsers = {
        isLoading: true,
      };
    },
    setGetCustomersWithUsersScuccess: (state, action) => {
      state.customersUsers = {
        data: action.payload
      };
    },
    setGetCustomersWithUsersFailure: (state, action) => {
      state.customersUsers = {
        error: action.payload,
      }
    },

    // Get Single Customer With Users

    setGetSingleCustomerWithUsersLoading: (state) => {
      state.singleCustomerUsers = {
        isLoading: true,
      };
    },
    setGetSingleCustomerWithUsersScuccess: (state, action) => {
      state.singleCustomerUsers = {
        data: action.payload
      };
    },
    setGetSingleCustomerWithUsersFailure: (state, action) => {
      state.singleCustomerUsers = {
        error: action.payload,
      }
    },

    // Get Users

    setGetUsersLoading: (state) => {
      state.users = {
        isLoading: true,
      };
    },
    setGetUsersScuccess: (state, action) => {
      state.users = {
        data: action.payload
      };
    },
    setGetUsersFailure: (state, action) => {
      state.users = {
        error: action.payload,
      }
    },

    // Get Users

    setGetAdminUsersLoading: (state) => {
      state.adminUsers = {
        isLoading: true,
      };
    },
    setGetAdminUsersScuccess: (state, action) => {
      state.adminUsers = {
        data: action.payload
      };
    },
    setGetAdminUsersFailure: (state, action) => {
      state.adminUsers = {
        error: action.payload,
      }
    },

    // Create a User

    setCreateUserLoading: (state) => {
      state.createUser = {
        isLoading: true,
      };
    },
    setCreateUserScuccess: (state, action) => {
      state.createUser = {
        isCreated: true,
        data: action.payload
      };
    },
    setCreateUserFailure: (state, action) => {
      state.createUser = {
        error: action.payload,
      }
    },
    createUserCleanup: (state) => {
      state.createUser = {}
    },

    // Update a User

    setUpdateUserLoading: (state) => {
      state.updateUser = {
        isLoading: true,
      };
    },
    setUpdateUserScuccess: (state) => {
      state.updateUser = {
        isUpdated: true
      };
    },
    setUpdateUserFailure: (state, action) => {
      state.updateUser = {
        error: action.payload,
      }
    },
    updateUserCleanup: (state) => {
      state.updateUser = {}
    },

    // Delete a User

    setDeleteUserLoading: (state) => {
      state.deleteUser = {
        isLoading: true,
      };
    },
    setDeleteUserScuccess: (state) => {
      state.deleteUser = {
        isDeleted: true
      };
    },
    setDeleteUserFailure: (state, action) => {
      state.deleteUser = {
        error: action.payload,
      }
    },
    deleteUserCleanup: (state) => {
      state.deleteUser = {}
    },

    // Transfer wallet to User

    setTransferWalletToUserLoading: (state) => {
      state.transferWallet = {
        isLoading: true,
      };
    },
    setTransferWalletToUserScuccess: (state) => {
      state.transferWallet = {
        isTransfered: true
      };
    },
    setTransferWalletToUserFailure: (state, action) => {
      state.transferWallet = {
        error: action.payload,
      }
    },
    transferWalletToUserCleanup: (state) => {
      state.transferWallet = {}
    },

    // Get user's roles and permissions

    setGetRolesAndPermissionsLoading: (state) => {
      state.rolesAndPermissions = {
        isLoading: true,
      };
    },
    setGetRolesAndPermissionsScuccess: (state, action) => {
      state.rolesAndPermissions = {
        data: action.payload
      };
    },
    setRolesAndPermissionsFailure: (state, action) => {
      state.rolesAndPermissions = {
        error: action.payload,
      }
    },
  },
})

export const { 
  setGetCustomersWithUsersLoading, setGetCustomersWithUsersScuccess, setGetCustomersWithUsersFailure,
  setGetSingleCustomerWithUsersLoading, setGetSingleCustomerWithUsersScuccess, setGetSingleCustomerWithUsersFailure,
  setGetUsersLoading, setGetUsersScuccess, setGetUsersFailure,
  setGetAdminUsersLoading, setGetAdminUsersScuccess, setGetAdminUsersFailure,
  setCreateUserLoading, setCreateUserScuccess, setCreateUserFailure, createUserCleanup,
  setUpdateUserLoading, setUpdateUserScuccess, setUpdateUserFailure, updateUserCleanup,
  setDeleteUserLoading, setDeleteUserScuccess, setDeleteUserFailure, deleteUserCleanup,
  setTransferWalletToUserLoading, setTransferWalletToUserScuccess, setTransferWalletToUserFailure, transferWalletToUserCleanup,
  setGetRolesAndPermissionsLoading, setGetRolesAndPermissionsScuccess, setRolesAndPermissionsFailure,
} = usersSlice.actions

export default usersSlice.reducer;