import React from 'react';

import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";


// Utilities

import { setNestedFieldName, getMaxDate } from '../../../utilities/helper-functions';

import './Styles.scss';

registerLocale('es', es)

export default function DateInput(props) {
    const {
        formik,
        fieldName,
        label,
        isRequired,
        roundedStyle,
        pickDay,
    } = props;

    return (
        <div className={roundedStyle ? "field" : "field field-alt field-date"}>
            {label &&
            <label>{label}</label>}

            {pickDay ? (
                 <DatePicker
                    selected={fieldName.includes('.') ? setNestedFieldName(fieldName, formik.values) : formik.values[fieldName]}
                    onChange={(date) => formik.setFieldValue(fieldName, date)}
                    required={isRequired}
                    maxDate={new Date()}
                    locale="es"
                />
            ) :(
                <DatePicker
                    selected={fieldName.includes('.') ? setNestedFieldName(fieldName, formik.values) : formik.values[fieldName]}
                    onChange={(date) => formik.setFieldValue(fieldName, date)}
                    required={isRequired}
                    maxDate={getMaxDate()}
                    dateFormat="MMMM/yyyy"
                    locale="es"
                    showMonthYearPicker
                />
            )}

            <span className='field-error'>{formik.touched[fieldName] && formik.errors[fieldName]}</span>

        </div>
    )
}
