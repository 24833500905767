import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux'
import * as yup from 'yup';
import Moment from 'moment';
import 'moment/locale/es';

import { getReport, cleanReport } from '../../../store/reports/reports.actions';
import { getDropDownOptions } from '../../../store/options/options.actions';

// Components

import Button from '../../UI/Button/Button';
import SelectInput from '../../UI/FormInput/SelectInput';
import DateInput from '../../UI/FormInput/DateInput';
import DownloadReportButton from '../../UI/DownloadReportButton/DownloadReportButton';
import Loader from '../../UI/Loader/Loader';

// Constants

import { REPORT_TYPES } from '../../../config/constants';

export default function ReportClientsByAssociates({setShowClientsByAssociateReportModal}) {
  const [reportUrl, setReportUrl] = useState(null);
  const reports = useSelector((state) => state.reports.reports);
  const dropDownOptions = useSelector((state) => state.options.dropDownOptions);
  const dispatch = useDispatch();

  // Generate Report

  const generateAssociatesReport = async (values, actions) => {
    setReportUrl(null);

    const data = {
      start_date: values.start_date ? Moment(values.start_date).format('YYYY-MM-DD') : null,
      end_date: values.end_date ? Moment(values.end_date).format('YYYY-MM-DD') : null,
      associate_id: values.associate_id ? values.associate_id : null,
      export: true,
    }

    dispatch(getReport(data, REPORT_TYPES.associates));
  }


  // Set up report download url

  useEffect(() => {
    if (reports.data) {
      const url = window.URL.createObjectURL(new Blob([reports.data]));
      setReportUrl(url);
    }
  }, [reports]);


  // Cleanup the storage on load

  useEffect(() => {
    dispatch(cleanReport());
    dispatch(getDropDownOptions())
  }, [dispatch])

  return (
    <>

      {(dropDownOptions?.isLoading || dropDownOptions?.error) &&
        <Loader
          message={'Cargando...'}
          error={dropDownOptions.error}
          action={() =>  {
              dispatch(getDropDownOptions())
            }
          }
          dark={true}
      />}

    {!dropDownOptions?.isLoading && dropDownOptions?.data &&
      <>
        <Formik
            initialValues={{
              // start_date: new Date(),
              // end_date: new Date(),
              associate_id: ''
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => generateAssociatesReport(values, actions)}
          >

            {props => (
            <>            

              {/* Form Inputs */}
              
              <>

                <SelectInput
                  formik={props}
                  fieldName={'associate_id'}
                  label={'Asociado'}
                  placeholder={'Seleccione'}
                  options={dropDownOptions?.data ? dropDownOptions?.data?.associates.map(option => ({ value: option.id, label: option.name })) : []}
                />

                <DateInput
                  formik={props}
                  fieldName={'start_date'}
                  label={'Fecha inicio'}
                  pickDay
                />

                <DateInput
                  formik={props}
                  fieldName={'end_date'}
                  label={'Fecha fin'}
                  pickDay
                />

                {/* Loader */}

                {(reports?.isLoading || reports?.error) &&
                  <Loader
                    message={'Generando reporte...'}
                    error={reports.error}
                    action={() => getReport(props.values, REPORT_TYPES.associates)}
                    dark={true}
                />}

                {/* Download Report Button */}

                {!reports?.isLoading && reports.data && reportUrl &&            
                <DownloadReportButton
                  fileUrl={reportUrl}
                  fileName={'reporte-de-clientes-por-asociado'}
                />}


                {/* Form actions */}
              
                <div className='form-actions'>

                  <Button
                    action={() => setShowClientsByAssociateReportModal(false)}
                    label={'Cancelar'}
                    secondaryStyle={true}
                    disabled={reports?.isLoading}
                  />

                  <Button
                    action={props.handleSubmit}
                    label={'Generar'}
                    disabled={reports?.isLoading}
                    isLoading={reports?.isLoading}
                  />

                </div>
              </>

            </>)}

        </Formik>

      </>}
    </>
  )
}

// Form initial values and validaiton schemas

const validationSchema = yup.object().shape({
  // associate_id: yup.string().required('Este campo es obligatorio')
  // start_date: yup.string(),
  // end_date: yup.date().when('start_date',
  //   (start_date, schema) => {
  //     if (start_date) {
  //       return schema.min(start_date, 'La fecha de fin no puede ser menor al a fecha de inico');
  //     }
  //     return schema;
  //   }),
  //   otherwise: yup.date(),
});