// API
import CustomersService from '../../services/customers.service';

import { 
  setGetCustomersLoading, setGetCustomersScuccess, setGetCustomersFailure,
  setGetAllCustomersLoading, setGetAllCustomersScuccess, setGetAllCustomersFailure,
  setCreateCustomerLoading, setCreateCustomerScuccess, setCreateCustomerFailure, createCustomerCleanup,
  setUpdateCustomerLoading, setUpdateCustomerScuccess, setUpdateCustomerFailure, updateCustomerCleanup,
  setDeleteCustomerLoading, setDeleteCustomerScuccess, setDeleteCustomerFailure, deleteCustomerCleanup,
  setGetCustomerDetailLoading, setGetCustomerDetailScuccess, setGetCustomerDetailFailure
 } from './customers.reducers';



// Get Customers With Pagination ====================================================================================================

/**
 * @param page optional page number
 * @description Gets the list of the customers with pagination
*/

 export const getCustomers = (page) => async (dispatch) => {
  dispatch(setGetCustomersLoading());

  try {

    const response = await CustomersService.getCustomers(page);

    dispatch(setGetCustomersScuccess(response.data?.data));

  } catch (error) {
    dispatch(setGetCustomersFailure(error.response?.data?.message || error.message));
  }

}


// Get All Customers ====================================================================================================

/**
* @description Gets the list of all the customers
*/

export const getAllCustomers = () => async (dispatch) => {
  dispatch(setGetAllCustomersLoading());

  try {

    const response = await CustomersService.getAllCustomers();

    dispatch(setGetAllCustomersScuccess(response.data));

  } catch (error) {
    dispatch(setGetAllCustomersFailure(error.response?.data?.message || error.message));
  }

}


// Search All Customers ====================================================================================================

/**
* @description Gets the list of all the customers that match the search query
*/

export const searchCustomers = (query) => async (dispatch) => {
  dispatch(setGetCustomersLoading());

  try {

    const response = await CustomersService.searchCustomers(query);

    dispatch(setGetCustomersScuccess(response.data?.data));

  } catch (error) {
    dispatch(setGetCustomersFailure(error.response?.data?.message || error.message));
  }

}


// Create a Customer ====================================================================================================

/**
* @param data {
      @string name,
      @string rnc,
      @number status,
      @file   logo,
      @string address,
      @string phone,
      @string email,
      @string contact_person
  }
* @returns Creates a new customer
*/

export const createCustomer = (data) => async (dispatch) => {
  dispatch(setCreateCustomerLoading());

  try {

    const response = await CustomersService.createCustomer(data);

    dispatch(setCreateCustomerScuccess(response.data));

    // Refresh the customers list

    dispatch(getCustomers());

  } catch (error) {
    dispatch(setCreateCustomerFailure(error.response?.data?.message || error.message));
  }

}


// Create a Customer Cleanup ====================================================================================================

/**
* @description Cleans up Create a Customer store
*/

export const cleanUpCreateCustomer = () => (dispatch) => {
  dispatch(createCustomerCleanup())
};


// Edit a Customer ====================================================================================================

/**
* @param data {
      @string name,
      @string rnc,
      @number status,
      @file   logo,
      @string address,
      @string phone,
      @string email,
      @string contact_person
  }
* @param customerId the id of the customer
* @returns Edits a customer info
*/

export const editCustomer = (data, customerId) => async (dispatch) => {
  dispatch(setUpdateCustomerLoading());

  try {

    await CustomersService.editCustomer(data, customerId);

    dispatch(setUpdateCustomerScuccess());

    // Refresh the customers list

    dispatch(getCustomers());

  } catch (error) {
    dispatch(setUpdateCustomerFailure(error.response?.data?.message || error.message));
  }

}


// Update a Customer Cleanup ====================================================================================================

/**
* @description Cleans up Update a Customer store
*/

export const cleanUpUpdateCustomer = () => (dispatch) => {
  dispatch(updateCustomerCleanup())
};



// Delete a Customer ====================================================================================================

/**
* @param customerId the id of the customer
* @returns True or false if the customer was deleted
*/

export const deleteCustomer = (customerId) => async (dispatch) => {
  dispatch(setDeleteCustomerLoading());

  try {

    await CustomersService.deleteCustomer(customerId);

    dispatch(setDeleteCustomerScuccess());

    // Refresh the customers list

    dispatch(getCustomers());

  } catch (error) {
    dispatch(setDeleteCustomerFailure(error.response?.data?.message || error.message));
  }

}


// Delete a Customer Cleanup ====================================================================================================

/**
* @description Cleans up Delete a Customer store
*/

export const cleanUpDeleteCustomer = () => (dispatch) => {
  dispatch(deleteCustomerCleanup())
};


// Get Customer Details ====================================================================================================

/**
* @param customerId the id of the customer
* @returns The customer's details
*/

export const getCustomerDetails = (customerId) => async (dispatch) => {
  dispatch(setGetCustomerDetailLoading());

  try {

    const response = await CustomersService.getCustomerDetails(customerId);

    dispatch(setGetCustomerDetailScuccess(response.data));

  } catch (error) {
    dispatch(setGetCustomerDetailFailure(error.response?.data?.message || error.message));
  }

}