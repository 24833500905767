// API
import LogsService from '../../services/logs.service';

import { 
  setgetLogsLoading, setgetLogsScuccess, setgetLogsFailure,
  setgetSingleLogLoading, setgetSingleLogScuccess, setgetSingleLogFailure,
  setgetChangesLogsLoading, setgetChangesLogsScuccess, setgetChangesLogsFailure,
  setExportChangesLogsLoading, setExportChangesLogsScuccess, setExportChangesLogsFailure, exportChangesLogsCleanup,
 } from './logs.reducers';


    // Get the list of all the logs ====================================================================================================

  /**
   * @param page optional page number
   * @description Gets the list of all the logs
   */

  export const getLogs = (page) => async (dispatch) => {
    dispatch(setgetLogsLoading());

    try {

      const response = await LogsService.getLogs(page);

      dispatch(setgetLogsScuccess(response.data));

    } catch(error) {
      dispatch(setgetLogsFailure(error.response?.data?.message || error.message));
    }

  }


  // Get a Single log ====================================================================================================

  /**
  * @param logId the id of the log
  * @returns Gets the detail of a single log
  */

  export const getSingleLog = (logId) => async (dispatch) => {
    dispatch(setgetSingleLogLoading());

    try {

      const response = await LogsService.getSingleLog(logId);

      dispatch(setgetSingleLogScuccess(response.data));

    } catch(error) {
      dispatch(setgetSingleLogFailure(error.response?.data?.message || error.message));
    }

  }

  // Get the list of all the changes logs ====================================================================================================

  /**
   * @param fromDate starting date
   * @param toDate ending date
   * @param page optional page number
   * @description Get the list of all the changes logs
   */

  export const getChangesLogs = (page, fromDate, toDate) => async (dispatch) => {
    dispatch(setgetChangesLogsLoading());

    try {

      const response = await LogsService.getChangesLogs(page, fromDate, toDate);

      dispatch(setgetChangesLogsScuccess(response.data));

    } catch(error) {
      dispatch(setgetChangesLogsFailure(error.response?.data?.message || error.message));
    }

  }

  
  // Export changes logs ====================================================================================================

  /**
   * @description Export changes logs
   */

  export const exportChangesLogs = (fromDate, toDate) => async (dispatch) => {
    dispatch(setExportChangesLogsLoading());

    try {

      const response = await LogsService.exportChangesLogs(fromDate, toDate);

      dispatch(setExportChangesLogsScuccess(response.data));

    } catch(error) {
      dispatch(setExportChangesLogsFailure(error.response?.data?.message || error.message));
    }

  }

  
  // Export changes logs Cleanup ====================================================================================================

  /**
  * @description Cleans up Export changes logs
  */

  export const cleanExportChangesLogs = () => (dispatch) => {
    dispatch(exportChangesLogsCleanup())
  };