import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'

import { getAllCustomers } from '../../../store/customers/customers.actions';
import { updateBusiness, cleanUpdateBusiness, deleteBusiness, cleanDeleteBusiness } from '../../../store/business/business.actions';
import { getAdminUsers } from '../../../store/users/users.actions';

// Components

import FormInput from '../../UI/FormInput/FormInput';
import SelectInput from '../../UI/FormInput/SelectInput';
import CustomerGroupSelect from '../../UI/FormInput/CustomerGroupSelect';
import Button from '../../UI/Button/Button';
import Loader from '../../UI/Loader/Loader';

export default function EditCustomerGroupForm({groupToEdit, setShowEditClientModal}) {
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const userPermissions = user?.data?.permissionsData;
  const updateABusiness = useSelector((state) => state.business.updateBusiness);
  const deleteABusiness = useSelector((state) => state.business.deleteBusiness);
  const adminUsers = useSelector((state) => state.users.adminUsers);
  const customers = useSelector((state) => state.customers.customersAll);
  const dispatch = useDispatch();

  // Create Bussiness Group
  
  const businessCreate = async (values, actions) => {
    const groupId = groupToEdit?.id;
    dispatch(updateBusiness(groupId, values));
  }

  useEffect(() => {
    if (updateABusiness.isUpdated || deleteABusiness.isDeleted) {
      setShowEditClientModal(false);
      dispatch(cleanUpdateBusiness());
      dispatch(cleanDeleteBusiness());
    }
  }, [dispatch, setShowEditClientModal, updateABusiness, deleteABusiness])

  useEffect(() => {
    getCustomersAndUsers();
  }, [dispatch])

  useEffect(() => {
    if (customers?.data) {
      setFilteredCustomers(customers?.data);
    }
  }, [customers])

  // Get Customers and uSers Data

  const getCustomersAndUsers = () => {
    dispatch(getAllCustomers());
    dispatch(getAdminUsers());
    dispatch(cleanUpdateBusiness());
    dispatch(cleanDeleteBusiness());
  }

  // Filter Admin Users by Selected Customers

  const filterAdminUsersBySelectedCustomers = (selectedCustomers) => {
    const usersData = adminUsers?.data;
    const filteredUsersData = usersData?.filter((user) => selectedCustomers?.includes(user.customer?.id));

    return filteredUsersData?.length > 0 ? filteredUsersData?.map(option => ({ value: option.id, label: `${option.name} (${option?.customer?.name})` })) : [];
  }

  // Search Clients

  const searchClients = (value) => {
    if (value !== '') {
        const data = filteredCustomers.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(value.toLowerCase())
        })
        setFilteredCustomers(data)
    } else {
      setFilteredCustomers(customers?.data)
    }
  }

  
  // Delete a business group

  const businessDelete = async () => {
    if (window.confirm("Desea borrar este grupo?")) {
      dispatch(deleteBusiness(groupToEdit?.id));
    }
  }

  return (
    <>
      {(customers?.isLoading || customers?.error) &&
        <Loader
          message={'Cargando...'}
          error={customers.error}
          action={() => getCustomersAndUsers()}
          dark={true}
      />}

      {!customers?.isLoading && filteredCustomers &&
      <Formik
        initialValues={{ 
          name: groupToEdit?.name,
          user_id: groupToEdit?.user_id ? Number(groupToEdit?.user_id) : '', 
          customers: groupToEdit?.companies?.map(customer => customer.id),
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => businessCreate(values, actions)}
      >
        {props => (
          <>

          {updateABusiness.error && <p className="form-error-message">{updateABusiness.error}</p>}
          {deleteABusiness.error && <p className="form-error-message">{deleteABusiness.error}</p>}

          {/* Form Inputs */}

          <>
            {filteredCustomers && filteredCustomers &&
            <CustomerGroupSelect
              formik={props}
              fieldName={'customers'}
              label={'Selecione clientes'}
              placeholder={'Seleccione'}
              data={filteredCustomers ? filteredCustomers.map(customer => ({ value: customer.id, label: customer.name, inGroup: (customer.bussiness_group_id !== null && customer.bussiness_group_id !== 0) })) : []}
              searchAction={searchClients}
            />}
          </>

          <div style={{marginTop: '2rem'}}>
            <FormInput
              formik={props}
              fieldName={'name'}
              label={'Nombre del grupo'}
            />

            <SelectInput
              formik={props}
              fieldName={'user_id'}
              label={'Usuario del grupo'}
              placeholder={'Seleccione'}
              options={filterAdminUsersBySelectedCustomers(props.values?.customers)}
              disabled={props.values?.customers?.length === 0}
            />
          </div>
        
          <div className='form-actions'>

            <Button
              action={() => setShowEditClientModal(false)}
              label={'Cancelar'}
              disabled={updateABusiness?.isLoading}
              secondaryStyle={true}
            />

            <Button
              action={props.handleSubmit}
              label={'Añadir'}
              disabled={updateABusiness?.isLoading}
              isLoading={updateABusiness?.isLoading}
            />

          </div>
         
          {userPermissions.EditarDatosGeneralesClientes &&
          <button
            className='btn-danger'
            type='button'
            style={deleteABusiness?.isLoading ? {opacity: 0.5} : {}}
            disabled={deleteABusiness?.isLoading}
            onClick={() => businessDelete()}
            >
            {!deleteABusiness?.isLoading ? (
              'Borrar Grupo'
              ) : (
                'Borrarndo...'
              )}
          </button>}

        </>)}
      </Formik>}
    </>
  )
}

// Form initial values and validaiton schemas

const validationSchema = yup.object().shape({
  name: yup.string().required('Este campo es requerido'),
  user_id: yup.number().required('Este campo es requerido'),
  customers: yup.array().min(1, 'Añada al menos un cliente')
});