import React, { useState, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import Moment from 'moment';
import 'moment/locale/es';

import { getChangesLogs, exportChangesLogs, cleanExportChangesLogs } from '../../store/logs/logs.actions';

// Components

import Loader from '../../components/UI/Loader/Loader';
import Pagination from '../../components/UI/Pagination/Pagination';
import NoEntriesMessage from '../../components/UI/NoEntriesMessage/NoEntriesMessage';
import SectionHeader from '../../components/UI/SectionHeader/SectionHeader';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import ChangesLogsList from '../../components/lists/ChangesLogsList/ChangesLogsList';
import DateRangeInput from '../../components/UI/FormInput/DateRangeInput';
import ButtonSmall from '../../components/UI/ButtonSmall/ButtonSmall';

export default function ChangesLogs() {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const changesLogs = useSelector((state) => state.logs.changesLogs);
    const exportChanges = useSelector((state) => state.logs.exportChangesLogs);
    const dispatch = useDispatch();

    const handleChangeLogsFilterAndPagination = (page, fromDate, toDate) => {
        const dateFrom = fromDate ?  Moment(fromDate).format('YYYY-MM-DD') : null;
        const dateTo = toDate ? Moment(toDate).format('YYYY-MM-DD') : null;

        dispatch(getChangesLogs(page, dateFrom, dateTo));
    }
    
    useEffect(() => {
        dispatch(getChangesLogs());
        dispatch(cleanExportChangesLogs());
    }, [dispatch])


    // Download exported Changes Log

    const handleExportChangeLog = (fromDate, toDate) => {
        const dateFrom = fromDate ?  Moment(fromDate).format('YYYY-MM-DD') : null;
        const dateTo = toDate ? Moment(toDate).format('YYYY-MM-DD') : null;

        dispatch(exportChangesLogs(dateFrom, dateTo));
    }

    useEffect(() => {
        if (exportChanges.data) {
            const date = Moment().format('MMM Do YYYY, h:mm:ss a');
            const url = window.URL.createObjectURL(new Blob([exportChanges.data]));
            const link = document.createElement("a");
            link.href = url;
            link.download = `logs-de-cambios-${date}.xlsx`;
            link.click();

            dispatch(cleanExportChangesLogs())
        }
    }, [exportChanges, dispatch]);

    return (
        <div className='dashboard-wrapper'>

            <PageWrapper>

                <SectionHeader title={'Listado de cambios'}>
                    <DateRangeInput
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        action={handleChangeLogsFilterAndPagination}
                    />

                    <Pagination
                        action={handleChangeLogsFilterAndPagination}
                        pagination={changesLogs?.data}
                        noDispatch
                    />
                    
                    <ButtonSmall
                        label={'Exportar'}
                        icon={'export'}
                        isLoading={exportChanges?.isLoading}
                        disabled={exportChanges?.isLoading}
                        secondaryStyle={true}
                        action={() => handleExportChangeLog(startDate, endDate)}
                    />
                </SectionHeader>

                {(changesLogs?.isLoading || changesLogs.error) &&
                <Loader
                    message={'Cargando logs...'}
                    error={changesLogs.error}
                    action={getChangesLogs}
                />}

                {!changesLogs?.isLoading && changesLogs?.data?.data && changesLogs?.data?.data?.length > 0 &&
                    <ChangesLogsList data={changesLogs?.data?.data} />
                }

                {!changesLogs?.isLoading && changesLogs?.data && changesLogs?.data?.data?.length === 0 &&
                    <NoEntriesMessage message={'No se encontraron logs para esta busqueda'} />
                }

            </PageWrapper>

        </div>
  )
}
