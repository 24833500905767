import { configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// Import the root reducer

import rootReducer from './combined-reducers';

// Middleware: Redux Persist Config

const persistConfig = {
  // Root

  key: 'root',
  version: 1,

  // Storage Method (React Native)

  storage: storage,

  // Whitelist (Save Specific Reducers)

  whitelist: ['auth', 'settings'],

  // Blacklist (Don't Save Specific Reducers)

  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

let persistor = persistStore(store);

export {store, persistor};
