import React from 'react';
import Select from 'react-select';

import './Styles.scss';

export default function SelectInput(props) {
    const {
        formik,
        fieldName,
        label,
        placeholder,
        isRequired,
        options,
        disabled,
        roundedStyle,
        additionalFunctions,
    } = props;

    const getOptionBackgroundColor = (state) => {
        if (state.isFocused) {
            return "#a0a5dd"
        } else if (state.isSelected) {
            return "#656DC3"
        } else {
            return ""
        }
    }

    const customStyles = {           
        option: (provided, state) => ({
            ...provided,
            backgroundColor: getOptionBackgroundColor(state),
            color: state.isFocused || state.isSelected ? "#FFFFFF" : "",
        }),
    };

    const valueFromId = (options, value) => options.find(option => option.value === value);

    return (
        <div className={roundedStyle ? "field" : "field field-alt"}>
            {label &&
            <label>{label}</label>}

            <Select 
                styles={customStyles}
                className={formik.touched[fieldName] && formik.errors[fieldName] ? "select-input input-error" : "select-input"}
                name={fieldName}
                options={options}
                value={valueFromId(options, formik.values[fieldName])}
                placeholder={placeholder}
                onChange={option => {
                    formik.setFieldValue(fieldName, option?.value)
                    if (additionalFunctions) {
                        additionalFunctions();
                    }
                }}
                onBlur={formik.handleBlur(fieldName)}
                isDisabled={disabled}
                required={isRequired}
                isClearable
            />

            <span className='field-error'>{formik.touched[fieldName] && formik.errors[fieldName]}</span>

        </div>
    )
}
