import React from 'react'

import AccountUtilities from '../../cards/AccountUtilities/AccountUtilities'
import Navigation from '../../Navigation/Navigation'

import './Styles.scss';

const imgBrandIcon = require('../../../assets/i/img-brand-icon.png');

export default function PageWrapper({children}) {
  return (
    <>
        <header>
            {/* <div className="container"> */}
                <img src={imgBrandIcon} className="header-brand-logo" alt='Vallges Group' />

                <AccountUtilities />

            {/* </div> */}
        </header>

        <div className='container dashboard-container' style={{margin: 0, padding: 0, maxWidth: 'none'}}>

            <div className="row">

                <div className="col-md-1">
                    <Navigation />
                </div>

                <div className="col-md-11">
                    <div className='dashboard-container-inner'>
                        <div className='dashboard-container-content'>

                            {children}

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </>
  )
}
