import React from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns } from '@fortawesome/free-solid-svg-icons';

import { handleShowAllAccountsListColumns } from '../../../store/settings/settings.actions';

import './Styles.scss';

export default function ToggleAccountsListColumnsButton() {
    const showAllAccountsListColumns = useSelector((state) => state.settings.showAllAccountsListColumns);
    const dispatch = useDispatch();

    return (
        <button
            className='toggle-columns-button' 
            title={showAllAccountsListColumns ? 'Ocultar columnas' : 'Mostrar todas las columnas'}
            onClick={() => dispatch(handleShowAllAccountsListColumns(!showAllAccountsListColumns))}
        >
            <FontAwesomeIcon className='toggle-columns-button-icon' icon={faColumns} />
        </button>
    )
}
