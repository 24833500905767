import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux'
import * as yup from 'yup';
import Moment from 'moment';
import 'moment/locale/es';

import { getReport, cleanReport } from '../../../store/reports/reports.actions';

// Components

import Button from '../../UI/Button/Button';
import DateInput from '../../UI/FormInput/DateInput';
import Loader from '../../UI/Loader/Loader';
import DownloadReportButton from '../../UI/DownloadReportButton/DownloadReportButton';

// Constants

import { REPORT_TYPES } from '../../../config/constants';

export default function ReportClients({setShowClientReportModal}) {
  const [reportUrl, setReportUrl] = useState(null);
  const reports = useSelector((state) => state.reports.reports);
  const dispatch = useDispatch();

  // Generate Report

  const generateClientsReport = async (values, actions) => {
    setReportUrl(null);

    const data = {
      start_date: values.start_date ? Moment(values.start_date).format('YYYY-MM-DD') : null,
      end_date: values.end_date ? Moment(values.end_date).format('YYYY-MM-DD') : null,
      log_type: values.log_type ? values.log_type : null,
      export: true,
    }

    dispatch(getReport(data, REPORT_TYPES.clients));
  }


  // Set up report download url

  useEffect(() => {
    if (reports.data) {
      const url = window.URL.createObjectURL(new Blob([reports.data]));
      setReportUrl(url);
    }
  }, [reports]);


  // Cleanup the storage on load

  useEffect(() => {
    dispatch(cleanReport());
  }, [dispatch])

  return (
    <>

      <Formik
          initialValues={{
            // start_date: new Date(),
            // end_date: new Date(),
            log_type: null
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => generateClientsReport(values, actions)}
        >

          {props => (
          <>            

            {/* Form Inputs */}
            
            <>

              <DateInput
                formik={props}
                fieldName={'start_date'}
                label={'Fecha inicio'}
                pickDay
              />

              <DateInput
                formik={props}
                fieldName={'end_date'}
                label={'Fecha fin'}
                pickDay
              />

              {/* Loader */}

              {(reports?.isLoading || reports?.error) &&
                <Loader
                  message={'Generando reporte...'}
                  error={reports.error}
                  action={() => generateClientsReport()}
                  dark={true}
              />}

              {/* Download Report Button */}

              {!reports?.isLoading && reports.data && reportUrl &&            
              <DownloadReportButton
                fileUrl={reportUrl}
                fileName={'reporte-de-clientes'}
              />}


              {/* Form actions */}
            
              <div className='form-actions'>

                <Button
                  action={() => setShowClientReportModal(false)}
                  label={'Cancelar'}
                  secondaryStyle={true}
                  disabled={reports?.isLoading}
                />

                <Button
                  action={props.handleSubmit}
                  label={'Generar'}
                  disabled={reports?.isLoading}
                  isLoading={reports?.isLoading}
                />

              </div>
            </>

          </>)}

      </Formik>

    </>
  )
}

// Form initial values and validaiton schemas

const validationSchema = yup.object().shape({
  // start_date: yup.string(),
  // end_date: yup.date().when('start_date',
  //   (start_date, schema) => {
  //     if (start_date) {
  //       return schema.min(start_date, 'La fecha de fin no puede ser menor al a fecha de inico');
  //     }
  //     return schema;
  //   }),
  //   otherwise: yup.date(),
});