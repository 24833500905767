import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss';

export default function Search({action, onChange}) {
  const [ query, setQuery ] = useState('');
  const dispatch = useDispatch();

  const searchQuery = () => {
    dispatch(action(query));
  }
  
  return (
    <div className='search'>
        <input
            type="text"
            placeholder='Buscar'
            value={query}
            onChange={onChange ? (e) => { setQuery(e.target.value); onChange(e.target.value) } : (e) => setQuery(e.target.value)}
        />
        <button onClick={onChange ? () => onChange(query) : () => searchQuery()}>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
        </button>
    </div>
  )
}
