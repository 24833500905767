import React from 'react'

import './Styles.scss';

export default function FormSteps({steps, currentStep}) {
  return (
    <div className='form-steps'>
        {steps && steps.map((step, i) => (
        <div key={i} className={i === currentStep ? 'form-step active' : 'form-step'}>
            <span>{i + 1}</span>
            <p>{step}</p>
        </div>
        ))}
    </div>
  )
}
