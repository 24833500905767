import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'


import { editUser, cleanUpUpdateUser } from '../../../store/users/users.actions';
import { getAllCustomers } from '../../../store/customers/customers.actions';

// Components

import MultiSelectInput from '../../UI/FormInput/MultiSelectInput';
import Button from '../../UI/Button/Button';
import Loader from '../../UI/Loader/Loader';

// Utilities

import { setUpUserPermissionsArray } from '../../../utilities/helper-functions';

export default function EditClientPortfolioForm({user, setShowEditClientPortfolioModal}) {
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const userUpdate = useSelector((state) => state.users.updateUser);
  const customers = useSelector((state) => state.customers.customersAll);
  const dispatch = useDispatch();

  // Edit a user
  
  const userEdit = async (values, actions) => {
    dispatch(editUser(values, user.id));
  }

  useEffect(() => {
    if (userUpdate.isUpdated) {
      setShowEditClientPortfolioModal(false);
      dispatch(cleanUpUpdateUser());
    }
  }, [dispatch, setShowEditClientPortfolioModal, userUpdate])

  useEffect(() => {
    dispatch(getAllCustomers());
    dispatch(cleanUpUpdateUser());
  }, [dispatch])

  useEffect(() => {
    if (customers?.data) {
      setFilteredCustomers(customers?.data);
    }
  }, [customers])

  // Search Clients

  const searchClients = (value) => {
    if (value !== '') {
        const data = filteredCustomers.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(value.toLowerCase())
        })
        setFilteredCustomers(data)
    } else {
      setFilteredCustomers(customers?.data)
    }
  }

  return (
    <>
      {(customers?.isLoading || customers?.error) &&
        <Loader
          message={'Cargando clientes...'}
          error={customers.error}
          action={() => dispatch(getAllCustomers())}
          dark={true}
      />}

      {!customers?.isLoading && filteredCustomers &&
      <Formik
        initialValues={{ 
          customer_id: user.customer_id,
          wallet: user.wallet ? user.wallet.map(customer => (Number(customer.customer_id))) : [],
          role: user.role, 
          name: user.name,
          lastname: user.lastname,
          email: user.email,
          status: user.status,
          permissions: user?.permissions?.length > 0 ? setUpUserPermissionsArray(user.permissions) : [],
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => userEdit(values, actions)}
      >

        {props => (
        <>

          {userUpdate.error && <p className="form-error-message">{userUpdate.error}</p>}

          {/* Form Inputs */}

          <>
            {filteredCustomers && filteredCustomers &&
            <MultiSelectInput
              formik={props}
              fieldName={'wallet'}
              label={'Selecione clientes'}
              placeholder={'Seleccione'}
              data={filteredCustomers ? filteredCustomers.map(customer => ({ value: customer.id, label: customer.name, inWallet: customer.in_wallet })) : []}
              searchAction={searchClients}
            />}
          
            <div className='form-actions'>

              <Button
                action={() => setShowEditClientPortfolioModal(false)}
                label={'Cancelar'}
                disabled={userUpdate?.isLoading}
                secondaryStyle={true}
              />

              <Button
                action={props.handleSubmit}
                label={'Editar'}
                disabled={userUpdate?.isLoading}
                isLoading={userUpdate?.isLoading}
              />

            </div>
          </>

        </>)}

      </Formik>}
    </>
  )
}

// Form initial values and validaiton schemas

const validationSchema = yup.object().shape({
  clients_portfolio: yup
    .array()
});