// API
import ReportsService from '../../services/reports.service';

import { 
  setGetReportLoading, setGetReportScuccess, setGetReportFailure, getReportCleanUp,
 } from './reports.reducers';


  // Get a report of the specified type ====================================================================================================

  /**
   * @param data the parameters for the report
   * @param type the report type
   * @description Gets a report of the specified type
  */

  export const getReport = (data, type) => async (dispatch) => {
    dispatch(setGetReportLoading());

    try {

      const response = await ReportsService.getReport(data, type);

      dispatch(setGetReportScuccess(response.data));

    } catch(error) {
      if (error.response?.status === 400) {
        dispatch(setGetReportFailure('No hay reportes con estos registros'));
      } else {
        dispatch(setGetReportFailure(error.response?.data?.message || error.message));
      }
    }

  }


  // Get Report Cleanup ====================================================================================================

  /**
  * @description Cleans up Get Report store
  */

  export const cleanReport = () => (dispatch) => {
    dispatch(getReportCleanUp())
  };
