import { createSlice } from '@reduxjs/toolkit'

// Setup the initial state

const initialState = {
  balanceCalculation: {},
  uploadBalanceData: {},
  balanceAccountEdit: {},
  bulkBalanceAccountEdit: {},
  exportBalance: {},
  calculateBalanceFlow: {},
  updateInitialBalanceTemplate: {},
  getInitialBalanceTemplate: {},
};

export const balancesSlice = createSlice({
  name: 'balances',
  initialState,
  reducers: {

    // Get Customer's Balance Calculation

    setGetBalanceCalculationLoading: (state) => {
      state.balanceCalculation = {
        isLoading: true,
      };
    },
    setGetBalanceCalculationScuccess: (state, action) => {
      state.balanceCalculation = {
        data: action.payload
      };
    },
    setGetBalanceCalculationFailure: (state, action) => {
      state.balanceCalculation = {
        error: action.payload,
      }
    },

    // Upload Customer's Balance Data

    setUploadBalanceDataLoading: (state) => {
      state.uploadBalanceData = {
        isLoading: true,
      };
    },
    setUploadBalanceDataScuccess: (state, action) => {
      state.uploadBalanceData = {
        isUploaded: true,
        data: action.payload
      };
    },
    setUploadBalanceDataFailure: (state, action) => {
      state.uploadBalanceData = {
        error: action.payload,
      }
    },
    uploadBalanceDataCleanup: (state) => {
      state.uploadBalanceData = {}
    },

    // Edit a Customer's Balance Account

    setEditBalanceAccountLoading: (state) => {
      state.balanceAccountEdit = {
        isLoading: true,
      };
    },
    setEditBalanceAccountScuccess: (state, action) => {
      state.balanceAccountEdit = {
        isEdited: true,
        data: action.payload
      };
    },
    setEditBalanceAccountFailure: (state, action) => {
      state.balanceAccountEdit = {
        error: action.payload,
      }
    },
    editBalanceAccountCleanup: (state) => {
      state.balanceAccountEdit = {}
    },

    // Bulk Edit Customer's Balance Accounts 

    setBulkBalanceAccountEditLoading: (state) => {
      state.bulkBalanceAccountEdit = {
        isLoading: true,
      };
    },
    setBulkBalanceAccountEditScuccess: (state, action) => {
      state.bulkBalanceAccountEdit = {
        isEdited: true,
        data: action.payload
      };
    },
    setBulkBalanceAccountEditFailure: (state, action) => {
      state.bulkBalanceAccountEdit = {
        error: action.payload,
      }
    },
    bulkBalanceAccountEditCleanup: (state) => {
      state.bulkBalanceAccountEdit = {}
    },

    // Calculate Balance Flow

    setCalculateBalanceFlowLoading: (state) => {
      state.calculateBalanceFlow = {
        isLoading: true,
      };
    },
    setCalculateBalanceFlowScuccess: (state, action) => {
      state.calculateBalanceFlow = {
        isCalculated: action.payload?.success,
        data: action.payload?.message
      };
    },
    setCalculateBalanceFlowFailure: (state, action) => {
      state.calculateBalanceFlow = {
        error: action.payload,
      }
    },
    calculateBalanceFlowCleanup: (state) => {
      state.calculateBalanceFlow = {}
    },

    // Export Client's Balance

    setExportClientBalanceLoading: (state) => {
      state.exportBalance = {
        isLoading: true,
      };
    },
    setExportClientBalanceScuccess: (state, action) => {
      state.exportBalance = {
        data: action.payload
      };
    },
    setExportClientBalanceFailure: (state, action) => {
      state.exportBalance = {
        error: action.payload,
      }
    },
    exportClientBalanceCleanup: (state) => {
      state.exportBalance = {}
    },

    // Update Initial Balance Template 

    setUpdateInitialBalanceTemplateLoading: (state) => {
      state.updateInitialBalanceTemplate = {
        isLoading: true,
      };
    },
    setUpdateInitialBalanceTemplateSuccess: (state, action) => {
      state.updateInitialBalanceTemplate = {
        isUpdated: true,
        data: action.payload
      };
    },
    setUpdateInitialBalanceTemplateFailure: (state, action) => {
      state.updateInitialBalanceTemplate = {
        error: action.payload,
      }
    },
    cleanUpdateInitialBalanceTemplate: (state) => {
      state.updateInitialBalanceTemplate = {}
    },

    // Get Terms and Conditions 

    setGetInitialBalanceTemplateLoading: (state) => {
      state.getInitialBalanceTemplate = {
        isLoading: true,
      };
    },
    setGetInitialBalanceTemplateSuccess: (state, action) => {
      state.getInitialBalanceTemplate = {
        data: action.payload
      };
    },
    setGetInitialBalanceTemplateFailure: (state, action) => {
      state.getInitialBalanceTemplate = {
        error: action.payload,
      }
    },

  },
})

export const {
  setGetBalanceCalculationLoading, setGetBalanceCalculationScuccess, setGetBalanceCalculationFailure,
  setUploadBalanceDataLoading, setUploadBalanceDataScuccess, setUploadBalanceDataFailure, uploadBalanceDataCleanup,
  setEditBalanceAccountLoading, setEditBalanceAccountScuccess, setEditBalanceAccountFailure, editBalanceAccountCleanup,
  setBulkBalanceAccountEditLoading, setBulkBalanceAccountEditScuccess, setBulkBalanceAccountEditFailure, bulkBalanceAccountEditCleanup,
  setCalculateBalanceFlowLoading, setCalculateBalanceFlowScuccess, setCalculateBalanceFlowFailure, calculateBalanceFlowCleanup,
  setExportClientBalanceLoading, setExportClientBalanceScuccess, setExportClientBalanceFailure, exportClientBalanceCleanup,
  setUpdateInitialBalanceTemplateLoading, setUpdateInitialBalanceTemplateSuccess, setUpdateInitialBalanceTemplateFailure, cleanUpdateInitialBalanceTemplate,
  setGetInitialBalanceTemplateLoading, setGetInitialBalanceTemplateSuccess, setGetInitialBalanceTemplateFailure
} = balancesSlice.actions

export default balancesSlice.reducer;