import React, { useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'

import { forgotPassword, cleanUpForgotPassword } from '../../../store/auth/auth.actions';

// Components

import FormInput from '../../UI/FormInput/FormInput';
import Button from '../../UI/Button/Button';

export default function ForgotPassForm({setForm}) {
  const forgotPass = useSelector((state) => state.auth.forgotPassword);
  const dispatch = useDispatch();

  // Recover Password Request

  const recoverPasswordRequest = async (values, actions) => {

    dispatch(forgotPassword(values));

  }

  useEffect(() => {
    dispatch(cleanUpForgotPassword());
  }, [dispatch])

  useEffect(() => {
    if (forgotPass?.tokenSent) {
      setForm('resetpass')
    }
  }, [setForm, forgotPass])

  return (
    <>
        <h1>Recuperar contraseña</h1>

        <p className='auth-description'>Por favor introduzca su correo electrónico para iniciar el proceso de recuperar contraseña.</p>

        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => recoverPasswordRequest(values, actions)}
          >

            {props => (
            <>

              {forgotPass?.error && <p className="form-error-message">{forgotPass?.error}</p>}

              {/* Form Inputs */}

              <FormInput
                  formik={props}
                  fieldName={'email'}
                  placeholder={'Email'}
                  roundedStyle={true}
              />

              <button className='btn-plain' onClick={() => setForm('login')}>¿Ya tiene su contraseña? Acceder</button>
              
              <Button
                action={props.handleSubmit}
                label={'Enviar'}
                disabled={forgotPass?.isLoading}
                isLoading={forgotPass?.isLoading}
              />

            </>)}

        </Formik>
    </>
  )
}

// Form initial values and validaiton schemas

const initialValues = { email: '' };

const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Ingrese un email válido.')
      .required('Este campo es requerido'),
});
