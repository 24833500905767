import React from 'react'

import './Styles.scss';

export default function StatusLabel({status}) {
  return (
    <p className={status === 1 ? 'status-label' : 'status-label status-label-danger'}>
        {status === 1 ? 'Activo' : 'Inactivo'}
    </p>
  )
}