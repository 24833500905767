import React, {useEffect} from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { useSelector,useDispatch } from 'react-redux';

// Services

import { getUserInfo } from './store/auth/auth.actions';

// Pages

import UserAccess from "./pages/UserAccess/UserAccess";
import Dashboard from "./pages/Dashboard/Dashboard";
import BusinessGroups from "./pages/BusinessGroups/BusinessGroups";
import BusinessGroupDetail from "./pages/BusinessGroupDetail/BusinessGroupDetail";
import ClientDashboard from "./pages/ClientDashboard/ClientDashboard";
import ClientDetail from "./pages/ClientDetail/ClientDetail";
import Users from "./pages/Users/Users";
import ClientUsers from "./pages/ClientUsers/ClientUsers";
import Logs from "./pages/Logs/Logs";
import ChangesLogs from "./pages/ChangesLogs/ChangesLogs";
import Reports from "./pages/Reports/Reports";
import Options from "./pages/Options/Options";
import OptionsGroup from "./pages/OptionsGroup/OptionsGroup";
import Terms from "./pages/Terms/Terms";

function App() {
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getUserInfo());
    }, 300000);

    return () => {
      clearInterval(interval)
    }
  }, []);

  return (
    <Router>
      <>
        {(user.isLoggedIn && Number(user?.data?.accepted_terms_conditions) === 1) &&
        <Routes>
          <Route path="/" element={(user.data?.role !== 3 && user.data?.role !== 4) || user.data?.bussiness_group?.id || user.data?.customer_wallet ? <Dashboard/> : <ClientDashboard/>} />
          {user.data?.role !== 4 && <Route path="/business-groups" element={<BusinessGroups/>} />}
          {user.data?.role !== 4 && <Route path="/business-group-detail/:id" element={<BusinessGroupDetail/>} />}
          <Route path="/client-detail/:id" element={<ClientDetail/>} />
          {user.data?.role !== 4 && <Route path="/users" element={<Users/>} />}
          {user.data?.role !== 4 && <Route path="/users/:id" element={<ClientUsers/>} />}
          {(user.data?.role === 1 || user.data?.role === 2) && <Route path="/logs" element={<Logs/>} />}
          <Route path="/changes-logs" element={<ChangesLogs/>} />
          {(user.data?.role === 1 || user.data?.role === 2) && <Route path="/reports" element={<Reports/>} />}
          {user.data?.role === 1 && <Route path="/options" element={<Options/>} />}
          {user.data?.role === 1 && <Route path="/options/:id" element={<OptionsGroup/>} />}
          <Route path="*" element={<Navigate to ="/" />}/>
        </Routes>}

        {(user.isLoggedIn && Number(user?.data?.accepted_terms_conditions) === 0) &&
        <Routes>
          <Route path="/" element={<Terms/>} />
          <Route path="*" element={<Navigate to ="/" />}/>
        </Routes>}

        {!user.isLoggedIn &&
        <Routes>
          <Route path="/" element={<UserAccess/>} />
          <Route path="*" element={<Navigate to ="/" />}/>
        </Routes>}
     </>
    </Router>
  );
}

export default App;
