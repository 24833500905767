import { createSlice } from '@reduxjs/toolkit'

// Setup the initial state

const initialState = {
  user: {},
  userPermissions: {},
  forgotPassword: {},
  resetPassword: {},
  changePassword: {}
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {

    // Log In

    setLoginLoading: (state) => {
      state.user = {
        isLoading: true,
      };
    },
    setLoginSuccess: (state, action) => {
      state.user = {
        isLoggedIn: true,
        data: action.payload
      };
    },
    setLoginFailure: (state, action) => {
      state.user = {
        error: action.payload,
      }
    },

    // Set User Permissions

    setUserPermissions: (state, action) => {
      state.userPermissions = action.payload;
    },

    // Sign out

    signOut: (state, action) => {
      state.user = action.payload ? { error: action.payload } : {};
      state.userPermissions = {}
    },

    // Forgot Password

    setForgotPasswordLoading: (state) => {
      state.forgotPassword = {
        isLoading: true,
      };
    },
    setForgotPasswordSuccess: (state, action) => {
      state.forgotPassword = {
        tokenSent: true,
        email: action.payload
      };
    },
    setForgotPasswordFailure: (state, action) => {
      state.forgotPassword = {
        error: action.payload,
      }
    },
    forgotPasswordCleanup: (state) => {
      state.forgotPassword = {}
    },

    // Reset Password

    setResetPasswordLoading: (state) => {
      state.resetPassword = {
        isLoading: true,
      };
    },
    setResetPasswordSuccess: (state) => {
      state.resetPassword = {
        passwordSet: true,
      };
    },
    setResetPasswordFailure: (state, action) => {
      state.resetPassword = {
        error: action.payload,
      }
    },
    resetPasswordCleanup: (state) => {
      state.resetPassword = {}
    },

    // Change Password

    setChangePasswordLoading: (state) => {
      state.changePassword = {
        isLoading: true,
      };
    },
    setChangePasswordSuccess: (state) => {
      state.changePassword = {
        passwordChanged: true,
      };
    },
    setChangePasswordFailure: (state, action) => {
      state.changePassword = {
        error: action.payload,
      }
    },
    changePasswordCleanup: (state) => {
      state.changePassword = {}
    },
  },
})

export const {
  setLoginLoading, setLoginSuccess, setLoginFailure, signOut,
  setUserPermissions,
  setForgotPasswordLoading, setForgotPasswordSuccess, setForgotPasswordFailure, forgotPasswordCleanup,
  setResetPasswordLoading, setResetPasswordSuccess, setResetPasswordFailure, resetPasswordCleanup,
  setChangePasswordLoading, setChangePasswordSuccess, setChangePasswordFailure, changePasswordCleanup,
} = authSlice.actions

export default authSlice.reducer;