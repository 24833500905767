import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import Moment from 'moment';
import 'moment/locale/es';

import { getDropDownOptions } from '../../store/options/options.actions';
import { getTermsAndConditions } from '../../store/terms/terms.actions';
import { getInitialBalanceTemplate } from '../../store/balances/balances.actions';

// Components

import Loader from '../../components/UI/Loader/Loader';
import Modal from '../../components/UI/Modal/Modal';
import NoEntriesMessage from '../../components/UI/NoEntriesMessage/NoEntriesMessage';
import SectionHeader from '../../components/UI/SectionHeader/SectionHeader';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import SectionButton from '../../components/UI/SectionButton/SectionButton';
import Button from '../../components/UI/Button/Button';
import UploadTermsForm from '../../components/forms/UploadTermsForm/UploadTermsForm';
import UploadInitialBalanceTemplateForm from '../../components/forms/UploadInitialBalanceTemplateForm/UploadInitialBalanceTemplateForm';

// Constants

import {OPTION_GROUPS_NAMES} from '../../config/constants';

import './Styles.scss';

export default function Options() {
    const [showUploadTermsModal, setShowUploadTermsModal] = useState(false);
    const [showUploadInitialBalanceTemplateModal, setShowUploadInitialBalanceTemplateModal] = useState(false);
    const getTerms = useSelector((state) => state.terms.getTerms);
    const updateTerms = useSelector((state) => state.terms.updateTerms);
    const getBalanceTemplate = useSelector((state) => state.balances.getInitialBalanceTemplate);
    const updateInitialBalanceTemplate = useSelector((state) => state.balances.updateInitialBalanceTemplate);
    const dropDownOptions = useSelector((state) => state.options.dropDownOptions);
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getInitialBalanceTemplate());
      dispatch(getTermsAndConditions());
      dispatch(getDropDownOptions());
    }, [dispatch])

    useEffect(() => {
        if (updateTerms.isUpdated) {
            dispatch(getTermsAndConditions());
        }
    }, [dispatch, updateTerms])

    useEffect(() => {
        if (updateInitialBalanceTemplate.isUpdated) {
            dispatch(getInitialBalanceTemplate());
        }
    }, [dispatch, updateInitialBalanceTemplate])

    return (
        <div className='dashboard-wrapper'>

            <PageWrapper>

                {/* INITIAL BALANCE TEMPLATE */}

                <SectionHeader title={'Template carga balanza inicial'} />

                {(getBalanceTemplate?.isLoading || getBalanceTemplate.error) &&
                <Loader
                    message={'Cargando template...'}
                    error={getBalanceTemplate.error}
                    action={getInitialBalanceTemplate}
                />}

                {!getBalanceTemplate?.isLoading && getBalanceTemplate?.data &&
                <div className='settings-block'>
                    <div className="row settings-terms-card">
                        <div className='col-md-9'>
                            <p><strong>Ultima actualización:</strong> {Moment(getBalanceTemplate?.data?.last_update).format('DD MMM yyyy')}</p>
                            <p><strong>Cambios:</strong> {getBalanceTemplate?.data?.changes}</p>
                        </div>

                        <div className='col-md'>
                            <Button
                                action={() => setShowUploadInitialBalanceTemplateModal(true)}
                                label={'Subir template'}
                                />
                            <Button
                                label={'Ver template'}
                                action={() => window.open(getBalanceTemplate?.data?.url, '_blank')}
                                secondaryStyle={true}
                            />
                        </div>
                    </div>
                </div>}


                {/* TERMS AND CONDITIONS */}

                <SectionHeader title={'Términos y condiciones'} />

                {(getTerms?.isLoading || getTerms.error) &&
                <Loader
                    message={'Cargando términos...'}
                    error={getTerms.error}
                    action={getTermsAndConditions}
                />}

                {!getTerms?.isLoading && getTerms?.data &&
                <div className='settings-block'>
                    <div className="row settings-terms-card">
                        <div className='col-md-9'>
                            <p><strong>Ultima actualización:</strong> {Moment(getTerms?.data?.last_update).format('DD MMM yyyy')}</p>
                            <p><strong>Cambios:</strong> {getTerms?.data?.changes}</p>
                        </div>

                        <div className='col-md'>
                            <Button
                                action={() => setShowUploadTermsModal(true)}
                                label={'Subir términos'}
                                />
                            <Button
                                label={'Ver Términos'}
                                action={() => window.open(getTerms?.data?.url, '_blank')}
                                secondaryStyle={true}
                            />
                        </div>
                    </div>
                </div>}


                {/* LIST OPTIONS */}

                <SectionHeader title={'Editar opciones de listas'} />

                {(dropDownOptions?.isLoading || dropDownOptions.error) &&
                <Loader
                    message={'Cargando opciones...'}
                    error={dropDownOptions.error}
                    action={getDropDownOptions}
                />}

                {!dropDownOptions?.isLoading && dropDownOptions?.data && Object.keys(dropDownOptions?.data).length > 0 &&
                <div className='row'>

                    {dropDownOptions?.data && Object.keys(dropDownOptions?.data).map((key, i) => (
                    <div key={i} className='col-md-6'>
                        <SectionButton
                            title={OPTION_GROUPS_NAMES[key]}
                            path={`/options/${key}`}
                        />
                    </div>))}
                </div>}

                {!dropDownOptions?.isLoading && dropDownOptions?.data && Object.keys(dropDownOptions?.data).length === 0 &&
                    <NoEntriesMessage message={'No se encontraron opciones para esta busqueda'} />
                }
                
            </PageWrapper>


            {/* Upload Initial balance Template Modal */}

            <Modal show={showUploadInitialBalanceTemplateModal} title={'Subir template'}>
                <UploadInitialBalanceTemplateForm setShowUploadInitialBalanceTemplateModal={setShowUploadInitialBalanceTemplateModal} />
            </Modal>


            {/* Upload Terms Modal */}

            <Modal show={showUploadTermsModal} title={'Subir términos'}>
                <UploadTermsForm setShowUploadTermsModal={setShowUploadTermsModal} />
            </Modal>

        </div>
  )
}
