import React, { useEffect } from 'react'

import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux'
import * as yup from 'yup';
import Moment from 'moment';
import 'moment/locale/es';

import { exportClientBalance, cleanExportClientBalance } from '../../../store/balances/balances.actions';

// Components

import Button from '../../UI/Button/Button';
import SelectInput from '../../UI/FormInput/SelectInput';
import SwitchInput from '../../UI/FormInput/SwitchInput';

export default function ExportClientBalance({clientId, customerName, longestBalancesArray, setShowExportClientBalancetModal}) {
  const exportBalance = useSelector((state) => state.balances.exportBalance);
  const user = useSelector((state) => state.auth.user);
  const userPermissions = user?.data?.permissionsData;

  const dispatch = useDispatch();

  // Export Client Balance

  const exportTheClientBalance = async (values, actions) => {
    const balances = longestBalancesArray?.balances;
    const startMonth = values?.start_month ? balances.map(object => object.title).indexOf(values?.start_month) : null;
    const endMonth = values?.end_month ? balances.map(object => object.title).indexOf(values?.end_month) : null;
    const endMonthIndex = endMonth ? endMonth + 1 : null;
    let monthRange;
    let monthRangeNames = [];
  
    if (startMonth !== null && endMonthIndex !== null) {
      monthRange = balances?.slice(startMonth, endMonthIndex);
      monthRange.forEach(item => {
        monthRangeNames?.push(item.title);
      });
    }

    const data = {
      customer_id: clientId,
      months: monthRangeNames,
      filter_account_analysis: values?.filter_account_analysis
    }

    console.log('data', data)
    
    dispatch(exportClientBalance(data));
  }


  // Cleanup the storage on load

  useEffect(() => {
    dispatch(cleanExportClientBalance());
  }, [dispatch]);

  // Download exported Client's Balance

  useEffect(() => {
    if (exportBalance.data) {
        const name = (userPermissions.VisualizarTodosLasInformacionesClientes || userPermissions.VisualizarTodasLasInformacionesDelClienteMenosElId) ? customerName : clientId;
        const date = Moment().format('MMM Do YYYY, h:mm:ss a');
        const url = window.URL.createObjectURL(new Blob([exportBalance.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `balanza-${name}-${date}.xlsx`;
        link.click();

        dispatch(cleanExportClientBalance())
    }
  }, [exportBalance, customerName, dispatch]);

  return (
    <>

      <Formik
          initialValues={{
            start_month: '',
            end_month: '',
            balances: longestBalancesArray?.balances,
            filter_account_analysis: 0,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => exportTheClientBalance(values, actions)}
        >

          {props => (
          <>            

            {/* Form Inputs */}
            
            <>

              <SelectInput
                formik={props}
                fieldName={'start_month'}
                label={'Mes inicio'}
                placeholder={'Seleccione'}
                options={longestBalancesArray?.balances ? longestBalancesArray?.balances?.map(option => ({ value: option.title, label: option.title })) : []}
              />

              <SelectInput
                formik={props}
                fieldName={'end_month'}
                label={'Mes fin'}
                placeholder={'Seleccione'}
                options={longestBalancesArray?.balances ? longestBalancesArray?.balances?.map(option => ({ value: option.title, label: option.title })) : []}
              />

              <SwitchInput
                formik={props}
                fieldName={'filter_account_analysis'}
                label={'Exportar sólo Cuentas de Analisis'}
              />

              <span className='field-error'>{props.touched.start_month && props.errors.start_month}</span>
              <span className='field-error'>{props.touched.end_month && props.errors.end_month}</span>

              {/* Form actions */}
            
              <div className='form-actions'>

                <Button
                  action={() => setShowExportClientBalancetModal(false)}
                  label={'Cancelar'}
                  secondaryStyle={true}
                  disabled={exportBalance?.isLoading}
                />

                <Button
                  action={props.handleSubmit}
                  label={'Exportar'}
                  disabled={exportBalance?.isLoading}
                  isLoading={exportBalance?.isLoading}
                />

              </div>
            </>

          </>)}

      </Formik>

    </>
  )
}

// Form initial values and validaiton schemas

const validationSchema = yup.object().shape({
  start_month: yup.string().when("end_month", {
    is: (val) => val !== undefined && val !== '',
    then: yup.string().required('Este campo es requerido'),
    otherwise: yup.string(),
  }),
  end_month: yup.string().test("endMonthTest", "Mes fin no puedes ser menor al mes de inicio", function (value) {
    const startMonth = this?.parent?.start_month ? this.parent?.balances.map(object => object.title).indexOf(this?.parent?.start_month) : null;
    const endMonth = value ? this?.parent?.balances.map(object => object.title).indexOf(value) : null;

    if (startMonth <= endMonth) {
      return true;
    } else {
      return false;
    }
  }),
  balances: yup.array(),
  status: yup.number(),
});