import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faFileExport, faSpinner, faCalculator } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss';

const icons = {
    export: faFileExport,
    upload: faCloudArrowUp,
    calculate: faCalculator,
}

export default function ButtonSmall({label, icon, action, secondaryStyle, disabled, isLoading}) {
  return (
    <button className={secondaryStyle ? 'btn-small btn-small-alt' : 'btn-small'}
      onClick={() => action()}
      disabled={disabled}
      >
      {!isLoading ? (
        <>
          {icon && <FontAwesomeIcon className='btn-small-icon' icon={icons[icon]} />}
          <p>{label}</p>
        </>
      ) : (
        <>
          <FontAwesomeIcon  className='btn-small-icon' icon={faSpinner} spin />
          <p>Cargando...</p>
        </>
      )}
    </button>
  )
}
