import React from 'react'

import './Styles.scss';

export default function SectionHeader({title, isSubsection, subHeading, noMargins, hideHeading, children}) {
  return (
    <div className='section-header'>
      
      {!hideHeading ?
        isSubsection ?
          <h2 className={noMargins ? 'section-header-no-margins' : ''}>{title} {subHeading && <span className='section-header-subheading'>• {subHeading}</span>}</h2>
        : 
          <h1 className={noMargins ? 'section-header-no-margins' : ''}>{title} {subHeading && <span className='section-header-subheading'>• {subHeading}</span>}</h1>
      : 
        (null)
      }

      {children}

    </div>
  )
}
