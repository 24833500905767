import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss';

export default function ToggleAccountIssuesButton({showingAccountsWithIssues, numberofIssues, action}) {
  return (
    <div>
        <button className={`toggle-account-issues-button  ${showingAccountsWithIssues ? 'toggle-issues-active' : '' }`} onClick={() => action()}>
        <FontAwesomeIcon className='toggle-account-issues-icon' icon={faCircleExclamation} /> {numberofIssues ? numberofIssues : 0} cuentas issues
        </button>
    </div>
  )
}
