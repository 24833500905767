import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'

import { getAllCustomers } from '../../../store/customers/customers.actions';
import { createBusiness, cleanCreateBusiness } from '../../../store/business/business.actions';
import { getAdminUsers } from '../../../store/users/users.actions';

// Components

import FormInput from '../../UI/FormInput/FormInput';
import SelectInput from '../../UI/FormInput/SelectInput';
import CustomerGroupSelect from '../../UI/FormInput/CustomerGroupSelect';
import Button from '../../UI/Button/Button';
import Loader from '../../UI/Loader/Loader';

export default function AddCustomerGroupForm({setShowAddCustomerGroupModal}) {
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const createABusiness = useSelector((state) => state.business.createBusiness);
  const adminUsers = useSelector((state) => state.users.adminUsers);
  const customers = useSelector((state) => state.customers.customersAll);
  const dispatch = useDispatch();

  // Create Bussiness Group
  
  const businessCreate = async (values, actions) => {
    dispatch(createBusiness(values));
  }

  useEffect(() => {
    if (createABusiness.isCreated) {
      setShowAddCustomerGroupModal(false);
      dispatch(cleanCreateBusiness());
    }
  }, [dispatch, setShowAddCustomerGroupModal, createABusiness])

  useEffect(() => {
    getCustomersAndUsers();
  }, [dispatch])

  useEffect(() => {
    if (customers?.data) {
      setFilteredCustomers(customers?.data);
    }
  }, [customers])

  // Get Customers and uSers Data

  const getCustomersAndUsers = () => {
    dispatch(getAllCustomers());
    dispatch(getAdminUsers());
    dispatch(cleanCreateBusiness());
  }

  // Filter Admin Users by Selected Customers

  const filterAdminUsersBySelectedCustomers = (selectedCustomers) => {
    const usersData = adminUsers?.data;
    const filteredUsersData = usersData?.filter((user) => selectedCustomers?.includes(user.customer?.id));

    return filteredUsersData?.length > 0 ? filteredUsersData?.map(option => ({ value: option.id, label: `${option.name} (${option?.customer?.name})` })) : [];
  }

  // Search Clients

  const searchClients = (value) => {
    if (value !== '') {
        const data = filteredCustomers.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(value.toLowerCase())
        })
        setFilteredCustomers(data)
    } else {
      setFilteredCustomers(customers?.data)
    }
  }

  return (
    <>
      {(customers?.isLoading || customers?.error) &&
        <Loader
          message={'Cargando...'}
          error={customers.error}
          action={() => getCustomersAndUsers()}
          dark={true}
      />}

      {!customers?.isLoading && filteredCustomers &&
      <Formik
        initialValues={{ 
          name: '',
          user_id: user.data?.id, 
          customers: [],
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => businessCreate(values, actions)}
      >

        {props => (
        <>

          {createABusiness.error && <p className="form-error-message">{createABusiness.error}</p>}

          {/* Form Inputs */}

          <>
            {filteredCustomers && filteredCustomers &&
            <CustomerGroupSelect
              formik={props}
              fieldName={'customers'}
              label={'Selecione clientes'}
              placeholder={'Seleccione'}
              data={filteredCustomers ? filteredCustomers.map(customer => ({ value: customer.id, label: customer.name, inGroup: (customer.bussiness_group_id !== null && customer.bussiness_group_id !== 0) })) : []}
              searchAction={searchClients}
            />}
          </>

          <div style={{marginTop: '2rem'}}>
            <FormInput
              formik={props}
              fieldName={'name'}
              label={'Nombre del grupo'}
            />

            <SelectInput
              formik={props}
              fieldName={'user_id'}
              label={'Usuario del grupo'}
              placeholder={'Seleccione'}
              options={filterAdminUsersBySelectedCustomers(props.values?.customers)}
              disabled={props.values?.customers?.length === 0}
            />
          </div>
        
          <div className='form-actions'>

            <Button
              action={() => setShowAddCustomerGroupModal(false)}
              label={'Cancelar'}
              disabled={createABusiness?.isLoading}
              secondaryStyle={true}
            />

            <Button
              action={props.handleSubmit}
              label={'Añadir'}
              disabled={createABusiness?.isLoading}
              isLoading={createABusiness?.isLoading}
            />

          </div>
         
        </>)}

      </Formik>}
    </>
  )
}

// Form initial values and validaiton schemas

const validationSchema = yup.object().shape({
  name: yup.string().required('Este campo es requerido'),
  user_id: yup.number().required('Este campo es requerido'),
  customers: yup.array().min(1, 'Añada al menos un cliente')
});