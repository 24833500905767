import React from 'react';

import NumberFormat from 'react-number-format';

// Utilities

import { setNestedFieldName } from '../../../utilities/helper-functions';

import './Styles.scss';

export default function MaskInput(props) {
    const {
        formik,
        fieldName,
        label,
        placeholder,
        isRequired,
        mask,
        roundedStyle,
        disabled
    } = props;

    return (
        <div className={roundedStyle ? "field" : "field field-alt"}>
            {label &&
            <label>{label}</label>}

            {mask ? (
                <NumberFormat
                    className={formik.touched[fieldName] && formik.errors[fieldName] ? "input-error" : ""}
                    placeholder={placeholder}
                    name={fieldName}
                    value={fieldName.includes('.') ? setNestedFieldName(fieldName, formik.values) : formik.values[fieldName]}
                    required={isRequired}
                    disabled={disabled}
                    format={mask}
                    mask="_"
                    onValueChange={(values) => {
                        const { value } = values;
                        // formattedValue = $2,223
                        // value ie, 2223
                        formik.setFieldValue(fieldName, value)
                    }}
                    onBlur={formik.handleBlur(fieldName)}
                />
            ) : (
                <NumberFormat
                    className={formik.touched[fieldName] && formik.errors[fieldName] ? "input-error" : ""}
                    placeholder={placeholder}
                    name={fieldName}
                    value={fieldName.includes('.') ? setNestedFieldName(fieldName, formik.values) : formik.values[fieldName]}
                    required={isRequired}
                    disabled={disabled}
                    thousandSeparator={true}
                    prefix={'$'}
                    onValueChange={(values) => {
                        const { value } = values;
                        // formattedValue = $2,223
                        // value ie, 2223
                        formik.setFieldValue(fieldName, Number(value))
                    }}
                    onBlur={formik.handleBlur(fieldName)}
                />
            )}

            <span className='field-error'>{formik.touched[fieldName] && formik.errors[fieldName]}</span>

        </div>
    )
}
