import React, {useState, useEffect} from 'react';

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'


import { logIn } from '../../../store/auth/auth.actions';
import { cleanUpResetPassword, cleanUpForgotPassword } from '../../../store/auth/auth.actions';

// Components

import FormInput from '../../UI/FormInput/FormInput';
import Button from '../../UI/Button/Button';
import Alert from '../../UI/Alert/Alert';

export default function LogInForm({setForm}) {
  const [alertMessage, setAlertMessage] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const resetPass = useSelector((state) => state.auth.resetPassword);
  const dispatch = useDispatch();

  // Sign in the user

  const userSignIn = async (values, actions) => {

    dispatch(logIn(values));

  }

  // Show alert message when the use restores their password succesfully

  useEffect(() => {
    if (resetPass?.passwordSet) {
      showAlertMessage('Contraseña cambiada exitosamente.');
      dispatch(cleanUpResetPassword());
    }
  }, [dispatch, resetPass])

  // Show alert message

  const showAlertMessage = (message) => {
    setAlertMessage(message)
    setTimeout(() => {
        setAlertMessage(null)
    }, 4000);
  }

  return (
    <>
        <h1>Iniciar sesión</h1>

        {alertMessage &&
        <Alert message={alertMessage} />}

        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => userSignIn(values, actions)}
          >

            {props => (
            <>

              {user?.error && <p className="form-error-message">{user?.error}</p>}

              {/* Form Inputs */}

              <FormInput
                  formik={props}
                  fieldName={'email'}
                  placeholder={'Email'}
                  roundedStyle={true}
              />

              <FormInput
                  formik={props}
                  fieldName={'password'}
                  placeholder={'Contraseña'}
                  type={'password'}
                  roundedStyle={true}
              />

              <button className='btn-plain' onClick={() => {dispatch(cleanUpResetPassword()); dispatch(cleanUpForgotPassword()); setForm('forgotpass') }}>¿Olvido su contraseña?</button>

              <Button
                action={props.handleSubmit}
                label={'Acceder'}
                disabled={user?.isLoading}
                isLoading={user?.isLoading}
              />

            </>)}

        </Formik>
    </>
  )
}

// Form initial values and validaiton schemas

const initialValues = { email: '', password: '' };

const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Ingrese un email válido.')
      .required('Este campo es requerido'),
    password: yup
      .string()
      .required('Este campo es requerido'),
});
