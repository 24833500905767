import React from 'react';

// Utilities

import { setNestedFieldName } from '../../../utilities/helper-functions';

import './Styles.scss';

export default function FormInput(props) {
    const {
        formik,
        fieldName,
        label,
        placeholder,
        type,
        isRequired,
        roundedStyle,
        disabled
    } = props;

    return (
        <div className={roundedStyle ? "field" : "field field-alt"}>
            {label &&
            <label>{label}</label>}

            <input
                className={formik.touched[fieldName] && formik.errors[fieldName] ? "input-error" : ""}
                type={type ? type : "text"}
                placeholder={placeholder}
                name={fieldName}
                value={fieldName.includes('.') ? setNestedFieldName(fieldName, formik.values) : formik.values[fieldName]}
                onChange={formik.handleChange(fieldName)}
                onBlur={formik.handleBlur(fieldName)}
                required={isRequired}
                disabled={disabled}
            />

            <span className='field-error'>{formik.touched[fieldName] && formik.errors[fieldName]}</span>

        </div>
    )
}
