import React, {useEffect} from 'react';

import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";

import './Styles.scss';

registerLocale('es', es)

export default function DateRangeInput({startDate, endDate, setStartDate, setEndDate, action}) {

    const handleResetDateRange = () => {
        setStartDate(null);
        setEndDate(null);
        action();
    }

    useEffect(() => {
        if (startDate && endDate) {
            action(null, startDate, endDate);
        }
    }, [startDate, endDate])

    return (
        <div className={'date-range-picker'}>
            <p>Filtrar</p>
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText='Fecha desde'
                dateFormat="dd-MMMM-yyyy"
                locale="es"
            />

            <span>•</span>

            <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText='Fecha hasta'
                dateFormat="dd-MMMM-yyyy"
                locale="es"
            />

            <button className='date-range-picker-reset-button' title='Borrar filtro' onClick={() => handleResetDateRange()} disabled={(!startDate || !endDate)}>X</button>
        </div>
    )
}
