import React from 'react'
import { NavLink } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBuilding,
  faHouse, 
  faUsers, 
  faChartSimple,
  faGear,
  faListCheck,
  faBarsStaggered
} from '@fortawesome/free-solid-svg-icons';

import './Styles.scss';

export default function Navigation() {
  const user = useSelector((state) => state.auth.user);

  return (
    <nav className='main-nav'>
        <ul>
            <NavButton path='/' icon={faHouse} label={'Inicio'} />

            {user.data && Number(user?.data?.accepted_terms_conditions) === 1 &&
            <>
              {user.data?.role !== 4 && <NavButton path='/business-groups' icon={faBuilding} label={'Grupos'} />}
              {user.data?.role !== 4 && <NavButton path='/users' icon={faUsers} label={'Usuarios'} />}
              {(user.data?.role === 1 || user.data?.role === 2) && <NavButton path='/reports' icon={faChartSimple} label={'Reportes'} />}
              {(user.data?.role === 1 || user.data?.role === 2) && <NavButton path='/logs' icon={faBarsStaggered} label={'Logs Vallges'} />}
              <NavButton path='/changes-logs' icon={faListCheck} label={'Cambios'} />
              {user.data?.role === 1 && <NavButton path='/options' icon={faGear} label={'Configuración'} />}
            </>}
        </ul>
    </nav>
  )
}

const NavButton = ({path, icon, label}) => (
  <li>
    <NavLink to={path}>
      <span>{label}</span>
      <FontAwesomeIcon icon={icon} />
    </NavLink>
  </li>
);