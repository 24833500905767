import { createSlice } from '@reduxjs/toolkit'

// Setup the initial state

const initialState = {
  reports: {},
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {

    // Get a report of the specified type

    setGetReportLoading: (state) => {
      state.reports = {
        isLoading: true,
      };
    },
    setGetReportScuccess: (state, action) => {
      state.reports = {
        data: action.payload
      };
    },
    setGetReportFailure: (state, action) => {
      state.reports = {
        error: action.payload,
      }
    },

    getReportCleanUp: (state, action) => {
      state.reports = {}
    },

  },
})

export const { 
  setGetReportLoading, setGetReportScuccess, setGetReportFailure, getReportCleanUp,
} = reportsSlice.actions

export default reportsSlice.reducer;