import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'

// Actions

import { editCustomer, cleanUpUpdateCustomer, deleteCustomer, cleanUpDeleteCustomer } from '../../../store/customers/customers.actions';
import { getSingleCustomerWithUsers } from '../../../store/users/users.actions';
import { getDropDownOptions } from '../../../store/options/options.actions';

// Components

import FormInput from '../../UI/FormInput/FormInput';
import FormSteps from '../../UI/FormSteps/FormSteps';
import FileInput from '../../UI/FormInput/FileInput';
import SelectInput from '../../UI/FormInput/SelectInput';
import MaskInput from '../../UI/FormInput/MaskInput';
import Button from '../../UI/Button/Button';
import SwitchInput from '../../UI/FormInput/SwitchInput';
import Loader from '../../UI/Loader/Loader';

// Constants

import { BALANCE_OPTIONS, VALLGES_CLIENT_ID, FISCAL_MONTHS } from '../../../config/constants';

// Utilities

import { setupFormData } from '../../../utilities/helper-functions';

const steps = [
  'Datos generales',
  'Datos financieros'
]

export default function EditClientForm({client, setShowEditClientModal}) {
  const [formStep, setFormStep] = useState(0);
  const customerUpdate = useSelector((state) => state.customers.updateCustomer);
  const customerDelete = useSelector((state) => state.customers.deleteCustomer);
  const vallgesUsers = useSelector((state) => state.users.singleCustomerUsers);
  const dropDownOptions = useSelector((state) => state.options.dropDownOptions);
  const user = useSelector((state) => state.auth.user);
  const userPermissions = user?.data?.permissionsData;

  const dispatch = useDispatch();

  // Edit a customer

  const customerEdit = async (values, actions) => {
    if (formStep === 1) {
      values._method = 'PUT';
      const data = setupFormData(values);

      dispatch(editCustomer(data, client.id));
    } else {
      setFormStep(formStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  // Delete a customer

  const deleteACustomer = async () => {
    if (window.confirm("Desea borrar este cliente?")) {
      dispatch(deleteCustomer(client.id));
    }
  }

  useEffect(() => {
    dispatch(getSingleCustomerWithUsers(VALLGES_CLIENT_ID))
    dispatch(getDropDownOptions())
  }, [dispatch])

  useEffect(() => {
    if (customerUpdate.isUpdated || customerDelete.isDeleted) {
      setShowEditClientModal(false);
      dispatch(cleanUpUpdateCustomer());
      dispatch(cleanUpDeleteCustomer());
    }
  }, [dispatch, setShowEditClientModal, customerUpdate, customerDelete])

  return (
    <>

      {(vallgesUsers?.isLoading || vallgesUsers?.error || dropDownOptions?.isLoading || dropDownOptions?.error) &&
        <Loader
          message={'Cargando...'}
          error={vallgesUsers.error || dropDownOptions.error}
          action={() =>  {
              dispatch(getSingleCustomerWithUsers(VALLGES_CLIENT_ID))
              dispatch(getDropDownOptions())
            }
          }
          dark={true}
      />}

      {!vallgesUsers?.isLoading && vallgesUsers?.data && !dropDownOptions?.isLoading && dropDownOptions?.data &&
      <>

        <FormSteps steps={steps} currentStep={formStep} />

        <Formik
          initialValues={{
            name: client.name,
            rnc: client.rnc, 
            status: Number(client.status),
            logo: '',
            address: client.address,
            phone: client.phone,
            email: client.email,
            contact_person: client.contact_person,
            segment: client.segment,
            sector: client.sector,
            balance_type: Number(client.balance_type),
            fiscal_start: client.fiscal_start,
            sales_level: client.sales_level, 
            employees_number: client.employees_number, 
            asigned_executive: Number(client.asigned_executive),
            associate_name: Number(client.associate_name),
            associates_income: client.associates_income,
            client_referral: Number(client.client_referral),
            referral_comission: client.referral_comission,
          }}
          validationSchema={validationSchema[formStep]}
          onSubmit={(values, actions) => customerEdit(values, actions)}
        >

          {props => (
          <>

            {customerUpdate.error && <p className="form-error-message">{customerUpdate.error}</p>}
            {customerDelete.error && <p className="form-error-message">{customerDelete.error}</p>}

            {/* Form Inputs */}

            {formStep === 0 &&
            <>
              {userPermissions.EditarDatosGeneralesClientes &&
              <SwitchInput
                formik={props}
                fieldName={'status'}
                label={'Activo'}
              />}

              <FormInput
                  formik={props}
                  fieldName={'name'}
                  label={'Empresa'}
                  disabled={!userPermissions.EditarDatosGeneralesClientes}
              />

              <MaskInput
                formik={props}
                fieldName={'rnc'}
                label={'RNC'}
                mask={'#########'}
                disabled={!userPermissions.EditarDatosGeneralesClientes}
              />

              {!userPermissions.EditarDatosGeneralesClientes &&
              <FileInput
                  formik={props}
                  fieldName={'logo'}
                  label={'Logo'}
                  fileType={'image'}
              />}

              <SelectInput
                  formik={props}
                  fieldName={'segment'}
                  label={'Segmento'}
                  placeholder={'Seleccione'}
                  options={dropDownOptions?.data ? dropDownOptions?.data?.segments.map(option => ({ value: option.name, label: option.name })) : []}
                  disabled={!userPermissions.EditarDatosGeneralesClientes}
              />

              <SelectInput
                  formik={props}
                  fieldName={'sector'}
                  label={'Sector'}
                  placeholder={'Seleccione'}
                  options={dropDownOptions?.data ? dropDownOptions?.data?.sectors.map(option => ({ value: option.name, label: option.name })) : []}
                  disabled={!userPermissions.EditarDatosGeneralesClientes}
              />

              <FormInput
                  formik={props}
                  fieldName={'address'}
                  label={'Dirección'}
                  disabled={!userPermissions.EditarDatosGeneralesClientes}
              />

              <MaskInput
                formik={props}
                fieldName={'phone'}
                label={'Teléfono'}
                mask={'(###) ### ####'}
                disabled={!userPermissions.EditarDatosGeneralesClientes}
              />

              <FormInput
                  formik={props}
                  fieldName={'email'}
                  label={'Email'}
                  disabled={!userPermissions.EditarDatosGeneralesClientes}
              />

              <FormInput
                  formik={props}
                  fieldName={'contact_person'}
                  label={'Persona de contacto'}
                  disabled={!userPermissions.EditarDatosGeneralesClientes}
              />
            
              <div className='form-actions'>

                <Button
                  action={() => setShowEditClientModal(false)}
                  label={'Cancelar'}
                  secondaryStyle={true}
                />

                <Button
                  action={props.handleSubmit}
                  label={'Continuar'}
                />

              </div>
            </>}

            {formStep === 1 &&
            <>
              <SelectInput
                  formik={props}
                  fieldName={'balance_type'}
                  label={'Tipo Balanza'}
                  placeholder={'Seleccione'}
                  options={BALANCE_OPTIONS}
                  disabled={true}
              />

              <SelectInput
                  formik={props}
                  fieldName={'fiscal_start'}
                  label={'Inicio Fiscal'}
                  placeholder={'Seleccione'}
                  options={FISCAL_MONTHS}
                  disabled={true}
              />

              <MaskInput
                  formik={props}
                  fieldName={'sales_level'}
                  label={'Nivel de ventas'}
                  disabled={!userPermissions.EditarDatosFinancierosClientes}
              />

              <FormInput
                  formik={props}
                  fieldName={'employees_number'}
                  label={'Cantidad de empleados'}
                  type={'number'}
                  disabled={!userPermissions.EditarDatosFinancierosClientes}
              />

              <SelectInput
                  formik={props}
                  fieldName={'asigned_executive'}
                  label={'Ejecutivo asignado'}
                  placeholder={'Seleccione'}
                  options={vallgesUsers?.data ? vallgesUsers?.data?.users.map(user => ({ value: user.id, label: user.name })) : []}
                  disabled={!userPermissions.EditarDatosFinancierosClientes}
              />

              <SelectInput
                  formik={props}
                  fieldName={'associate_name'}
                  label={'Nombre asociado'}
                  placeholder={'Seleccione'}
                  options={dropDownOptions?.data ? dropDownOptions?.data?.associates.map(option => ({ value: option.id, label: option.name })) : []}
                  disabled={!userPermissions.EditarDatosFinancierosClientes}
              />

              <MaskInput
                formik={props}
                fieldName={'associates_income'}
                label={'Ingreso por asociados'}
                disabled={!userPermissions.EditarDatosFinancierosClientes}
              />

              <SelectInput
                  formik={props}
                  fieldName={'client_referral'}
                  label={'Cliente referido por'}
                  placeholder={'Seleccione'}
                  options={dropDownOptions?.data ? dropDownOptions?.data?.referrals.map(option => ({ value: option.id, label: option.name })) : []}
                  disabled={!userPermissions.EditarDatosFinancierosClientes}
              />

              <MaskInput
                  formik={props}
                  fieldName={'referral_comission'}
                  label={'Comisión referido'}
                  disabled={!userPermissions.EditarDatosFinancierosClientes}
              />
            
              <div className='form-actions'>

                <Button
                  action={() => setFormStep(formStep - 1)}
                  label={'Atrás'}
                  disabled={customerUpdate?.isLoading}
                  secondaryStyle={true}
                />

                <Button
                  action={props.handleSubmit}
                  label={'Editar'}
                  disabled={customerUpdate?.isLoading || user.data?.role === 3}
                  isLoading={customerUpdate?.isLoading}
                />

              </div>

            </>}

            {user.data?.role === 1 &&
            <button
              className='btn-danger'
              type='button'
              style={customerDelete?.isLoading ? {opacity: 0.5} : {}}
              disabled={customerDelete?.isLoading}
              onClick={() => deleteACustomer()}
            >
              {!customerDelete?.isLoading ? (
                  'Borrar Cliente'
                ) : (
                  'Borrarndo...'
                )}
            </button>}

          </>)}

        </Formik>

      </>}
    </>
  )
}

// Form initial values and validaiton schemas


const validationSchema = [
  yup.object().shape({
    name: yup
      .string()
      .required('Este campo es requerido'),
    rnc: yup
      .string()
      .min(9)
      .required('Este campo es requerido'),
    logo: yup
      .string(),
    segment: yup
      .string()
      .required('Este campo es requerido'),
    sector: yup
      .string()
      .required('Este campo es requerido'),
    address: yup
      .string()
      .required('Este campo es requerido'),
    phone: yup
      .string()
      .min(10)
      .required('Este campo es requerido'),
    email: yup
      .string()
      .required('Este campo es requerido'),
    contact_person: yup
      .string()
      .required('Este campo es requerido'),
  }),
  yup.object().shape({
    balance_type: yup
      .date()
      .required('Este campo es requerido'),
    fiscal_start: yup
      .string()
      .required('Este campo es requerido'),
    sales_level: yup
      .string()
      .required('Este campo es requerido'),
    employees_number: yup
      .string()
      .required('Este campo es requerido'),
    asigned_executive: yup
      .string()
      .required('Este campo es requerido'),
    associate_name: yup
      .string(),
    associates_income: yup
      .string(),
    client_referral: yup
      .string(),
    referral_comission: yup
      .string(),
  })
];