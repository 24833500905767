import React, { useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'


import { createOptionForDropDownGroup, cleanUpCreateOption } from '../../../store/options/options.actions';

// Components

import FormInput from '../../UI/FormInput/FormInput';
import SelectInput from '../../UI/FormInput/SelectInput';
import Button from '../../UI/Button/Button';

// Constants

import { ASSOCIATE_TYPE_FORMATTED } from '../../../config/constants';

export default function AddOptionForm({group, setShowAddOptionModal}) {
  const optionCreate = useSelector((state) => state.options.createOption);
  const dispatch = useDispatch();

  // Create an option

  const createNewOption = async (values, actions) => {
    dispatch(createOptionForDropDownGroup(group, values));
  }

  useEffect(() => {
    if (optionCreate.isCreated) {
      setShowAddOptionModal(false);
      dispatch(cleanUpCreateOption());
    }
  }, [dispatch, setShowAddOptionModal, optionCreate])

  useEffect(() => {
    dispatch(cleanUpCreateOption());
  }, [dispatch])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => createNewOption(values, actions)}
    >

      {props => (
      <>

        {optionCreate.error && <p className="form-error-message">{optionCreate.error}</p>}

        {/* Form Inputs */}

        <>
          <FormInput
              formik={props}
              fieldName={'name'}
              label={'Nombre'}
          />

          {group === 'associates' &&
          <SelectInput
              formik={props}
              fieldName={'type'}
              label={'Tipo asociado'}
              placeholder={'Seleccione'}
              options={ASSOCIATE_TYPE_FORMATTED}
          />}
        
          <div className='form-actions'>

            <Button
              action={() => setShowAddOptionModal(false)}
              label={'Cancelar'}
              disabled={optionCreate?.isLoading}
              secondaryStyle={true}
            />

            <Button
              action={props.handleSubmit}
              label={'Añadir'}
              disabled={optionCreate?.isLoading}
              isLoading={optionCreate?.isLoading}
            />

          </div>
        </>

      </>)}

    </Formik>
  )
}

// Form initial values and validaiton schemas

const initialValues = { 
  name: '',
  type: '',
};

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Este campo es requerido'),
  type: yup
    .string()
});