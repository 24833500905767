import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'


import { getUserRolesAndPermissions, editUser, cleanUpUpdateUser, deleteUser, cleanUpDeleteUser } from '../../../store/users/users.actions';
import { getAllCustomers } from '../../../store/customers/customers.actions';

// Components

import FormInput from '../../UI/FormInput/FormInput';
import FormSteps from '../../UI/FormSteps/FormSteps';
import SelectInput from '../../UI/FormInput/SelectInput';
import Loader from '../../UI/Loader/Loader';
import Button from '../../UI/Button/Button';
import SwitchInput from '../../UI/FormInput/SwitchInput';
import PermissionsList from '../../lists/PermissionsList/PermissionsList';

// Utilities

import { formatRolesOptionsData, setUpUserPermissionsArray, formatPermissionsOptionsData } from '../../../utilities/helper-functions';

// Data

// import {USER_ROLES_OPTIONS,CLIENT_USER_ROLES_OPTIONS} from '../../../config/constants';

const steps = [
  'Datos generales',
  'Permisos'
]

export default function EditUserForm({userData, setShowEditUserModal}) {
  const [formStep, setFormStep] = useState(0);
  const userUpdate = useSelector((state) => state.users.updateUser);
  const userDelete = useSelector((state) => state.users.deleteUser);
  const rolesAndPermissions = useSelector((state) => state.users.rolesAndPermissions);
  const customers = useSelector((state) => state.customers.customersAll);
  const user = useSelector((state) => state.auth.user);
  const userPermissions = user?.data?.permissionsData;

  const dispatch = useDispatch();

  // Edit a user

  const userEdit = async (values, actions) => {
    if (formStep === 1) {
      dispatch(editUser(values, userData.id));
    } else {
      setFormStep(formStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  // Delete a user

  const deleteAUser = async () => {
    if (window.confirm("Desea borrar este usuario?")) {
      dispatch(deleteUser(userData.id));
    }
  }

  useEffect(() => {
    if (userUpdate.isUpdated || userDelete.isDeleted) {
      setShowEditUserModal(false);
      dispatch(cleanUpUpdateUser());
      dispatch(cleanUpDeleteUser());
    }
  }, [dispatch, setShowEditUserModal, userUpdate, userDelete])

  useEffect(() => {
    dispatch(getAllCustomers());
    dispatch(getUserRolesAndPermissions());
    dispatch(cleanUpUpdateUser());
  }, [dispatch])

  return (
    <>
      {(customers?.isLoading || customers?.error || rolesAndPermissions?.isLoading || rolesAndPermissions?.error) &&
        <Loader
          message={'Cargando...'}
          error={customers.error || rolesAndPermissions?.error}
          action={() => {
            dispatch(getAllCustomers());
            dispatch(getUserRolesAndPermissions());
          }}
          dark={true}
      />}

      {(!customers?.isLoading && customers?.data && !rolesAndPermissions?.isLoading && rolesAndPermissions?.data) &&
      <>
        <FormSteps steps={steps} currentStep={formStep} />

        <Formik
          initialValues={{ 
            status: userData.status,
            customer_id: Number(userData.customer_id),
            name: userData.name,
            lastname: userData.lastname,
            email: userData.email,
            // password: '',
            // repassword: '',
            role: userData.role, 
            permissions: userData?.permissions?.length > 0 ? setUpUserPermissionsArray(userData.permissions) : [],
          }}
          validationSchema={validationSchema[formStep]}
          onSubmit={(values, actions) => userEdit(values, actions)}
        >

          {props => (
          <>

            {userUpdate.error && <p className="form-error-message">{userUpdate.error}</p>}

            {/* Form Inputs */}

            {formStep === 0 &&
            <>
              <SwitchInput
                formik={props}
                fieldName={'status'}
                label={'Activo'}
              />

              {customers && customers.data &&
              <SelectInput
                formik={props}
                fieldName={'customer_id'}
                label={'Cliente'}
                placeholder={'Seleccione'}
                options={customers?.data ? customers.data.map(customer => ({ value: customer.id, label: customer.name })) : []}
              />}

              <FormInput
                  formik={props}
                  fieldName={'name'}
                  label={'Nombre'}
              />

              <FormInput
                  formik={props}
                  fieldName={'lastname'}
                  label={'Apellido'}
              />

              <FormInput
                  formik={props}
                  fieldName={'email'}
                  label={'Email'}
              />

              <FormInput
                  formik={props}
                  fieldName={'password'}
                  label={'Contraseña'}
              />

              <FormInput
                  formik={props}
                  fieldName={'password_confirmation'}
                  label={'Repetir Contraseña'}
              />

              <p style={{textAlign: 'right'}}><small>* Contraseña de minimo 8 caracteres</small></p>
            
              <div className='form-actions'>

                <Button
                  action={() => setShowEditUserModal(false)}
                  label={'Cancelar'}
                  secondaryStyle={true}
                />

                <Button
                  action={props.handleSubmit}
                  label={'Continuar'}
                />

              </div>

              {user.data?.role === 1 &&
              <button
                className='btn-danger'
                style={userDelete?.isLoading ? {opacity: 0.5} : {}}
                disabled={userDelete?.isLoading}
                onClick={() => deleteAUser()}
              >
                {!userDelete?.isLoading ? (
                    'Borrar Usuario'
                  ) : (
                    'Borrarndo...'
                  )}
              </button>}
            </>}

            {formStep === 1 &&
            <>
              <SelectInput
                formik={props}
                fieldName={'role'}
                label={'Rol'}
                placeholder={'Seleccione'}
                options={formatRolesOptionsData(rolesAndPermissions?.data, userPermissions)}
                additionalFunctions={() => props.setFieldValue('permissions', [])}
              />

              {props.values.role &&
              <PermissionsList
                data={formatPermissionsOptionsData(rolesAndPermissions?.data, props.values.role)}
                formik={props}
                fieldName={'permissions'}
              />}

              <div className='form-actions'>

              <Button
                action={() => setFormStep(formStep - 1)}
                label={'Atrás'}
                disabled={userUpdate?.isLoading}
                secondaryStyle={true}
              />

              <Button
                action={props.handleSubmit}
                label={'Editar'}
                disabled={userUpdate?.isLoading}
                isLoading={userUpdate?.isLoading}
              />

              </div>
            </>}

          </>)}

        </Formik>
      </>}
    </>
  )
}

// Form initial values and validaiton schemas

const validationSchema = [
  yup.object().shape({
    customer_id: yup
      .string()
      .required('Este campo es requerido'),
    name: yup
      .string()
      .required('Este campo es requerido'),
    lastname: yup
      .string()
      .required('Este campo es requerido'),
    email: yup
      .string()
      .email('Ingrese un email válido.')
      .required('Este campo es requerido'),
    password: yup
      .string()
      .matches(/^\S+$/, { message: 'Contraseña no puede tener espacios' })
      .min(8, 'Contraseña de minimo 8 caracteres'),
    password_confirmation: yup
      .string()
      .oneOf(
          [yup.ref('password')], 'Las contraseñas no coinciden',
      ),
    status: yup
      .number(),
  }),
  yup.object().shape({
    role: yup
      .string()
      .required('Este campo es requerido'),
    permissions: yup
      .array()
      .min(1, 'Seleccione por lo menos un permiso')
      .required('Este campo es requerido'),
  }),
];