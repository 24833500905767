import React from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableCellsLarge, faList } from '@fortawesome/free-solid-svg-icons';

import { handleToggleClientListStyle } from '../../../store/settings/settings.actions';

import './Styles.scss';

export default function ClientListToggleButtons() {
    const clientListStyle = useSelector((state) => state.settings.clientListStyle);
    const dispatch = useDispatch();

    return (
        <div className='clients-list-style-toggle'>
            <button onClick={() => dispatch(handleToggleClientListStyle(false))} disabled={!clientListStyle}>
                <FontAwesomeIcon icon={faTableCellsLarge} />
            </button>
            <button onClick={() =>  dispatch(handleToggleClientListStyle(true))} disabled={clientListStyle}>
                <FontAwesomeIcon icon={faList} />
            </button>
        </div>
    )
}
