import React from 'react'

import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss';

export default function Loader({message, error, action, dark}) {
  const dispatch = useDispatch();

  return (
    <div className={dark ? 'loader loader-dark' : 'loader'}>
      {error ? (
        <>
          <FontAwesomeIcon className='loader-icon' icon={faCircleExclamation} />
          <p>{error}</p>
          <button onClick={() => dispatch(action)}>Reintentar</button>
        </>
      ) : (
        <>
          <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          <p>{message}</p>
        </>
      )}
    </div>
  )
}
