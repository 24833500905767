import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { cleanSessionStore } from '../../../store/auth/auth.actions';

// Components

import Modal from '../../UI/Modal/Modal';
import ChangePassword from '../../forms/ChangePassword/ChangePassword';

import './Styles.scss';

export default function AccountUtilities() {
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    //   const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
  
  return (
    <>

        <div className='account-utilities'>

            {/* <div className='account-utilities-notifications'>
                <FontAwesomeIcon className='notifications-icon' icon={faBell} />
                <p className='notifications-badge'>5</p>
            </div> */}

            <div className='account-utilities-menu'>

                <p>Mi Cuenta</p>

                <div className='account-utilities-user-icon'>
                    <FontAwesomeIcon className='user-icon' icon={faUser} />
                </div>

                <div className='account-utilities-submenu'>
                    <ul>
                        <li><button onClick={() => setShowEditUserModal(true)}>Cambiar Contraseña</button></li>
                        <li><button onClick={() => dispatch(cleanSessionStore())}>Cerrar Sesión</button></li>
                    </ul>
                </div>

            </div>

        </div>

        
        {/* Edit User Modal */}

        <Modal show={showEditUserModal} title={'Cambiar Contraseña'}>
            <ChangePassword
                setShowEditUserModal={setShowEditUserModal}
            />
        </Modal>

    </>
  )
}
