
import api from "../config/api";
import BaseService from './base-service';

export default class AuthService extends BaseService {

    // Sign In the user ====================================================================================================

    /**
     * @param data {
            @string email,
            @string password
        }
    * @returns The user's data
    */

    static logIn(data) {
        const url = `/auth/login`

        return api.post(url, data);
    }
        

    // Forgot Password ====================================================================================================

    /**
     * @param data {
            @string email
        }
    * @returns Sends an email to the user with the password reset token
    */

    static forgotPassword(data) {
        const url = `/password/forgot-password`

        return api.post(url, data);
    }


    // Password Reset ====================================================================================================

    /**
     * @param data {
            @string email
            @string token
            @string password
            @string password_confirmation
        }
    * @returns True or false if the password was succesfully reset
    */

    static resetPassword(data) {
        const url = `/password/reset`

        return api.post(url, data);
    }


    // Change Password ====================================================================================================

    /**
     * @param data {
            @string current_password
            @string password
            @string password_confirmation
        }
    * @returns True or false if the password was succesfully changed
    */

    static changePassword(data) {
        const url = `/auth/update-password`

        return api.post(url, data);
    }
    
}
