// API
import BalancesService from '../../services/balances.service';

import {
  setGetBalanceCalculationLoading, setGetBalanceCalculationScuccess, setGetBalanceCalculationFailure,
  setUploadBalanceDataLoading, setUploadBalanceDataScuccess, setUploadBalanceDataFailure, uploadBalanceDataCleanup,
  setEditBalanceAccountLoading, setEditBalanceAccountScuccess, setEditBalanceAccountFailure, editBalanceAccountCleanup,
  setBulkBalanceAccountEditLoading, setBulkBalanceAccountEditScuccess, setBulkBalanceAccountEditFailure, bulkBalanceAccountEditCleanup,
  setCalculateBalanceFlowLoading, setCalculateBalanceFlowScuccess, setCalculateBalanceFlowFailure, calculateBalanceFlowCleanup,
  setExportClientBalanceLoading, setExportClientBalanceScuccess, setExportClientBalanceFailure, exportClientBalanceCleanup,
  setUpdateInitialBalanceTemplateLoading, setUpdateInitialBalanceTemplateSuccess, setUpdateInitialBalanceTemplateFailure, cleanUpdateInitialBalanceTemplate,
  setGetInitialBalanceTemplateLoading, setGetInitialBalanceTemplateSuccess, setGetInitialBalanceTemplateFailure
 } from './balances.reducers';


  // Get Customer's Balance Calculation ====================================================================================================

  /**
  * @param customerId the id of the customer
  * @returns Return the customer's balance calculation
  */

  export const getBalanceCalculation = (customerId) => async (dispatch) => {
    dispatch(setGetBalanceCalculationLoading());

    try {

      const response = await BalancesService.getBalanceCalculation(customerId);

      dispatch(setGetBalanceCalculationScuccess(response.data));

    } catch (error) {
      dispatch(setGetBalanceCalculationFailure(error.response?.data?.message || error.message));
    }

  }


// Uplaod Customer's Balance Data ====================================================================================================

/**
 * @param data {
        @number customer_id,
        @file   balances,
        @number years,
        @string import_type,
        @number months,
    }
* @returns Return true or false if the balance data was uploaded
*/

export const uploadBalanceData = (data) => async (dispatch) => {
  dispatch(setUploadBalanceDataLoading());

  try {

    const response = await BalancesService.uploadBalanceData(data);

    dispatch(setUploadBalanceDataScuccess(response.data));

  } catch (error) {
    if (error.response?.status === 400 && error.response?.data?.length > 0) {
      dispatch(setUploadBalanceDataFailure(error.response?.data[0]));
    } else {
      dispatch(setUploadBalanceDataFailure(error.response?.data?.message || error.message));
    }
  }

}


// Uplaod Customer's Balance Data Cleanup ====================================================================================================

/**
* @description Cleans up Uplaod Balance Data store
*/

export const cleanUpUploadBalanceData = () => (dispatch) => {
  dispatch(uploadBalanceDataCleanup())
};



 // Edit a Customer's Balance Account ====================================================================================================

/**
 * @param data {
        @number customer_id,
        @string account,
        @string description,
        @string account_classification,
        @string active_passive_si,
        @string account_eeff,
        @string cost_expense,
        @string fixed_variable_ex_type,
        @string flow_heading,
        @object balance {
            @number id,
            @string title,
            @string balance,
            @string account
        }
    }
* @param balanceAccountId the id of the balance account
* @returns Edits a balance account info
*/

export const editBalanceAccount = (data, balanceAccountId) => async (dispatch) => {
  dispatch(setEditBalanceAccountLoading());

  try {

    const response = await BalancesService.editBalanceAccount(data, balanceAccountId);

    dispatch(setEditBalanceAccountScuccess(response.data));

  } catch (error) {
    dispatch(setEditBalanceAccountFailure(error.response?.data?.message || error.message));
  }

}


// Edit a Customer's Balance Account Cleanup ====================================================================================================

/**
* @description Cleans up Edit a Customer's Balance Account store
*/

export const cleanUpEditBalanceAccount = () => (dispatch) => {
  dispatch(editBalanceAccountCleanup())
};


  // Bulk Edit Customer's Balance Accounts ====================================================================================================

/**
 * @param data {
        @number customer_id,
        @array account ids,
        @string account_classification,
        @string active_passive_si,
        @string account_eeff,
        @string fixed_variable_ex_type,
        @string flow_heading,
        @string partida_gp,
        @string tax_risk,
    }
* @returns Bulk Edit Customer's Balance Accounts
*/

export const bulkEditBalanceAccounts = (data) => async (dispatch) => {
  dispatch(setBulkBalanceAccountEditLoading());

  try {

    const response = await BalancesService.bulkEditBalanceAccounts(data);

    dispatch(setBulkBalanceAccountEditScuccess(response.data));

  } catch (error) {
    dispatch(setBulkBalanceAccountEditFailure(error.response?.data?.message || error.message));
  }

}


// Bulk Edit a Customer's Balance Accounts Cleanup ====================================================================================================

/**
* @description Cleans up Bulk Edit a Customer's Balance Accounts
*/

export const cleanUpBulkEditBalanceAccounts = () => (dispatch) => {
  dispatch(bulkBalanceAccountEditCleanup())
};


// Calculate Balance Flow ====================================================================================================

/**
 * @param data {
        @number customer_id,
        @string month,
    }
* @returns Returns true or false if the balance flow was calculated
*/

export const calculateBalanceFlow = (data) => async (dispatch) => {
  dispatch(setCalculateBalanceFlowLoading());

  try {

    const response = await BalancesService.calculateBalanceFlow(data);

    dispatch(setCalculateBalanceFlowScuccess(response.data));

  } catch (error) {
    dispatch(setCalculateBalanceFlowFailure(error.response?.data?.message || error.message));
  }

}


// Calculate Balance Flow Cleanup ====================================================================================================

/**
* @description Cleans up Calculate Balance Flow store
*/

export const cleanUpCalculateBalanceFlow = () => (dispatch) => {
  dispatch(calculateBalanceFlowCleanup())
};


  // Export Client's Balance ====================================================================================================

  /**
   * @param data {
      * @string customer_id the id of the customer
    * }
    * @description Gets an excel file with the client balance
  */

  export const exportClientBalance = (data) => async (dispatch) => {
    dispatch(setExportClientBalanceLoading());

    try {

      const response = await BalancesService.exportClientBalance(data);

      dispatch(setExportClientBalanceScuccess(response.data));

    } catch(error) {
      if (error.response?.status === 400) {
        dispatch(setExportClientBalanceFailure('No hay reportes con estos registros'));
      } else {
        dispatch(setExportClientBalanceFailure(error.response?.data?.message || error.message));
      }
    }

  }

  
  // Export Client's Balance Cleanup ====================================================================================================

  /**
  * @description Cleans up Export Client's Balance store
  */

  export const cleanExportClientBalance = () => (dispatch) => {
    dispatch(exportClientBalanceCleanup())
  };


// Update Initial Balance Template ====================================================================================================

/**
 * @param data {
        @file template,
        @string changes
    }
* @returns True or false if the initial balance template was updated
*/

export const updateInitialBalanceTemplate = (data) => async (dispatch) => {
  dispatch(setUpdateInitialBalanceTemplateLoading());

  try {

    await BalancesService.updateInitialBalanceTemplate(data);

    dispatch(setUpdateInitialBalanceTemplateSuccess(data));

  } catch(error) {
    dispatch(setUpdateInitialBalanceTemplateFailure(error.response?.data?.message || error.response?.data?.errors[0] || error.message));
  }

}

// Update Initial Balance Template Cleanup ====================================================================================================

/**
 * @description Cleans up Update Initial Balance Template
 */

  export const updateInitialBalanceTemplateCleanup = () => (dispatch) => {
  dispatch(cleanUpdateInitialBalanceTemplate())

};


// Get Initial Balance Template ====================================================================================================

/**
  * @returns The url for the Initial Balance Template
*/

export const getInitialBalanceTemplate = () => async (dispatch) => {
  dispatch(setGetInitialBalanceTemplateLoading());

  try {

    const response = await BalancesService.getInitialBalanceTemplate();

    dispatch(setGetInitialBalanceTemplateSuccess(response?.data));

  } catch(error) {
    dispatch(setGetInitialBalanceTemplateFailure(error.response?.data?.message || error.message));
  }

}