import React, { useState, useEffect } from 'react'
import Switch from "react-switch";

export default function SwitchInput(props) {
    const {
        formik,
        fieldName,
        label,
        roundedStyle
    } = props;

    const [value, setValue] = useState(formik.values[fieldName] === 1 ? true : false );

    const handleChange = () => {
        setValue(!value);
    }

    useEffect(() => {
        const val = value === true ? 1 : 0;
        formik.setFieldValue(fieldName, val);
    }, [value])
    

    return (
        <div className={roundedStyle ? "field" : "field field-alt"}>
            {label &&
            <label style={{flex: '1'}}>{label}</label>}

            <Switch
                onChange={handleChange}
                checked={value}
            />

            <span className='field-error'>{formik.touched[fieldName] && formik.errors[fieldName]}</span>

        </div>
    )
}
