
import api from "../config/api";
import BaseService from './base-service';

export default class BalancesService extends BaseService {

    // Get Customer's Balance Calculation ====================================================================================================

    /**
    * @param customerId the id of the customer
    * @returns Return the customer's balance calculation
    */

    static getBalanceCalculation(customerId) {
        const url = `/getcalculations/${customerId}`

        return api.get(url);
    }


    // Upload Customer's Balance Data ====================================================================================================

    /**
     * @param data {
            @number customer_id,
            @file   balances,
            @number years,
            @string import_type,
            @number months,
        }
    * @returns Return true or false if the balance data was uploaded
    */

    static uploadBalanceData(data) {
        const url = `/import`

        return api.post(url, data);
    }


    // Edit a Customer's Balance Account ====================================================================================================

    /**
     * @param data {
            @number customer_id,
            @string account,
            @string description,
            @string account_classification,
            @string active_passive_si,
            @string account_eeff,
            @string cost_expense,
            @string fixed_variable_ex_type,
            @string flow_heading,
            @object balance {
                @number id,
                @string title,
                @string balance,
                @string account
            }
        }
    * @param balanceAccountId the id of the balance account
    * @returns Edits a balance account info
    */

    static editBalanceAccount(data, balanceAccountId) {
        const url = `/checkbalances/${balanceAccountId}`

        return api.put(url, data);
    }

        
    // Bulk Edit Customer's Balance Accounts ====================================================================================================

    /**
     * @param data {
            @number customer_id,
            @array account ids,
            @string account_classification,
            @string active_passive_si,
            @string account_eeff,
            @string fixed_variable_ex_type,
            @string flow_heading,
            @string partida_gp,
            @string tax_risk,
        }
    * @returns Bulk Edit Customer's Balance Accounts
    */

    static bulkEditBalanceAccounts(data) {
        const url = `/checkBalance/multiple`

        return api.post(url, data);
    }


    // Calculate Balance Flow ====================================================================================================

    /**
     * @param data {
            @number customer_id,
            @string month,
        }
    * @returns Returns true or false if the balance flow was calculated
    */

    static calculateBalanceFlow(data) {
        const url = `/calculate-flow`

        return api.post(url, data);
    }


    // Export Client's Balance ====================================================================================================

    /**
     * @param data {
        * @string customer_id the id of the customer
     * }
     * @description Gets an excel file with the client balance
    */

    static exportClientBalance(data) {
        const url = `/export`;

        return api.get(url,{
            params: data,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        });
    }

        
    // Update Initial Balance Template ====================================================================================================

    /**
     * @param data {
            @file template,
            @string changes
        }
    * @returns True or false if the initial balance template was updated
    */

    static updateInitialBalanceTemplate(data) {
        const url = `/template/update`

        return api.post(url, data);
    }


    // Get Initial Balance Template ====================================================================================================

    /**
    * @returns The url for the initial balance template
    */

    static getInitialBalanceTemplate() {
        const url = `/template`

        return api.get(url);
    }

}