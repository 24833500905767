
import api from "../config/api";
import BaseService from './base-service';

export default class CustomersService extends BaseService {

    // Get Customers With Pagination ====================================================================================================

    /**
     * @param page optional page number
     * @description Gets the list of the customers with pagination
     */

    static getCustomers(page) {
        const url = page ? `/customers?page=${page}` : `/customers`;

        return api.get(url);
    }

     
    // Get All Customers ====================================================================================================

    /**
     * @description Gets the list of all the customers
     */

     static getAllCustomers() {
        const url = `/all/customers`;

        return api.get(url);
    }


    // Search All Customers ====================================================================================================

    /**
     * @param query the terms of the search query
     * @description Gets the list of all the customers that match the search query
     */

     static searchCustomers(query) {
        const url = `/customers?search=${query}`;

        return api.get(url);
    }

     
    // Create a Customer ====================================================================================================

    /**
     * @param data {
            @string name,
            @string rnc,
            @number status,
            @string logo,
            @string address,
            @string phone,
            @string email,
            @string contact_person
        }
    * @returns Creates a new customer
    */

     static createCustomer(data) {
        const url = `/customers`

        return api.post(url, data);
    }


    // Edit a Customer ====================================================================================================

    /**
     * @param data {
            @string name,
            @string rnc,
            @number status,
            @string logo,
            @string address,
            @string phone,
            @string email,
            @string contact_person
        }
    * @param customerId the id of the customer
    * @returns Edits a customer info
    */

    static editCustomer(data, customerId) {
        const url = `/customers/${customerId}`

        return api.post(url, data);
    }


    // Delete a Customer ====================================================================================================

    /**
    * @param customerId the id of the customer
    * @returns True or false if the customer was deleted
    */

    static deleteCustomer(customerId) {
        const url = `/customers/${customerId}`

        return api.delete(url);
    }


    // Get Customer Details ====================================================================================================

    /**
    * @param customerId the id of the customer
    * @returns The customer's details
    */

     static getCustomerDetails(customerId) {
        const url = `/customers/${customerId}/details`

        return api.get(url);
    }

}
