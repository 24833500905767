import React, { useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'


import { editBalanceAccount, cleanUpEditBalanceAccount } from '../../../store/balances/balances.actions';
import { getDropDownOptions } from '../../../store/options/options.actions';

// Components

import FormInput from '../../UI/FormInput/FormInput';
import MaskInput from '../../UI/FormInput/MaskInput';
import SelectInput from '../../UI/FormInput/SelectInput';
import Button from '../../UI/Button/Button';
import Loader from '../../UI/Loader/Loader';

// Data

import {
  EXPENSE_TYPE_FORMATTED 
} from '../../../config/constants';

export default function EditBalanceAccountForm({account, currentMonth, setShowEditBalanceAccountModal}) {
  const balanceAccountEdit = useSelector((state) => state.balances.balanceAccountEdit);
  const dropDownOptions = useSelector((state) => state.options.dropDownOptions);
  const dispatch = useDispatch();

  // Edit a client account

  const clientAccountEdit = async (values, actions) => {
    dispatch(editBalanceAccount(values, account.id));
  }

  useEffect(() => {
    dispatch(getDropDownOptions())
  }, [dispatch])

  useEffect(() => {
    if (balanceAccountEdit.isEdited) {
      setShowEditBalanceAccountModal(false);
      dispatch(cleanUpEditBalanceAccount());
    }
  }, [dispatch, setShowEditBalanceAccountModal, balanceAccountEdit])

  return (
    <>

      {(dropDownOptions?.isLoading || dropDownOptions?.error) &&
        <Loader
          message={'Cargando...'}
          error={dropDownOptions.error}
          action={() =>  dispatch(getDropDownOptions())}
          dark={true}
      />}

      {!dropDownOptions?.isLoading && dropDownOptions?.data &&    
      <Formik
        initialValues={{ 
          customer_id: account.customer_id,
          account: account.account,
          description: account.description,
          account_classification: account.account_classification,
          active_passive_si: account.active_passive_si ? account.active_passive_si : '',
          account_eeff: account.account_eeff ? account.account_eeff : '',
          // cost_expense: account.cost_expense,
          fixed_variable_ex_type: account.fixed_variable_ex_type ? account.fixed_variable_ex_type : '',
          flow_heading: account.flow_heading ? account.flow_heading : '',
          partida_gp: account.partida_gp ? account.partida_gp : '',
          tax_risk: account.tax_risk ? account.tax_risk : '',
          balance: {
            id: account.balances[currentMonth]?.id,
            title: account.balances[currentMonth]?.title,
            balance: account.balances[currentMonth]?.balance,
            account: account.balances[currentMonth]?.account
          }
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => clientAccountEdit(values, actions)}
      >

        {props => (
        <>
        
          {balanceAccountEdit.error && <p className="form-error-message">{balanceAccountEdit.error}</p>}

          {/* Form Inputs */}

          <>
            <FormInput
                formik={props}
                fieldName={'account'}
                label={'Cuenta'}
                disabled={true}
            />
            
            <FormInput
                formik={props}
                fieldName={'description'}
                label={'Descripción'}
                disabled={true}
            />

            <SelectInput
              formik={props}
              fieldName={'account_classification'}
              label={'Clasificación Cuenta'}
              placeholder={'Seleccione'}
              options={dropDownOptions?.data ? dropDownOptions?.data?.account_classifications.map(option => ({ value: option.name, label: option.name })) : []}
            />

            <SelectInput
              formik={props}
              fieldName={'active_passive_si'}
              label={'Partidas Estructura'}
              placeholder={'Seleccione'}
              options={dropDownOptions?.data ? dropDownOptions?.data?.structure_items.map(option => ({ value: option.name, label: option.name })) : []}
            />

            <SelectInput
              formik={props}
              fieldName={'flow_heading'}
              label={'Partida Flujo'}
              placeholder={'Seleccione'}
              options={dropDownOptions?.data ? dropDownOptions?.data?.flow_headings.map(option => ({ value: option.name, label: option.name })) : []}
            />

            <SelectInput
              formik={props}
              fieldName={'account_eeff'}
              label={'Cuenta EEFF'}
              placeholder={'Seleccione'}
              options={dropDownOptions?.data ? dropDownOptions?.data?.eeff_accounts.map(option => ({ value: option.name, label: option.name })) : []}
            />

            <SelectInput
              formik={props}
              fieldName={'partida_gp'}
              label={'Partida G&P'}
              placeholder={'Seleccione'}
              options={dropDownOptions?.data ? dropDownOptions?.data?.gp_items.map(option => ({ value: option.name, label: option.name })) : []}
            />

            {/* <SelectInput
              formik={props}
              fieldName={'tax_risk'}
              label={'Riesgo Fiscal'}
              placeholder={'Seleccione'}
              options={dropDownOptions?.data ? dropDownOptions?.data?.fiscal_risks.map(option => ({ value: option.name, label: option.name })) : []}
            /> */}

            <FormInput
                formik={props}
                fieldName={'tax_risk'}
                label={'Cuentas de Análisis'}
            />

            <SelectInput
              formik={props}
              fieldName={'fixed_variable_ex_type'}
              label={'Tipo Gasto'}
              placeholder={'Seleccione'}
              options={EXPENSE_TYPE_FORMATTED}
            />

            <MaskInput
                formik={props}
                fieldName={'balance.balance'}
                label={'Saldo'}
                disabled={true}
            />
          
            <div className='form-actions'>

              <Button
                action={() => setShowEditBalanceAccountModal(false)}
                label={'Cancelar'}
                disabled={balanceAccountEdit?.isLoading}
                secondaryStyle={true}
              />

              <Button
                action={props.handleSubmit}
                label={'Editar'}
                disabled={balanceAccountEdit?.isLoading}
                isLoading={balanceAccountEdit?.isLoading}
              />

            </div>

          </>

        </>)}

      </Formik>}
    </>
  )
}

// Form initial values and validaiton schemas

const validationSchema = yup.object().shape({
  account: yup
    .string(),
  description: yup
    .string(),
  account_classification: yup
    .string()
    .required('Este campo es requerido'),
  active_passive_si: yup
    .string()
    .required('Este campo es requerido'),
  flow_heading: yup.string().when('account_eeff', {
    is: (val) => val === 'Depreciación acumulada',
    then: yup.string().required('Este campo es requerido'),
    otherwise: yup.string(),
  }),
  account_eeff: yup
    .string()
    .required('Este campo es requerido'),
  // partida_gp: yup
  //   .string(),
  // tax_risk: yup
  //   .string(),
  fixed_variable_ex_type: yup.string().when('partida_gp', {
    is: (val) => val !== 'Ingreso de Ventas' && val !== 'Otros Ingresos' && val !== undefined && val !== '',
    then: yup.string().required('Este campo es requerido'),
    otherwise: yup.string(),
  }),
  balance: yup.object({
    balance: yup.string(),
  }),
});