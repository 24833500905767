import React, { useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'

import { cleanUpForgotPassword, resetPassword } from '../../../store/auth/auth.actions';

// Components

import FormInput from '../../UI/FormInput/FormInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function ResetPassForm({setForm}) {
  const resetPass = useSelector((state) => state.auth.resetPassword);
  const forgotPass = useSelector((state) => state.auth.forgotPassword);
  const dispatch = useDispatch();

  // Reset Password Request

  const resetPasswordRequest = async (values, actions) => {

    dispatch(resetPassword(values));

  }

  useEffect(() => {
    if (resetPass?.passwordSet) {
      setForm('login')
      dispatch(cleanUpForgotPassword());
    }
  }, [dispatch, setForm, resetPass])

  return (
    <>
        <h1>Reestablecer contraseña</h1>

        <p className='auth-description'>Por favor introduzca el token enviado al correo <strong>{forgotPass?.email}</strong> y su nueva contraseña.</p>

        <Formik
            initialValues={{
              email: forgotPass?.email,
              ...initialValues
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => resetPasswordRequest(values, actions)}
          >

            {props => (
            <>

              {resetPass?.error && <p className="form-error-message">{resetPass?.error}</p>}

              {/* Form Inputs */}

              <FormInput
                  formik={props}
                  fieldName={'token'}
                  placeholder={'Token'}
                  roundedStyle={true}
              />

              <FormInput
                  formik={props}
                  fieldName={'password'}
                  placeholder={'Contraseña'}
                  type={'password'}
                  roundedStyle={true}
              />

              <FormInput
                  formik={props}
                  fieldName={'password_confirmation'}
                  placeholder={'Repetir Contraseña'}
                  type={'password'}
                  roundedStyle={true}
              />

              <button className='btn-plain' onClick={() => setForm('login')}>¿Ya tiene su contraseña? Acceder</button>

              <button className="btn" onClick={props.handleSubmit}>
                {!resetPass?.isLoading ? (
                  'Continuar'
                ) : (
                  <FontAwesomeIcon icon={faSpinner} />
                )}
              </button>

            </>)}

        </Formik>
    </>
  )
}

// Form initial values and validaiton schemas

const initialValues = { token: '', password: '', password_confirmation: '' };

const validationSchema = yup.object().shape({
    token: yup
      .string()
      .required('Este campo es requerido'),
    password: yup
      .string()
      .matches(/^\S+$/, { message: 'Contraseña no puede tener espacios' })
      .min(8, 'Contraseña de minimo 8 caracteres')
      .required('Este campo es requerido'),
    password_confirmation: yup
      .string()
      .oneOf(
        [yup.ref('password')], 'Las contraseñas no coinciden',
      )
      .required('Este campo es requerido'),
});
