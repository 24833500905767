import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'

// Actions

import { createCustomer, cleanUpCreateCustomer } from '../../../store/customers/customers.actions';
import { getSingleCustomerWithUsers } from '../../../store/users/users.actions';
import { getDropDownOptions } from '../../../store/options/options.actions';

// Components

import FormInput from '../../UI/FormInput/FormInput';
import FormSteps from '../../UI/FormSteps/FormSteps';
import FileInput from '../../UI/FormInput/FileInput';
import SelectInput from '../../UI/FormInput/SelectInput';
import MaskInput from '../../UI/FormInput/MaskInput';
import Button from '../../UI/Button/Button';
import SwitchInput from '../../UI/FormInput/SwitchInput';
import Loader from '../../UI/Loader/Loader';

// Utilities

import { setupFormData } from '../../../utilities/helper-functions';

// Constants

import { BALANCE_OPTIONS, VALLGES_CLIENT_ID, FISCAL_MONTHS } from '../../../config/constants';

const steps = [
  'Datos generales',
  'Datos financieros'
]

export default function AddClientForm({setShowAddClientModal}) {
  const [formStep, setFormStep] = useState(0);
  const customerCreate = useSelector((state) => state.customers.createCustomer);
  const vallgesUsers = useSelector((state) => state.users.singleCustomerUsers);
  const dropDownOptions = useSelector((state) => state.options.dropDownOptions);
  const dispatch = useDispatch();

  // Create a customer

  const createNewCustomer = async (values, actions) => {

    if (formStep === 1) {
      const data = setupFormData(values);
      dispatch(createCustomer(data));
    } else {
      setFormStep(formStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }

  }

  useEffect(() => {
    dispatch(getSingleCustomerWithUsers(VALLGES_CLIENT_ID))
    dispatch(getDropDownOptions())
  }, [dispatch])

  useEffect(() => {
    if (customerCreate.isCreated) {
      setShowAddClientModal(false);
      dispatch(cleanUpCreateCustomer());
    }
  }, [dispatch, setShowAddClientModal, customerCreate])

  return (
    <>

      {(vallgesUsers?.isLoading || vallgesUsers?.error || dropDownOptions?.isLoading || dropDownOptions?.error) &&
        <Loader
          message={'Cargando...'}
          error={vallgesUsers.error || dropDownOptions.error}
          action={() =>  {
              dispatch(getSingleCustomerWithUsers(VALLGES_CLIENT_ID))
              dispatch(getDropDownOptions())
            }
          }
          dark={true}
      />}

      {!vallgesUsers?.isLoading && vallgesUsers?.data && !dropDownOptions?.isLoading && dropDownOptions?.data &&
      <>
      
        <FormSteps steps={steps} currentStep={formStep} />

        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema[formStep]}
            onSubmit={(values, actions) => createNewCustomer(values, actions)}
          >

            {props => (
            <>

              {customerCreate.error && <p className="form-error-message">{customerCreate.error}</p>}

              {/* Form Inputs */}

              {formStep === 0 &&
              <>
                <SwitchInput
                  formik={props}
                  fieldName={'status'}
                  label={'Activo'}
                />

                <FormInput
                    formik={props}
                    fieldName={'name'}
                    label={'Empresa'}
                />

                <MaskInput
                  formik={props}
                  fieldName={'rnc'}
                  label={'RNC'}
                  mask={'#########'}
                />

                <FileInput
                  formik={props}
                  fieldName={'logo'}
                  label={'Logo'}
                  fileType={'image'}
                />

                <SelectInput
                    formik={props}
                    fieldName={'segment'}
                    label={'Segmento'}
                    placeholder={'Seleccione'}
                    options={dropDownOptions?.data ? dropDownOptions?.data?.segments.map(option => ({ value: option.name, label: option.name })) : []}
                />

                <SelectInput
                    formik={props}
                    fieldName={'sector'}
                    label={'Sector'}
                    placeholder={'Seleccione'}
                    options={dropDownOptions?.data ? dropDownOptions?.data?.sectors.map(option => ({ value: option.name, label: option.name })) : []}
                />

                <FormInput
                    formik={props}
                    fieldName={'address'}
                    label={'Dirección'}
                />

                <MaskInput
                  formik={props}
                  fieldName={'phone'}
                  label={'Teléfono'}
                  mask={'(###) ### ####'}
                />

                <FormInput
                    formik={props}
                    fieldName={'email'}
                    label={'Email'}
                />

                <FormInput
                    formik={props}
                    fieldName={'contact_person'}
                    label={'Persona de contacto'}
                />
              
                <div className='form-actions'>

                  <Button
                    action={() => setShowAddClientModal(false)}
                    label={'Cancelar'}
                    secondaryStyle={true}
                  />

                  <Button
                    action={props.handleSubmit}
                    label={'Continuar'}
                  />

                </div>
              </>}

              {formStep === 1 &&
              <>
                <SelectInput
                    formik={props}
                    fieldName={'balance_type'}
                    label={'Tipo Balanza'}
                    placeholder={'Seleccione'}
                    options={BALANCE_OPTIONS}
                />

                <SelectInput
                    formik={props}
                    fieldName={'fiscal_start'}
                    label={'Inicio Fiscal'}
                    placeholder={'Seleccione'}
                    options={FISCAL_MONTHS}
                />

                <MaskInput
                    formik={props}
                    fieldName={'sales_level'}
                    label={'Nivel de ventas'}
                />

                <FormInput
                    formik={props}
                    fieldName={'employees_number'}
                    label={'Cantidad de empleados'}
                    type={'number'}
                />

                <SelectInput
                    formik={props}
                    fieldName={'asigned_executive'}
                    label={'Ejecutivo asignado'}
                    placeholder={'Seleccione'}
                    options={vallgesUsers?.data ? vallgesUsers?.data?.users.map(user => ({ value: user.id, label: user.name })) : []}
                />

                <SelectInput
                    formik={props}
                    fieldName={'associate_name'}
                    label={'Nombre asociado'}
                    placeholder={'Seleccione'}
                    options={dropDownOptions?.data ? dropDownOptions?.data?.associates.map(option => ({ value: option.id, label: option.name })) : []}
                />

                <MaskInput
                  formik={props}
                  fieldName={'associates_income'}
                  label={'Ingreso por asociados'}
                />

                <SelectInput
                    formik={props}
                    fieldName={'client_referral'}
                    label={'Cliente referido por'}
                    placeholder={'Seleccione'}
                    options={dropDownOptions?.data ? dropDownOptions?.data?.referrals.map(option => ({ value: option.id, label: option.name })) : []}
                />

                <MaskInput
                    formik={props}
                    fieldName={'referral_comission'}
                    label={'Comisión referido'}
                />
              
                <div className='form-actions'>

                  <Button
                    action={() => setFormStep(formStep - 1)}
                    label={'Atrás'}
                    disabled={customerCreate?.isLoading}
                    secondaryStyle={true}
                  />

                  <Button
                    action={props.handleSubmit}
                    label={'Añadir'}
                    disabled={customerCreate?.isLoading}
                    isLoading={customerCreate?.isLoading}
                  />

                </div>
              </>}

            </>)}

        </Formik>

      </>}
    </>
  )
}

// Form initial values and validaiton schemas

const initialValues = { 
  name: '', 
  rnc: '', 
  status: 0, 
  logo: '', 
  segment: '',
  sector: '',
  address: '', 
  phone: '', 
  email: '', 
  contact_person: '',
  balance_type: '',
  fiscal_start: '',
  sales_level: '', 
  employees_number: '', 
  asigned_executive: '',
  associate_name: '',
  associates_income: '',
  client_referral: '',
  referral_comission: ''
};

const validationSchema = [
  yup.object().shape({
    name: yup
      .string()
      .required('Este campo es requerido'),
    rnc: yup
      .string()
      .min(9)
      .required('Este campo es requerido'),
    logo: yup
      .string(),
    segment: yup
      .string()
      .required('Este campo es requerido'),
    sector: yup
      .string()
      .required('Este campo es requerido'),
    address: yup
      .string()
      .required('Este campo es requerido'),
    phone: yup
      .string()
      .min(10)
      .required('Este campo es requerido'),
    email: yup
      .string()
      .email('Ingrese un email válido.')
      .required('Este campo es requerido'),
    contact_person: yup
      .string()
      .required('Este campo es requerido'),
    status: yup
      .number(),
  }),
  yup.object().shape({
    balance_type: yup
      .date()
      .required('Este campo es requerido'),
    fiscal_start: yup
      .string()
      .required('Este campo es requerido'),
    sales_level: yup
      .string()
      .required('Este campo es requerido'),
    employees_number: yup
      .string()
      .required('Este campo es requerido'),
    asigned_executive: yup
      .string()
      .required('Este campo es requerido'),
    associate_name: yup
      .string(),
    associates_income: yup
      .string(),
    client_referral: yup
      .string(),
    referral_comission: yup
      .string(),
  })
];