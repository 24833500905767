import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';


import { transferWalletClientsToUser, cleantransferWalletToUser } from '../../../store/users/users.actions';

// Components

import MultiSelectInput from '../../UI/FormInput/MultiSelectInput';
import SelectInput from '../../UI/FormInput/SelectInput';
import Button from '../../UI/Button/Button';

import './Styles.scss';

export default function TransferClientPortfolioForm({user, usersData, setShowEditClientPortfolioModal}) {
  const transferWallet = useSelector((state) => state.users.transferWallet);
  const dispatch = useDispatch();

  // Edit a user
  
  const transferWalletClients = async (values, actions) => {
    dispatch(transferWalletClientsToUser(values));
  }

  useEffect(() => {
    if (transferWallet.isTransfered) {
      setShowEditClientPortfolioModal(false);
      dispatch(cleantransferWalletToUser());
    }
  }, [dispatch, setShowEditClientPortfolioModal, transferWallet])

  useEffect(() => {
    dispatch(cleantransferWalletToUser());
  }, [dispatch])

  return (
    <Formik
      initialValues={{ 
        user_from: user.id,
        user_to: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => transferWalletClients(values, actions)}
    >

      {props => (
      <>

        {transferWallet.error && <p className="form-error-message">{transferWallet.error}</p>}

        <p>Cartera de <strong>{user.name} {user.lastname}</strong></p>
        
        {user.wallet.length > 0 && 
        <div className='inwallet-clients-entries'>
          {user.wallet.map((entry, i) => (
            <div className='inwallet-clients-entry' key={i}>
              {entry.customer?.name}
            </div>
          ))}
        </div>}

        {user.wallet.length === 0 &&
        <p className='no-clients-inwallet-message'>
          <FontAwesomeIcon className='no-clients-inwallet-message-icon' icon={faCircleExclamation} />
          Este ejecutivo no tiene clientes en su cartera para transferir.
        </p>}

        {/* Form Inputs */}

        <>
          {usersData && user.wallet.length > 0 &&
          <SelectInput
            formik={props}
            fieldName={'user_to'}
            label={'Ejecutivo destino'}
            placeholder={'Seleccione'}
            options={usersData.map(executive => ({ value: executive.id, label: executive.name }))}
          />}
        
          <div className='form-actions'>

            <Button
              action={() => setShowEditClientPortfolioModal(false)}
              label={'Cancelar'}
              disabled={transferWallet?.isLoading}
              secondaryStyle={true}
            />

            <Button
              action={props.handleSubmit}
              label={'Transferir'}
              disabled={transferWallet?.isLoading || user.wallet.length === 0}
              isLoading={transferWallet?.isLoading}
            />

          </div>
        </>

      </>)}

    </Formik>
  )
}

// Form initial values and validaiton schemas

const validationSchema = yup.object().shape({
  user_to: yup
    .string()
    .required('Este campo es requerido'),
});