// API
import OptionsService from '../../services/options.service';

import { 
  setgetDropDownOptionsLoading, setgetDropDownOptionsScuccess, setgetDropDownOptionsFailure,
  setgetDropDownOptionsGroupLoading, setgetDropDownOptionsGroupScuccess, setgetDropDownOptionsGroupFailure,
  setcreateOptionForDropDownGroupLoading, setcreateOptionForDropDownGroupScuccess, setcreateOptionForDropDownGroupFailure, createOptionCleanup,
  setupdateDropDownGroupOptionLoading, setupdateDropDownGroupOptionScuccess, setupdateDropDownGroupOptionFailure, updateOptionCleanup,
  setdeleteDropDownGroupOptionLoading, setdeleteDropDownGroupOptionScuccess, setdeleteDropDownGroupOptionFailure, deleteOptionCleanup
 } from './options.reducers';


  // Get the list of all the dropdown options ====================================================================================================

  /**
   * @description Gets the list of all the dropdown options
   */

  export const getDropDownOptions = () => async (dispatch) => {
    dispatch(setgetDropDownOptionsLoading());

    try {

      const response = await OptionsService.getDropDownOptions();

      dispatch(setgetDropDownOptionsScuccess(response.data));

    } catch(error) {
      dispatch(setgetDropDownOptionsFailure(error.response?.data?.message || error.message));
    }

  }

  
  // Get the list of options in a group ====================================================================================================

  /**
   * @param group the slug of the option group
   * @description Get the list of options in a group
   */

   export const getDropDownOptionsGroup = (group) => async (dispatch) => {
    dispatch(setgetDropDownOptionsGroupLoading());

    try {

      const response = await OptionsService.getDropDownOptionsGroup(group);

      dispatch(setgetDropDownOptionsGroupScuccess(response.data?.data));

    } catch(error) {
      dispatch(setgetDropDownOptionsGroupFailure(error.response?.data?.message || error.message));
    }

  }


  // Create an option for a list group ====================================================================================================

  /**
   * @param group the slug of the option group
   * @param data {
        @string name,
      }
    * @description True or false if the option was created succesfully
  */

   export const createOptionForDropDownGroup = (group, data) => async (dispatch) => {
    dispatch(setcreateOptionForDropDownGroupLoading());

    try {

      const response = await OptionsService.createOptionForDropDownGroup(group, data);

      dispatch(setcreateOptionForDropDownGroupScuccess(response.data));

    } catch(error) {
      dispatch(setcreateOptionForDropDownGroupFailure(error.response?.data?.message || error.message));
    }

  }


  // Create an Option Cleanup ====================================================================================================

  /**
  * @description Cleans up Create an Option store
  */

  export const cleanUpCreateOption = () => (dispatch) => {
    dispatch(createOptionCleanup())
  };



  // Update an option for a list group ====================================================================================================

  /**
   * @param group the slug of the option group
   * @param optionId the id of the option to update
   * @param data {
        @string name,
      }
    * @description True or false if the option was updated succesfully
  */

  export const updateDropDownGroupOption = (group, optionId, data) => async (dispatch) => {
    dispatch(setupdateDropDownGroupOptionLoading());

    try {

      const response = await OptionsService.updateDropDownGroupOption(group, optionId, data);

      dispatch(setupdateDropDownGroupOptionScuccess(response.data));

    } catch(error) {
      dispatch(setupdateDropDownGroupOptionFailure(error.response?.data?.message || error.message));
    }

  }

  // Update an Option Cleanup ====================================================================================================

  /**
  * @description Cleans up Update an Option store
  */

   export const cleanUpUpdateOption = () => (dispatch) => {
    dispatch(updateOptionCleanup())
  };

  // Delate an option for a list group ====================================================================================================

  /**
   * @param group the slug of the option group
   * @param optionId the id of the option to delete
    * @description True or false if the option was deleted succesfully
  */

  export const deleteDropDownGroupOption = (group, optionId) => async (dispatch) => {
    dispatch(setdeleteDropDownGroupOptionLoading());

    try {

      const response = await OptionsService.deleteDropDownGroupOption(group, optionId);

      dispatch(setdeleteDropDownGroupOptionScuccess(response.data));

    } catch(error) {
      dispatch(setdeleteDropDownGroupOptionFailure(error.response?.data?.message || error.message));
    }

  }


  // Delete an Option Cleanup ====================================================================================================

  /**
  * @description Cleans up Delete an Option store
  */

   export const cleanUpDeleteOption = () => (dispatch) => {
    dispatch(deleteOptionCleanup())
  };