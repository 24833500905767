import React, { useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'


import { changePassword, cleanUpChangePassword } from '../../../store/auth/auth.actions';

// Components

import FormInput from '../../UI/FormInput/FormInput';
import Button from '../../UI/Button/Button';

export default function ChangePassword({setShowEditUserModal}) {
  const passChange = useSelector((state) => state.auth.changePassword);
  const dispatch = useDispatch();

  // Edit a user

  const handdlePasswordChange = async (values, actions) => {
    dispatch(changePassword(values));
  }

  useEffect(() => {
    if (passChange?.passwordChanged) {
      setShowEditUserModal(false);
      dispatch(cleanUpChangePassword());
    }
  }, [dispatch, setShowEditUserModal, passChange])

  useEffect(() => {
    dispatch(cleanUpChangePassword());
  }, [dispatch])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => handdlePasswordChange(values, actions)}
    >

      {props => (
      <>

        {passChange?.error && <p className="form-error-message">{passChange?.error}</p>}

        {/* Form Inputs */}

        <>
          <FormInput
              formik={props}
              fieldName={'current_password'}
              label={'Contraseña actual'}
          />
          
          <FormInput
              formik={props}
              fieldName={'password'}
              label={'Nueva contraseña'}
          />

          <FormInput
              formik={props}
              fieldName={'password_confirmation'}
              label={'Repetir contraseña'}
          />
        
          <div className='form-actions'>

            <Button
              action={() => setShowEditUserModal(false)}
              label={'Cancelar'}
              disabled={passChange?.isLoading}
              secondaryStyle={true}
            />

            <Button
              action={props.handleSubmit}
              label={'Confirmar'}
              disabled={passChange?.isLoading}
              isLoading={passChange?.isLoading}
            />

          </div>
        </>

      </>)}

    </Formik>
  )
}

// Form initial values and validaiton schemas

const initialValues = { 
  current_password: '', 
  password: '',
  password_confirmation: '', 
};

const validationSchema = yup.object().shape({
  current_password: yup
    .string()
    .required('Este campo es requerido'),
  password: yup
    .string()
    .min(6, 'Contraseña de mínimo 6 caracteres')
    .required('Este campo es requerido'),
  password_confirmation: yup
    .string()
    .oneOf(
        [yup.ref('password')], 'Las contraseñas no coinciden',
    )
    .required('Este campo es requerido'),
});