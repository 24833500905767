import React from 'react'

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss';

export default function UsersList({data}) {
  return (
    <div className='table-list'>

        <table>
            <thead>
                <tr className='table-list-headers'>
                    <th>Cliente</th>
                    <th>RNC</th>
                    <th>Email</th>
                    <th>Usuarios</th>
                    <th></th>
                </tr>
            </thead>

            <tbody>

                {data.map((entry, i) => (
                    <UserEntry
                        key={i}
                        entry={entry}
                    />
                ))}

            </tbody>
            
        </table>

    </div>
  )
}

const UserEntry = ({entry}) => {
    return (
        <tr className='table-entry'>
            <td className='user-name'>
                <p>
                    <strong>
                        <Link className='table-entry-link' to={`/users/${entry.id}`}>{entry.name}</Link>
                    </strong>
                </p>
            </td>
            <td className='user-rnc'><p>{entry.rnc}</p></td>
            <td className='user-email'><p>{entry.email}</p></td>
            <td className='user-count'><p>{entry.users_count}</p></td>
            <td className='user-action'>
                <p>
                    <Link className='table-entry-link' to={`/users/${entry.id}`}>
                        <FontAwesomeIcon className='table-entry-link-icon' icon={faMagnifyingGlass} />
                    </Link>
                </p>
            </td>
        </tr>
    )  
}