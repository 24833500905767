import React, { useState } from 'react';
import Search from '../../forms/Search/Search';

import './Styles.scss';

export default function CustomerGroupSelect(props) {
    const {
        formik,
        fieldName,
        label,
        data,
        searchAction,
    } = props;
    const [options, setOptions] = useState(formik.values[fieldName] ? formik.values[fieldName] : []);

    const setClientPortfolio = (item) => {
        const optionsArray = [...options];
        if (optionsArray.includes(item)) {
            let index = optionsArray.indexOf(item);
            optionsArray.splice(index, 1);
        } else {
            optionsArray.push(item);
        }

        setOptions(optionsArray);
        formik.setFieldValue(fieldName, optionsArray);
    }

    return (
        <div className={"field-multi-select"}>
            {label &&
            <label>{label}</label>}

            <Search onChange={searchAction} />

            <div className='field-multi-select-wrapper'>

                {formik.values[fieldName] && formik.values[fieldName].length > 0 &&
                <div className='field-multi-select-active-group'>

                    
                    <p className='field-multi-select-heading'>Clientes en grupo</p>                    

                    {data.map((option, i) => {
                        if(formik.values[fieldName]?.includes(option.value)) {
                            return (
                                <button
                                    key={i}
                                    className={formik.values[fieldName]?.includes(option.value) ? 'field-multi-select-option field-multi-select-option-active' : 'field-multi-select-option'}
                                    onClick={() => setClientPortfolio(option.value)}
                                >
                                    {option.label}
                                </button>
                            )
                        }
                    })}

                </div>}

                {data.map((option, i) => {
                    if(!formik.values[fieldName]?.includes(option.value)) {
                        return (
                            <button
                                key={i}
                                className={formik.values[fieldName]?.includes(option.value) ? 'field-multi-select-option field-multi-select-option-active' : 'field-multi-select-option'}
                                disabled={option.inGroup}
                                title={option.inGroup ? 'Este cliente ya está asignado a un grupo' : ''}
                                onClick={() => setClientPortfolio(option.value)}
                            >
                                {option.label}
                            </button>
                        )
                    }
                })}

            </div>

            <span className='field-error'>{formik.touched[fieldName] && formik.errors[fieldName]}</span>

        </div>
    )
}
