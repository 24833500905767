import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss';

export default function UploadRecordsButton({action}) {
  return (
    <div className='upload-records-button'>
        <h2>Subir archivo de records</h2>
        <button onClick={() => action()}>
            <FontAwesomeIcon className='upload-records-icon' icon={faCloudArrowUp} />
            <p>Seleccionar archivo</p>
        </button>
        <p className='upload-records-formats'>Tamaño máximo 2MB • Formato: Excel (xlsx)</p>
    </div>
  )
}
