import React from 'react'

import { COPYRIGHT_URL } from '../../../config/constants';

import './Styles.scss';

export default function Copyright() {
  return (
    <p className='copyright'>Copyright ® Vallges, <a href={COPYRIGHT_URL}>Términos y Condiciones</a></p>
  )
}
