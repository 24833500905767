
import api from "../config/api";
import BaseService from './base-service';

export default class BusinessService extends BaseService {

    // Get Business With Pagination ====================================================================================================

    /**
     * @param page optional page number
     * @description Gets the list of the business with pagination
     */

    static getBusiness(page) {
        const url = page ? `/bussiness-group?page=${page}` : `/bussiness-group`;

        return api.get(url);
    }

    
    // Get a Business Detail ====================================================================================================

    /**
     * @param id business id
     * @description Gets the detail data of a bussines
     */

    static getBusinessDetail(id) {
        const url = `/bussiness-group/${id}`;

        return api.get(url);
    }


    // Get Companies With Pagination ====================================================================================================

    /**
     * @param page optional page number
     * @description Gets the list of the companies with pagination
     */

    static getCompanies(page) {
        const url = page ? `/bussiness/companies?page=${page}` : `/bussiness/companies`;

        return api.get(url);
    }

    
    // Create a Business ====================================================================================================

    /**
     * @param data.name business name
     * @param data.user_id user id
     * @param data.customers array of customer ids
     * @description True or false if the business was created
     */

    static createBusiness(data) {
        const url = `/bussiness-group`;

        return api.post(url, data);
    }

    
    // Update a Business ====================================================================================================

    /**
     * @param id business id
     * @param data.name business name
     * @param data.user_id user id
     * @param data.customers array of customer ids
     * @description True or false if the business was updated
     */

    static updateBusiness(id, data) {
        const url = `/bussiness-group/${id}`;

        return api.put(url, data);
    }

    
    // Delete a Business ====================================================================================================

    /**
     * @param id business id
     * @description True or false if the business was deleted
     */

    static deleteBusiness(id) {
        const url = `/bussiness-group/${id}`;

        return api.delete(url);
    }

     
    // Get Business Users ====================================================================================================

    /**
     * @description Gets the list of business users
     */

    static getBusinessUsers() {
        const url = `/bussiness/users`;

        return api.get(url);
    }

    
    // Update Client user Wallet ====================================================================================================

    /**
     * @param data.user_id user id
     * @param data.customers array of customer ids
     * @description True or false if the client user wallet was updated
     */

    static updateClientUserWallet(data) {
        const url = `/business/update-my-wallet`;

        return api.post(url, data);
    }
}
