import React, { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

// Utilities

import { formatCurrency } from '../../../utilities/helper-functions';

export default function ClientAccountsListSimple({data, reconciliation, currentMonth, setCurrentMonth, openEditClienAccounttModal, longestBalancesArray, setLongestBalancesArray}) {

    const getlongestBalancesArray = () => {
        const length = Math.max.apply(Math,data.map(function(o){return o.balances.length;}));
        const obj = data.find(function(x){ return x.balances.length === length;});

        setLongestBalancesArray(obj);
    }

    useEffect(() => {
        getlongestBalancesArray()
    }, [getlongestBalancesArray])
    
    
  return (
    <div className='container'>

        <div className='client-accounts-list'>

            <table>
                <thead>
                    <tr className='client-accounts-list-headers'>
                        <th>Cuenta</th>
                        <th>Descripción</th>
                        <th className='list-header-balance'>
                            {longestBalancesArray?.balances &&
                            <>
                            <button 
                                className='btn-account-balance' 
                                disabled={currentMonth === 0} 
                                onClick={() => setCurrentMonth(currentMonth - 1)}
                            >
                                <FontAwesomeIcon className='btn-account-balance-icon' icon={faCaretLeft} />
                            </button>

                            <p>{longestBalancesArray?.balances[currentMonth]?.title}</p>

                            <button
                                className='btn-account-balance'
                                disabled={currentMonth === (longestBalancesArray?.balances.length - 1)}
                                onClick={() => setCurrentMonth(currentMonth + 1)}
                            >
                                <FontAwesomeIcon className='btn-account-balance-icon' icon={faCaretRight} />
                            </button>
                            </>}
                        </th>
                    </tr>
                </thead>

                <tbody>

                    {data && data.map((entry, i) => (
                        <ClientAccountEntry
                            key={i}
                            entry={entry}
                            currentMonth={currentMonth}
                            action={openEditClienAccounttModal}
                        />
                    ))}

                </tbody>

                <tfoot>
                    <tr className={reconciliation[currentMonth]?.sum === 0 ? 'client-accounts-reconciliation' : 'client-accounts-reconciliation reconcicliation-error'}>
                        <td className='reconciliation-name'>Reconclliación</td>
                        <td></td>

                        {reconciliation[currentMonth]?.sum !== null ? (
                            <td className='reconciliation-balance'>${reconciliation[currentMonth]?.sum}</td>
                        ) : (
                            <td className='reconciliation-balance'>Sin monto</td>
                        )}
                    </tr>
                </tfoot>
                
            </table>

        </div>

    </div>
  )
}

const ClientAccountEntry = ({entry, currentMonth, action}) => (
    <tr className='client-account-entry'>
        <td className='account-name'>
            <p><span>{entry.account}</span></p>
        </td>
        <td className='account-name'>
            <p><strong>{entry.description}</strong></p>
        </td>

        {/* Account Balance */}

        <td className='account-balance'>
            <p className={entry.balances[currentMonth]?.balance < 0 ? 'negative-balance' : ''}>
                <strong>
                    {entry.balances[currentMonth]?.balance ? formatCurrency(entry.balances[currentMonth]?.balance) : formatCurrency(0)}
                </strong>
            </p>
        </td>
    </tr>
)