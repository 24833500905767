
import api from "../config/api";
import BaseService from './base-service';

export default class OptionsService extends BaseService {

    // Get the list of all the dropdown options ====================================================================================================

    /**
     * @description Gets the list of all the dropdown options
     */

     static getDropDownOptions() {
        const url = `/options`;

        return api.get(url);
    }

    
    // Get the list of options in a group ====================================================================================================

    /**
     * @param group the slug of the option group
     * @description Get the list of options in a group
     */

     static getDropDownOptionsGroup(group) {
        const url = `/options/${group}`;

        return api.get(url);
    }


    // Create an option for a list group ====================================================================================================

    /**
     * @param group the slug of the option group
     * @param data {
            @string name,
        }
     * @description True or false if the option was created succesfully
     */

     static createOptionForDropDownGroup(group, data) {
        const url = `/options/${group}`;

        return api.post(url, data);
    }


    // Update an option for a list group ====================================================================================================

    /**
     * @param group the slug of the option group
     * @param optionId the id of the option to update
     * @param data {
            @string name,
        }
     * @description True or false if the option was updated succesfully
     */

    static updateDropDownGroupOption(group, optionId, data) {
        const url = `/options/${group}/${optionId}`;

        return api.put(url, data);
    }


    // Delete an option for a list group ====================================================================================================

    /**
     * @param group the slug of the option group
     * @param optionId the id of the option to delete
     * @description True or false if the option was deleted succesfully
     */

    static deleteDropDownGroupOption(group, optionId) {
        const url = `/options/${group}/${optionId}`;

        return api.delete(url);
    }

}
