import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss'

export default function Button({label, action, disabled, isLoading, secondaryStyle}) {
  return (
    <button className={secondaryStyle ? "btn btn-secondary" : "btn"}
        onClick={() => action()}
        disabled={disabled}
    >
        {!isLoading ? (
            label
        ) : (
            <FontAwesomeIcon icon={faSpinner} spin />
        )}
    </button>
  )
}
