import React from 'react'

import { Link } from 'react-router-dom';

import Moment from 'moment';
import 'moment/locale/es';

import './Styles.scss';

export default function ClientCardSimple({data}) {
  return (
    <div className='client-card-simple'>
        <Link className='client-card-link' to={`/client-detail/${data?.id}`}>
            <div className='client-card-details'>
                <div className={data?.logo_url ? 'client-card-logo client-card-logo-clear' : 'client-card-logo'}>
                    {data?.logo_url &&
                    <img src={data?.logo_url} alt={data?.name} />}
                </div>
                <h2>{data?.name}</h2>
            </div>
            <div className='client-card-balance'>
                {data?.last_end_uploaded_month ? (
                    <div className='client-card-balance-entry'>
                        <p><strong>Ultima actualización</strong></p>
                        <p>{Moment(data?.last_end_uploaded_month).format('MMM yyyy')}</p>
                    </div>
                ) : (
                    <div className='client-card-balance-entry'>
                        <p><strong>Ultima actualización</strong></p>
                        <p>N/D</p>
                    </div>
                )}
            </div>
        </Link>
    </div>
  )
}
