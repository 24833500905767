
import api from "../config/api";
import BaseService from './base-service';

export default class TermsService extends BaseService {

    // Update Terms and Conditions ====================================================================================================

    /**
     * @param data {
            @file terms,
            @string changes
        }
    * @returns True or false if the terms were updated
    */

    static updateTermsAndConditions(data) {
        const url = `/terms/update`

        return api.post(url, data);
    }


    // Get Terms and Conditions ====================================================================================================

    /**
    * @returns The url for the terms and conditions
    */

    static getTermsAndConditions() {
        const url = `/terms`

        return api.get(url);
    }

        
    // Accept Terms and Conditions ====================================================================================================

    /**
    * @returns True or false if the terms were accepted
    */

    static acceptTermsAndConditions() {
        const url = `/terms/accept`

        return api.post(url);
    }

}
