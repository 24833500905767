import React, { useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'


import { updateTermsAndConditions, updateTermsCleanup } from '../../../store/terms/terms.actions';

// Components

import FormInput from '../../UI/FormInput/FormInput';
import FileInput from '../../UI/FormInput/FileInput';
import Button from '../../UI/Button/Button';

// Data


export default function UploadTermsForm({setShowUploadTermsModal}) {
  const updateTerms = useSelector((state) => state.terms.updateTerms);
  const dispatch = useDispatch();

  // Upload terms

  const handleUploadTerms = async (values, actions) => {
    const data = new FormData();

    data.append("terms", values?.terms);
    data.append("changes",  values?.changes);

    dispatch(updateTermsAndConditions(data));
  }

  useEffect(() => {
    if (updateTerms.isUpdated) {
      setShowUploadTermsModal(false);
      dispatch(updateTermsCleanup());
    }
  }, [dispatch, setShowUploadTermsModal, updateTerms])

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => handleUploadTerms(values, actions)}
      >

        {props => (
        <>

          {updateTerms.error && <p className="form-error-message">{updateTerms.error}</p>}

          {/* Form Inputs */}

          <>

            <FileInput
              formik={props}
              fieldName={'terms'}
              label={'Términos'}
              fileType={'pdf'}
            />

            <FormInput
              formik={props}
              fieldName={'changes'}
              label={'Cambios'}
            />
          
            <div className='form-actions'>

              <Button
                action={() => setShowUploadTermsModal(false)}
                label={'Cancelar'}
                disabled={updateTerms?.isLoading}
                secondaryStyle={true}
              />

              <Button
                action={props.handleSubmit}
                label={'Subir'}
                disabled={updateTerms?.isLoading}
                isLoading={updateTerms?.isLoading}
              />

            </div>
          </>

        </>)}

      </Formik>
    </>
  )
}

// Form initial values and validaiton schemas

const initialValues = { 
  terms: '', 
  changes: '',
};

const validationSchema = yup.object().shape({
  terms: yup
    .string()
    .required('Este campo es requerido'),
  changes: yup
    .string()
    .required('Este campo es requerido'),
});