import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { cleanUpUploadBalanceData } from '../../store/balances/balances.actions';

// Components

import Modal from '../../components/UI/Modal/Modal';
import SectionHeader from '../../components/UI/SectionHeader/SectionHeader';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import ClientCardSimple from '../../components/cards/ClientCardSimple/ClientCardSimple';
import UploadRecordsButton from '../../components/UI/UploadRecordsButton/UploadRecordsButton';
import DashboardButton from '../../components/UI/DashboardButton/DashboardButton';
import UploadBalanceForm from '../../components/forms/UploadBalanceForm/UploadBalanceForm';
import Alert from '../../components/UI/Alert/Alert';
import ChangePassword from '../../components/forms/ChangePassword/ChangePassword';

// Balance Template Files

const balanceTemplate = [
    require('../../assets/d/template-carga-recurrente-con-y-sin-naturaleza.xlsx'),
    require('../../assets/d/template-carga-recurrente-con-y-sin-naturaleza.xlsx'),
    require('../../assets/d/template-carga-recurrente-sin-naturaleza-sin-saldo.xlsx')
]

const balanceInitialTemplate = require('../../assets/d/template-carga-inicial.xlsx');

export default function ClientDashboard() {
    const [showUploadBalanceModal, setShowUploadBalanceModal] = useState(false);
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const user = useSelector((state) => state.auth.user);
    const userPermissions = user?.data?.permissionsData;
    const balanceUpload = useSelector((state) => state.balances.uploadBalanceData);
    const dispatch = useDispatch();

    
    // Show alert message when the balance data is uploaded

    useEffect(() => {
        if (balanceUpload.isUploaded) {
            showAlertMessage('Data de balanza subida exitosamente.')
            dispatch(cleanUpUploadBalanceData())
        }
    }, [dispatch, balanceUpload])

    // Show alert message

    const showAlertMessage = (message) => {
        setAlertMessage(message)
        setTimeout(() => {
            setAlertMessage(null)
        }, 4000);
    }
    
    return (
        <div className='dashboard-wrapper'>

            <PageWrapper>

                <SectionHeader title={'Dashboard'} />

                <div className="container">
                    {user?.data?.customer &&
                    <ClientCardSimple data={user?.data?.customer} />}

                    {alertMessage &&
                    <Alert message={alertMessage} />}

                    {userPermissions.SubirArchivos &&                    
                    <div className='row'>
                        <div className='col'>
                            <UploadRecordsButton action={() => setShowUploadBalanceModal(true)} />
                        </div>
                        <div className='col'>
                            <DashboardButton
                                title='Cambiar contraseña'
                                description='Cambio de conraseña de su usuario'
                                icon='profile'
                                action={() => setShowEditUserModal(true)}
                            />
                            <DashboardButton
                                title='Descargar template de carga'
                                description='Formato de ejemplo para subir balanza'
                                icon='download'
                                isFile={true}
                                fileUrl={balanceTemplate[user?.data?.customer?.balance_type - 1]}
                            />
                        </div>
                    </div>}
                </div>

            </PageWrapper>


            {/* Upload Balance Modal */}

            <Modal show={showUploadBalanceModal} title={'Subir Balance'}>
                <UploadBalanceForm
                    clientId={user?.data?.customer?.id}
                    balanceTemplateUrl={balanceTemplate[user?.data?.customer?.balance_type - 1]}
                    balanceInitialTemplateUrl={balanceInitialTemplate}
                    setShowUploadBalanceModal={setShowUploadBalanceModal}
                />
            </Modal>

            {/* Edit User Modal */}

            <Modal show={showEditUserModal} title={'Cambiar Contraseña'}>
                <ChangePassword
                    setShowEditUserModal={setShowEditUserModal}
                />
            </Modal>

        </div>
  )
}
