import { 
  toggleClientListStyle,
  showAllAccountsListColumns,
 } from './settings.reducers';


// Toggle Client List Style ====================================================================================================

/**
* @param listStyle true or false to show the list style of the client's list
* @description Toggles the display style of the client's list
*/

export const handleToggleClientListStyle = (listStyle) => (dispatch) => {
  dispatch(toggleClientListStyle(listStyle))
};


// Show All Accounts List Columns ====================================================================================================

/**
* @param show true or false to show all the accounts list columns
* @description Toggles showing all the accounts list columns
*/

export const handleShowAllAccountsListColumns = (show) => (dispatch) => {
  dispatch(showAllAccountsListColumns(show))
};