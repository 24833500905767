import { createSlice } from '@reduxjs/toolkit'

// Setup the initial state

const initialState = {
  dropDownOptions: {},
  dropDownOptionsGroup: {},
  createOption: {},
  updateOption: {},
  deleteOption: {},
};

export const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {

    // Get the list of all the dropdown options

    setgetDropDownOptionsLoading: (state) => {
      state.dropDownOptions = {
        isLoading: true,
      };
    },
    setgetDropDownOptionsScuccess: (state, action) => {
      state.dropDownOptions = {
        data: action.payload
      };
    },
    setgetDropDownOptionsFailure: (state, action) => {
      state.dropDownOptions = {
        error: action.payload,
      }
    },

    // Get the list of all the dropdown options

    setgetDropDownOptionsGroupLoading: (state) => {
      state.dropDownOptionsGroup = {
        isLoading: true,
      };
    },
    setgetDropDownOptionsGroupScuccess: (state, action) => {
      state.dropDownOptionsGroup = {
        data: action.payload
      };
    },
    setgetDropDownOptionsGroupFailure: (state, action) => {
      state.dropDownOptionsGroup = {
        error: action.payload,
      }
    },

    // Create an option for a list group

    setcreateOptionForDropDownGroupLoading: (state) => {
      state.createOption = {
        isLoading: true,
      };
    },
    setcreateOptionForDropDownGroupScuccess: (state, action) => {
      state.createOption = {
        isCreated: true,
        data: action.payload
      };
    },
    setcreateOptionForDropDownGroupFailure: (state, action) => {
      state.createOption = {
        error: action.payload,
      }
    },
    createOptionCleanup: (state) => {
      state.createOption = {}
    },

    // Update an option for a list group

    setupdateDropDownGroupOptionLoading: (state) => {
      state.updateOption = {
        isLoading: true,
      };
    },
    setupdateDropDownGroupOptionScuccess: (state, action) => {
      state.updateOption = {
        isUpdated: true,
        data: action.payload
      };
    },
    setupdateDropDownGroupOptionFailure: (state, action) => {
      state.updateOption = {
        error: action.payload,
      }
    },
    updateOptionCleanup: (state) => {
      state.updateOption = {}
    },

    // Delete an option for a list group

    setdeleteDropDownGroupOptionLoading: (state) => {
      state.deleteOption = {
        isLoading: true,
      };
    },
    setdeleteDropDownGroupOptionScuccess: (state, action) => {
      state.deleteOption = {
        isDeleted: true,
        data: action.payload
      };
    },
    setdeleteDropDownGroupOptionFailure: (state, action) => {
      state.deleteOption = {
        error: action.payload,
      }
    },
    deleteOptionCleanup: (state) => {
      state.deleteOption = {}
    },

  },
})

export const { 
  setgetDropDownOptionsLoading, setgetDropDownOptionsScuccess, setgetDropDownOptionsFailure,
  setgetDropDownOptionsGroupLoading, setgetDropDownOptionsGroupScuccess, setgetDropDownOptionsGroupFailure,
  setcreateOptionForDropDownGroupLoading, setcreateOptionForDropDownGroupScuccess, setcreateOptionForDropDownGroupFailure, createOptionCleanup,
  setupdateDropDownGroupOptionLoading, setupdateDropDownGroupOptionScuccess, setupdateDropDownGroupOptionFailure, updateOptionCleanup,
  setdeleteDropDownGroupOptionLoading, setdeleteDropDownGroupOptionScuccess, setdeleteDropDownGroupOptionFailure, deleteOptionCleanup
} = optionsSlice.actions

export default optionsSlice.reducer;