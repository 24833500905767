import React, { useState, useEffect } from 'react'

import {
    useParams
} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'

import { getDropDownOptionsGroup, deleteDropDownGroupOption, cleanUpDeleteOption } from '../../store/options/options.actions';

// Components

import Loader from '../../components/UI/Loader/Loader';
import NoEntriesMessage from '../../components/UI/NoEntriesMessage/NoEntriesMessage';
import SectionHeader from '../../components/UI/SectionHeader/SectionHeader';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import OptionsList from '../../components/lists/OptionsList/OptionsList';
import Modal from '../../components/UI/Modal/Modal';
import AddOptionForm from '../../components/forms/AddOptionForm/AddOptionForm';
import AddUserButton from '../../components/UI/AddUserButton/AddUserButton';

// Constants

import {OPTION_GROUPS_NAMES} from '../../config/constants';
import EditOptionForm from '../../components/forms/EditOptionForm/EditOptionForm';

export default function OptionsGroup() {
    const { id } = useParams();
    const [showAddOptionModal, setShowAddOptionModal] = useState(false);
    const [showUpdateOptionModal, setShowUpdateOptionModal] = useState(false);
    const [optionToEdit, setOptionToEdit] = useState();
    const dropDownOptionsGroup = useSelector((state) => state.options.dropDownOptionsGroup);
    const optionCreate = useSelector((state) => state.options.createOption);
    const optionUpdate = useSelector((state) => state.options.updateOption);
    const optionDelete = useSelector((state) => state.options.deleteOption);
    const user = useSelector((state) => state.auth.user);
    const userPermissions = user?.data?.permissionsData;

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getDropDownOptionsGroup(id));
    }, [dispatch, id])

    useEffect(() => {
        if (optionCreate.isCreated || optionUpdate.isUpdated || optionDelete.isDeleted) {
            dispatch(getDropDownOptionsGroup(id));
        }
        if (optionDelete.isDeleted) {
            dispatch(cleanUpDeleteOption());
        }
    }, [dispatch, id, optionCreate, optionUpdate, optionDelete])

    
    // Open Edit Option Modal

    const openUpdateOptionModal = (option) => {
        setOptionToEdit(option);
        setShowUpdateOptionModal(true)
    }

    // Delete an option

    const deleteOption = async (optionId) => {
        if (window.confirm("Desea borrar esta opción?")) {
            dispatch(deleteDropDownGroupOption(id, optionId));
        }
    }

    return (
        <div className='dashboard-wrapper'>

            <PageWrapper>

                <SectionHeader title={`Editar opciones de ${OPTION_GROUPS_NAMES[id]}`}>

                    {userPermissions.AgregarClasificacionesEnLasConfiguracionDeLaListasDeClasificaciones &&
                    <AddUserButton label={'Añadir opción'} action={() => setShowAddOptionModal(true)} />}
                
                </SectionHeader>

                {(dropDownOptionsGroup?.isLoading || dropDownOptionsGroup.error) &&
                <Loader
                    message={'Cargando opciones...'}
                    error={dropDownOptionsGroup.error}
                    action={getDropDownOptionsGroup}
                />}

                {!dropDownOptionsGroup?.isLoading && dropDownOptionsGroup?.data && dropDownOptionsGroup?.data?.length > 0 &&
                    <OptionsList
                        group={id}
                        data={dropDownOptionsGroup?.data}
                        openUpdateOptionModal={openUpdateOptionModal}
                        deleteOption={deleteOption}
                    />
                }

                {!dropDownOptionsGroup?.isLoading && dropDownOptionsGroup?.data && dropDownOptionsGroup?.data?.length === 0 &&
                    <NoEntriesMessage message={'No se encontraron opciones para esta busqueda'} />
                }
                
            </PageWrapper>

            {/* Add Option Modal */}

            <Modal show={showAddOptionModal} title={'Añadir Opción'}>
                <AddOptionForm
                    group={id}
                    setShowAddOptionModal={setShowAddOptionModal}
                />
            </Modal>


            {/* Update Option Modal */}

            <Modal show={showUpdateOptionModal} title={'Editar Opción'}>
                <EditOptionForm
                    group={id}
                    option={optionToEdit}
                    setShowUpdateOptionModal={setShowUpdateOptionModal}
                />
            </Modal>

        </div>
  )
}
