// API
import AuthService from '../../services/auth.service';
import UsersService from '../../services/users.service';

import { 
  setLoginLoading, setLoginSuccess, setLoginFailure, signOut,
  setUserPermissions,
  setForgotPasswordLoading, setForgotPasswordSuccess, setForgotPasswordFailure, forgotPasswordCleanup,
  setResetPasswordLoading, setResetPasswordSuccess, setResetPasswordFailure, resetPasswordCleanup,
  setChangePasswordLoading, setChangePasswordSuccess, setChangePasswordFailure, changePasswordCleanup,
} from './auth.reducers';

// Utilities

import { createUserPermissionsData } from '../../utilities/helper-functions';

// Constants

import { VALLGES_USER_PERMISSIONS_DATA } from '../../config/userPermissions';

// Sign In the user ====================================================================================================

export const logIn = (data) => async (dispatch) => {
  dispatch(setLoginLoading());

  try {

    const response = await AuthService.logIn(data);

    dispatch(setLoginSuccess({
      token: response.data?.token,
      ...response.data?.user,
      permissionsData: createUserPermissionsData(response.data?.user?.permissions)
    }));

  } catch(error) {
    dispatch(setLoginFailure(error.response?.data?.message || error.message));
  }

}


// Get user's info ====================================================================================================

export const getUserInfo = () => async (dispatch, getState) => {
  const state = getState();
  const token = state.auth?.user?.data?.token;

  try {

    const response = await UsersService.getUserInfo();

    dispatch(setLoginSuccess({
      token: token,
      ...response.data,
      permissionsData: createUserPermissionsData(response.data?.permissions)
    }));

  } catch(error) {
    return null   
  }

}


// Set terms as accepted in the user data ====================================================================================================

export const setTermsAsAcceptedInUser = (data) => async (dispatch) => {
  const userData = { ...data };
  userData.accepted_terms_conditions = 1;

  dispatch(setLoginSuccess(userData));
}


// Clean Session Store ====================================================================================================

/**
 * @description Cleans up the Session store
 */

export const cleanSessionStore = () => (dispatch, getState) => {
  dispatch(signOut())

};


// Forgot Password ====================================================================================================

  /**
   * @param data {
          @string email
      }
  * @returns Sends an email to the user with the password reset token
  */

 export const forgotPassword = (data) => async (dispatch) => {
  dispatch(setForgotPasswordLoading());

  try {

    await AuthService.forgotPassword(data);

    dispatch(setForgotPasswordSuccess(data.email));

  } catch(error) {
    dispatch(setForgotPasswordFailure(error.response?.data?.message || error.response?.data?.errors[0] || error.message));
  }

}

// Forgot Password Cleanup ====================================================================================================

/**
 * @description Cleans up Forgot Password store
 */

 export const cleanUpForgotPassword = () => (dispatch) => {
  dispatch(forgotPasswordCleanup())

};


// Password Reset ====================================================================================================

/**
 * @param data {
        @string email
        @string token
        @string password
        @string password_confirmation
    }
* @returns True or false if the password was succesfully reset
*/

export const resetPassword = (data) => async (dispatch) => {
  dispatch(setResetPasswordLoading());

  try {

    await AuthService.resetPassword(data);

    dispatch(setResetPasswordSuccess());

  } catch(error) {
    dispatch(setResetPasswordFailure(error.response?.data?.message || error.response?.data?.errors[0] || error.message));
  }

}

// Reset Password Cleanup ====================================================================================================

/**
 * @description Cleans up Reset Password store
 */

 export const cleanUpResetPassword = () => (dispatch) => {
  dispatch(resetPasswordCleanup())

};


// Change Password ====================================================================================================

/**
 * @param data {
      @string current_password
      @string password
      @string password_confirmation
    }
* @returns True or false if the password was succesfully changed
*/

export const changePassword = (data) => async (dispatch) => {
  dispatch(setChangePasswordLoading());

  try {

    await AuthService.changePassword(data);

    dispatch(setChangePasswordSuccess());

  } catch(error) {
    dispatch(setChangePasswordFailure(error.response?.data?.message || error.response?.data?.errors[0] || error.message));
  }

}

// Reset Password Cleanup ====================================================================================================

/**
 * @description Cleans up Reset Password store
 */

 export const cleanUpChangePassword = () => (dispatch) => {
  dispatch(changePasswordCleanup())

};