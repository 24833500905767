import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { getBusiness } from '../../store/business/business.actions';

// Components

import BusinessGroupCard from '../../components/cards/BusinessGroupCard/BusinessGroupCard';
import Modal from '../../components/UI/Modal/Modal';
import AddCustomerGroupForm from '../../components/forms/AddCustomerGroupForm/AddCustomerGroupForm';
import EditCustomerGroupForm from '../../components/forms/EditCustomerGroupForm/EditCustomerGroupForm';
import Loader from '../../components/UI/Loader/Loader';
import Pagination from '../../components/UI/Pagination/Pagination';
import NoEntriesMessage from '../../components/UI/NoEntriesMessage/NoEntriesMessage';
import SectionHeader from '../../components/UI/SectionHeader/SectionHeader';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import Alert from '../../components/UI/Alert/Alert';
import ClientListToggleButtons from '../../components/UI/ClientListToggleButtons/ClientListToggleButtons';
import AddUserButton from '../../components/UI/AddUserButton/AddUserButton';

export default function BusinessGroups() {
    const [showAddCustomerGroupModal, setShowAddCustomerGroupModal] = useState(false);
    const [showEditClientModal, setShowEditClientModal] = useState(false);
    const [groupToEdit, setGroupToEdit] = useState();
    const [alertMessage, setAlertMessage] = useState(null);
    const clientListStyle = useSelector((state) => state.settings.clientListStyle);
    
    const user = useSelector((state) => state.auth.user);
    const userPermissions = user?.data?.permissionsData;
    const business = useSelector((state) => state.business.business);
    const createABusiness = useSelector((state) => state.business.createBusiness);
    const updateABusiness = useSelector((state) => state.business.updateBusiness);
    const deleteABusiness = useSelector((state) => state.business.deleteBusiness);

    const dispatch = useDispatch();

    // Get all the customers on load

    useEffect(() => {
      dispatch(getBusiness());
    }, [dispatch])


    // Show alert message when the customer is created or updated

    useEffect(() => {
        if (createABusiness.isCreated) {
            showAlertMessage('Grupo añadido exitosamente.')
        } else if (updateABusiness.isUpdated) {
            showAlertMessage('Grupo editado exitosamente.')
        } else if (deleteABusiness.isDeleted) {
            showAlertMessage('Grupo borrado exitosamente.')
        }
    }, [createABusiness, updateABusiness, deleteABusiness])


    // Open Edit Business Group Modal

    const openEditBusinessGroupModal = (group) => {
        setGroupToEdit(group);
        setShowEditClientModal(true)
    }


    // Show alert message

    const showAlertMessage = (message) => {
        setAlertMessage(message)
        setTimeout(() => {
            setAlertMessage(null);
            dispatch(getBusiness());
        }, 4000);
    }

    return (
        <div className='dashboard-wrapper'>

            <PageWrapper>
                <SectionHeader title={'Grupos de negocios'}>

                    {userPermissions.CrearClientes &&
                    <AddUserButton label={'Anadir grupo'} action={() => setShowAddCustomerGroupModal(true)} />}

                    {/* <Search
                        action={searchCustomers}
                    /> */}
                
                    <Pagination
                        action={getBusiness}
                        pagination={business?.data}
                    />

                    <ClientListToggleButtons />
                </SectionHeader>

                {alertMessage &&
                <Alert message={alertMessage} />}

                {(business?.isLoading || business.error) &&
                <Loader
                    message={'Cargando grupos...'}
                    error={business.error}
                    action={() => dispatch(getBusiness())}
                />}

                {!business?.isLoading && business?.data?.data && business?.data?.data?.length > 0 &&
                <div className="container">
                    <div className="row">

                        {business?.data?.data.map((entry, i) => (
                            <div key={i} className={clientListStyle ? "" : "col-md-4"}>
                                <BusinessGroupCard
                                    entry={entry}
                                    listStyle={clientListStyle}
                                    openEditBusinessGroupModal={openEditBusinessGroupModal}
                                />
                            </div>
                        ))}

                    </div>
                </div>}

                {!business?.isLoading && business?.data?.data && business?.data?.data?.length === 0 &&
                    <NoEntriesMessage message={'No se encontraron clientes para esta busqueda'} />
                }

            </PageWrapper>


            {/* Add Bussiness Group Modal */}

            <Modal show={showAddCustomerGroupModal} title={'Añadir Grupo Negocio'}>
                <AddCustomerGroupForm
                    setShowAddCustomerGroupModal={setShowAddCustomerGroupModal}
                />
            </Modal>


            {/* Edit Bussiness Group Modal */}

            <Modal show={showEditClientModal} title={'Editar Grupo Negocio'}>
                <EditCustomerGroupForm
                    groupToEdit={groupToEdit}
                    setShowEditClientModal={setShowEditClientModal}
                />
            </Modal>

        </div>
  )
}
