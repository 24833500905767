import React, { useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'


import { updateInitialBalanceTemplate, updateInitialBalanceTemplateCleanup } from '../../../store/balances/balances.actions';

// Components

import FormInput from '../../UI/FormInput/FormInput';
import FileInput from '../../UI/FormInput/FileInput';
import Button from '../../UI/Button/Button';

// Data


export default function UploadInitialBalanceTemplateForm({setShowUploadInitialBalanceTemplateModal}) {
  const updateBalanceTemplate = useSelector((state) => state.balances.updateInitialBalanceTemplate);
  const dispatch = useDispatch();

  // Upload Template

  const handleUploadTemplate = async (values, actions) => {
    const data = new FormData();

    data.append("template", values?.template);
    data.append("changes",  values?.changes);

    dispatch(updateInitialBalanceTemplate(data));
  }

  useEffect(() => {
    if (updateBalanceTemplate.isUpdated) {
      setShowUploadInitialBalanceTemplateModal(false);
      dispatch(updateInitialBalanceTemplateCleanup());
    }
  }, [dispatch, setShowUploadInitialBalanceTemplateModal, updateBalanceTemplate])

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => handleUploadTemplate(values, actions)}
      >

        {props => (
        <>

          {updateBalanceTemplate.error && <p className="form-error-message">{updateBalanceTemplate.error}</p>}

          {/* Form Inputs */}

          <>

            <FileInput
              formik={props}
              fieldName={'template'}
              label={'Template'}
              fileType={'excel'}
            />

            <FormInput
              formik={props}
              fieldName={'changes'}
              label={'Cambios'}
            />
          
            <div className='form-actions'>

              <Button
                action={() => setShowUploadInitialBalanceTemplateModal(false)}
                label={'Cancelar'}
                disabled={updateBalanceTemplate?.isLoading}
                secondaryStyle={true}
              />

              <Button
                action={props.handleSubmit}
                label={'Subir'}
                disabled={updateBalanceTemplate?.isLoading}
                isLoading={updateBalanceTemplate?.isLoading}
              />

            </div>
          </>

        </>)}

      </Formik>
    </>
  )
}

// Form initial values and validaiton schemas

const initialValues = { 
  template: '', 
  changes: '',
};

const validationSchema = yup.object().shape({
  template: yup
    .string()
    .required('Este campo es requerido'),
  changes: yup
    .string()
    .required('Este campo es requerido'),
});