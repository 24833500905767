import React from 'react'

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss';

const icons = {
    profile: faUser,
    download: faCloudArrowDown
}

export default function DashboardButton({title, description, icon, action, isFile, fileUrl}) {
  return (
    isFile ? (
        <Link to={fileUrl} className='dashboard-button' target="_blank" download>
            <div className='dashboard-button-info'>
                <h2>{title}</h2>
                <p>{description}</p>
            </div>
            {icon && icons[icon] && <FontAwesomeIcon className='dashboard-button-icon' icon={icons[icon]} />}
        </Link>
    ) : (
        <button className='dashboard-button' onClick={action}>
            <div className='dashboard-button-info'>
                <h2>{title}</h2>
                <p>{description}</p>
            </div>
            {icon && icons[icon] && <FontAwesomeIcon className='dashboard-button-icon' icon={icons[icon]} />}
        </button>
    )
  )
}
