import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'

import { getCompanies, updateClientUserWallet, cleanUpdateClientUserWallet } from '../../../store/business/business.actions';

// Components

import CustomerGroupSelect from '../../UI/FormInput/CustomerGroupSelect';
import Button from '../../UI/Button/Button';
import Loader from '../../UI/Loader/Loader';

export default function EditClientUserPortfolioForm({user, setShowEditClientUserPortfolioModal}) {
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const updateAClientUserWallet = useSelector((state) => state.business.updateAClientUserWallet);
  const companies = useSelector((state) => state.business.companies);
  const dispatch = useDispatch();
  
  // Edit a user
  
  const ClientUserWalletUpdate = async (values, actions) => {
    dispatch(updateClientUserWallet(values));
  }

  useEffect(() => {
    if (updateAClientUserWallet.isUpdated) {
      setShowEditClientUserPortfolioModal(false);
      dispatch(cleanUpdateClientUserWallet());
    }
  }, [dispatch, setShowEditClientUserPortfolioModal, updateAClientUserWallet])

  useEffect(() => {
    dispatch(getCompanies());
    dispatch(cleanUpdateClientUserWallet());
  }, [dispatch])

  useEffect(() => {
    if (companies?.data) {
      setFilteredCustomers(companies?.data);
    }
  }, [companies])

  // Search Clients

  const searchClients = (value) => {
    if (value !== '') {
        const data = filteredCustomers.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(value.toLowerCase())
        })
        setFilteredCustomers(data)
    } else {
      setFilteredCustomers(companies?.data)
    }
  }

  return (
    <>
      {(companies?.isLoading || companies?.error) &&
        <Loader
          message={'Cargando...'}
          error={companies.error}
          action={() => dispatch(getCompanies())}
          dark={true}
      />}

      {!companies?.isLoading && filteredCustomers &&
      <Formik
        initialValues={{ 
          user_id: user.id,
          customers: user.customer_wallet ? user.customer_wallet.map(customer => (Number(customer.customer_id))) : [],
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => ClientUserWalletUpdate(values, actions)}
      >

        {props => (
        <>

          {updateAClientUserWallet.error && <p className="form-error-message">{updateAClientUserWallet.error}</p>}

          {/* Form Inputs */}

          <>
            {filteredCustomers && filteredCustomers &&
            <CustomerGroupSelect
              formik={props}
              fieldName={'customers'}
              label={'Selecione clientes'}
              placeholder={'Seleccione'}
              data={filteredCustomers ? filteredCustomers.map(customer => ({ value: customer.id, label: customer.name })) : []}
              searchAction={searchClients}
            />}
          
            <div className='form-actions'>

              <Button
                action={() => setShowEditClientUserPortfolioModal(false)}
                label={'Cancelar'}
                disabled={updateAClientUserWallet?.isLoading}
                secondaryStyle={true}
              />

              <Button
                action={props.handleSubmit}
                label={'Editar'}
                disabled={updateAClientUserWallet?.isLoading}
                isLoading={updateAClientUserWallet?.isLoading}
              />

            </div>
          </>

        </>)}

      </Formik>}
    </>
  )
}

// Form initial values and validaiton schemas

const validationSchema = yup.object().shape({
  clients_portfolio: yup
    .array()
});