export const VALLGES_USER_PERMISSIONS = {
    modifyAccount: 1,
    viewAllClients: 2,
    modifyClientBalanceAccounts: 3,
    createAndModifyClientInfo: 4,
    createClientPortfolioAdminUser: 5,
    createVallgesUsers: 6,
    resetClientPortfolioUsers: 7,
    resetOwnVallgesUser: 8,
    viewReports: 9,
    viewLogs: 10,
    viewClientPortfolioByExecutive: 11,
    viewAllClientsMonthlyFinancialReports: 12,
    modifyAllClientsMonthlyFinancialReports: 13,
    viewPortfolioClientFinancialReport: 14,
    modifyPortfolioClientFinancialReports: 15,
    deactivateUsers: 16,
    deactivateClients: 17,
    viewPortfolioClients: 18,
    validateExecutiveClientsInfo: 19,
    validateExecutiveClientsCreationAndEdit: 20,
    validateMonthlyFinancialReportofExecutivePortfolioClients: 21,
    viewClientsPorfolioReports: 22,
    viewClientsDetails: 23,
    modifyClientPorfoiloBalanceAccounts: 24,
    AssignAndModifyVallesUserClientsPorfoilo: 25,
    viewAlertsReports: 26,
    viewNewAndModifiedReportOfExecutivesClients: 27,
    resetClientUsers: 28,
    clientCreateUser: 36,
    clientViewBalanceSheet: 37,
    clientViewAccountDetails: 38,
    clientViewFinancialReports: 39,
    clientUploadBalanceFile: 40,
    clientExportToExcel: 41
}

export const VALLGES_USER_PERMISSIONS_DATA = {
    modifyAccount: true,
    viewAllClients: true,
    modifyClientBalanceAccounts: true,
    createAndModifyClientInfo: true,
    createClientPortfolioAdminUser: true,
    createVallgesUsers: true,
    resetClientPortfolioUsers: true,
    resetOwnVallgesUser: true,
    viewReports: true,
    viewLogs: true,
    viewClientPortfolioByExecutive: true,
    viewAllClientsMonthlyFinancialReports: true,
    modifyAllClientsMonthlyFinancialReports: true,
    viewPortfolioClientFinancialReport: true,
    modifyPortfolioClientFinancialReports: true,
    deactivateUsers: true,
    deactivateClients: true,
    viewPortfolioClients: true,
    validateExecutiveClientsInfo: true,
    validateExecutiveClientsCreationAndEdit: true,
    validateMonthlyFinancialReportofExecutivePortfolioClients: true,
    viewClientsPorfolioReports: true,
    viewClientsDetails: true,
    modifyClientPorfoiloBalanceAccounts: true,
    AssignAndModifyVallesUserClientsPorfoilo: true,
    viewAlertsReports: true,
    viewNewAndModifiedReportOfExecutivesClients: true,
    resetClientUsers: true,
    clientCreateUser: true,
    clientViewBalanceSheet: true,
    clientViewAccountDetails: true,
    clientViewFinancialReports: true,
    clientUploadBalanceFile: true,
    clientExportToExcel: true
}