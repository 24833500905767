import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux'
import * as yup from 'yup';
import Moment from 'moment';
import 'moment/locale/es';

import { getReport, cleanReport } from '../../../store/reports/reports.actions';
import { getAllCustomers } from '../../../store/customers/customers.actions';

// Components

import Button from '../../UI/Button/Button';
import SelectInput from '../../UI/FormInput/SelectInput';
import DateInput from '../../UI/FormInput/DateInput';
import DownloadReportButton from '../../UI/DownloadReportButton/DownloadReportButton';
import Loader from '../../UI/Loader/Loader';

// Constants

import { REPORT_TYPES } from '../../../config/constants';

export default function ReportBalanceWithBalance({setShowBalanceWithBalanceReportModal}) {
  const [reportUrl, setReportUrl] = useState(null);
  const reports = useSelector((state) => state.reports.reports);
  const customers = useSelector((state) => state.customers.customersAll);
  const dispatch = useDispatch();

  // Generate Report

  const generateBalanceWithBalanceReport = async (values, actions) => {
    setReportUrl(null);

    const data = {
      start_date: values.start_date ? Moment(values.start_date).format('YYYY-MM-DD') : null,
      end_date: values.end_date ? Moment(values.end_date).format('YYYY-MM-DD') : null,
      customer_id: values.customer_id ? values.customer_id : null,
      export: true,
    }

    dispatch(getReport(data, REPORT_TYPES.balancewithbalance));
  }


  // Set up report download url

  useEffect(() => {
    if (reports.data) {
      const url = window.URL.createObjectURL(new Blob([reports.data]));
      setReportUrl(url);
    }
  }, [reports]);


  // Cleanup the storage on load

  useEffect(() => {
    dispatch(cleanReport());
    dispatch(getAllCustomers())
  }, [dispatch])

  return (
    <>

      {(customers?.isLoading || customers?.error) &&
        <Loader
          message={'Cargando...'}
          error={customers.error}
          action={() => dispatch(getAllCustomers())}
          dark={true}
      />}

    {!customers?.isLoading && customers?.data &&
      <>
        <Formik
            initialValues={{
              // start_date: new Date(),
              // end_date: new Date(),
              customer_id: ''
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => generateBalanceWithBalanceReport(values, actions)}
          >

            {props => (
            <>            

              {/* Form Inputs */}
              
              <>

                <SelectInput
                  formik={props}
                  fieldName={'customer_id'}
                  label={'Cliente'}
                  placeholder={'Seleccione'}
                  options={customers?.data ? customers?.data.map(option => ({ value: option.id, label: option.name })) : []}
                />

                <DateInput
                  formik={props}
                  fieldName={'start_date'}
                  label={'Fecha inicio'}
                  pickDay
                />

                <DateInput
                  formik={props}
                  fieldName={'end_date'}
                  label={'Fecha fin'}
                  pickDay
                />

                {/* Loader */}

                {(reports?.isLoading || reports?.error) &&
                  <Loader
                    message={'Generando reporte...'}
                    error={reports.error}
                    action={() => getReport(props.values, REPORT_TYPES.balanceflow)}
                    dark={true}
                />}

                {/* Download Report Button */}

                {!reports?.isLoading && reports.data && reportUrl &&            
                <DownloadReportButton
                  fileUrl={reportUrl}
                  fileName={'reporte-data-plana-balances'}
                />}


                {/* Form actions */}
              
                <div className='form-actions'>

                  <Button
                    action={() => setShowBalanceWithBalanceReportModal(false)}
                    label={'Cancelar'}
                    secondaryStyle={true}
                    disabled={reports?.isLoading}
                  />

                  <Button
                    action={props.handleSubmit}
                    label={'Generar'}
                    disabled={reports?.isLoading}
                    isLoading={reports?.isLoading}
                  />

                </div>
              </>

            </>)}

        </Formik>

      </>}
    </>
  )
}

// Form initial values and validaiton schemas

const validationSchema = yup.object().shape({
  customer_id: yup.string().required('Este campo es obligatorio')
});