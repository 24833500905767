import React, {useEffect} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

// Components

import Search from '../../forms/Search/Search';
import Alert from '../../UI/Alert/Alert';

import './Styles.scss';

export default function AccountsList({data, formik, fieldName, searchAction}) {

    const handlePermissionSelect = (item) => {
        const optionsArray = [...formik.values[fieldName]];
        if (optionsArray.includes(item)) {
            let index = optionsArray.indexOf(item);
            optionsArray.splice(index, 1);
        } else {
            optionsArray.push(item);
        }

        formik.setFieldValue(fieldName, optionsArray);
    }

    return (
        <div className='permissions-list'>

            <p>Seleccione cuentas</p>

            <Search onChange={searchAction} />

            <ul>
                {data.map((option, i) => (
                    <li key={i}>
                        <button
                            role="button" 
                            className={formik.values[fieldName]?.includes(option.id) ? 'permissions-list-selected' : ''}
                            onClick={() => handlePermissionSelect(option.id)}
                        >
                            <FontAwesomeIcon className='permissions-list-icon' icon={formik.values[fieldName]?.includes(option.id) ? faCircleCheck : faCircle} />
                            <span>{option.account}</span>
                        </button>
                    </li>
                ))}
            </ul>

            {formik.touched[fieldName] && formik.errors[fieldName] &&
            <Alert message={formik.errors[fieldName]} isError />}

        </div>
    )
}
