import React, { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { getTermsAndConditions, acceptTermsAndConditions, acceptTermsCleanup } from '../../store/terms/terms.actions';
import { setTermsAsAcceptedInUser } from '../../store/auth/auth.actions';


// Components

import Loader from '../../components/UI/Loader/Loader';
import SectionHeader from '../../components/UI/SectionHeader/SectionHeader';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import Alert from '../../components/UI/Alert/Alert';
import ButtonSmall from '../../components/UI/ButtonSmall/ButtonSmall';

export default function Terms() {
    const getTerms = useSelector((state) => state.terms.getTerms);
    const acceptTerms = useSelector((state) => state.terms.acceptTerms);
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    // Get the terms on load

    useEffect(() => {
      dispatch(getTermsAndConditions());
    }, [dispatch])

    useEffect(() => {
        if (acceptTerms.isAccepted) {
            dispatch(setTermsAsAcceptedInUser(user?.data));
            dispatch(acceptTermsCleanup());
        }
    }, [dispatch, acceptTerms])

    return (
        <div className='dashboard-wrapper'>

            <PageWrapper>
 
                <SectionHeader title={'Términos y condiciones'}>

                    {!getTerms?.isLoading && getTerms?.data &&
                    <ButtonSmall
                        label={'Aceptar Términos'}
                        action={() => dispatch(acceptTermsAndConditions())}
                        isLoading={acceptTerms?.isLoading}
                    />}
                </SectionHeader>

                <p>Por favor lea y acepte los términos y condiciones antes de continuar.</p>

                {getTerms.error &&
                <Alert message={getTerms.error} />}

                {(getTerms?.isLoading || getTerms.error) &&
                <Loader
                    message={'Cargando términos...'}
                    error={getTerms.error}
                    action={() => dispatch(getTermsAndConditions())}
                />}

                {!getTerms?.isLoading && getTerms?.data &&
                <div className="container">

                    <object style={{width:"100%", height:"100vh", marginTop: '1rem'}} data={getTerms?.data?.url} type="application/pdf"></object>
                   
                </div>} 

            </PageWrapper>

        </div>
    )
}
