import React, { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { getLogs } from '../../store/logs/logs.actions';

// Components

import Loader from '../../components/UI/Loader/Loader';
import Pagination from '../../components/UI/Pagination/Pagination';
import NoEntriesMessage from '../../components/UI/NoEntriesMessage/NoEntriesMessage';
import SectionHeader from '../../components/UI/SectionHeader/SectionHeader';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import LogsList from '../../components/lists/LogsList/LogsList';

export default function Logs() {
    const logs = useSelector((state) => state.logs.logs);
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getLogs());
    }, [dispatch])

    return (
        <div className='dashboard-wrapper'>

            <PageWrapper>

                <SectionHeader title={'Listado de logs'}>
                    <Pagination
                        action={getLogs}
                        pagination={logs?.data}
                    />
                </SectionHeader>

                {(logs?.isLoading || logs.error) &&
                <Loader
                    message={'Cargando logs...'}
                    error={logs.error}
                    action={getLogs}
                />}

                {!logs?.isLoading && logs?.data?.data && logs?.data?.data?.length > 0 &&
                    <LogsList data={logs?.data?.data} />
                }

                {!logs?.isLoading && logs?.data && logs?.data?.data?.length === 0 &&
                    <NoEntriesMessage message={'No se encontraron logs para esta busqueda'} />
                }

            </PageWrapper>

        </div>
  )
}
