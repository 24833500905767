import React, { useState } from 'react'

// Styles

import './Styles.scss';

// Components

import LogInForm from '../../components/forms/LogInForm/LogInForm';
import ForgotPassForm from '../../components/forms/ForgotPassForm/ForgotPassForm';
import ResetPassForm from '../../components/forms/ResetPassForm/ResetPassForm';
import Copyright from '../../components/UI/Copyright/Copyright';

const imgBrand = require('../../assets/i/img-brand.png');

export default function UserAccess() {
    const [form, setForm] = useState('login');
    return (
        <div className='auth-wrapper'>
            <div className='auth-form-container'>

                <img src={imgBrand} alt='Vallges Group' />

                {form === 'login' &&
                    <LogInForm setForm={setForm} />
                }

                {form === 'forgotpass' &&
                    <ForgotPassForm setForm={setForm} />
                }

                {form === 'resetpass' &&
                    <ResetPassForm setForm={setForm} />
                }

                <Copyright />

            </div>
        </div>
  )
}
