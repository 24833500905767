
import api from "../config/api";
import BaseService from './base-service';

export default class LogsService extends BaseService {

    // Get the list of all the logs ====================================================================================================

    /**
     * @param page optional page number
     * @description Gets the list of all the logs
     */

     static getLogs(page) {
        const url = page ? `/logs?page=${page}` : `/logs`;

        return api.get(url);
    }


    // Get a Single log ====================================================================================================

    /**
    * @param logId the id of the log
    * @returns Gets the detail of a single log
    */

     static getSingleLog(logId) {
        const url = `/logs/${logId}`

        return api.get(url);
    }

        // Get a Single log ====================================================================================================

    /**
    * @param logId the id of the log
    * @returns Gets the detail of a single log
    */

     static getSingleLog(logId) {
        const url = `/logs/${logId}`

        return api.get(url);
    }

    
    // Get the list of all the changes logs ====================================================================================================

    /**
     * @param page optional page number
     * @param fromDate starting date
     * @param toDate ending date
     * @description Get the list of all the changes logs
     */

    static getChangesLogs(page, fromDate, toDate) {
        const urlDate = fromDate && toDate ? `/audits-all?from=${fromDate}&to=${toDate}` : `/audits-all`;
        const url = page ? `${urlDate}?page=${page}` : urlDate;

        return api.get(url);
    }

        
    // Export changes logs ====================================================================================================

    /**
     * @param fromDate starting date
     * @param toDate ending date
     * @description Export changes logs
     */

    static exportChangesLogs(fromDate, toDate) {
        const url = fromDate && toDate ? `/audits-exports?from=${fromDate}&to=${toDate}` : `/audits-exports`;

        return api.get(url,{
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        });
    }

}
