import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss';

export default function Pagination({action, pagination, noDispatch}) {
  const [pages, setPages] = useState('0/0');
  const dispatch = useDispatch();
  
  return (
    <div className='pagination'>
        <button 
            disabled={pagination?.current_page === 1} 
            onClick={() => { 
                setPages(`${pagination?.current_page}/${pagination?.last_page}`);
                if (noDispatch) {
                    action(pagination?.current_page - 1)
                } else {
                    dispatch(action(pagination?.current_page - 1))
                }
            }}
        >
            <FontAwesomeIcon icon={faCaretLeft} />
        </button>
        <p>{
                pagination?.current_page ? (
                    `${pagination?.current_page}/${pagination?.last_page}`
                ) : (
                    pages
                )
            }
        </p>
        <button 
            disabled={pagination?.current_page === pagination?.last_page} 
            onClick={() => { 
                setPages(`${pagination?.current_page}/${pagination?.last_page}`);
                if (noDispatch) {
                    action(pagination?.current_page + 1)
                } else {
                    dispatch(action(pagination?.current_page + 1))
                }
            }}
        >
            <FontAwesomeIcon icon={faCaretRight} />
        </button>
    </div>
  )
}
