// API
import TermsService from '../../services/terms.service';

import { 
  setUpdateTermsLoading, setUpdateTermsSuccess, setUpdateTermsFailure, cleanUpdateTerms,
  setGetTermsLoading, setGetTermsSuccess, setGetTermsFailure,
  setAcceptTermsLoading, setAcceptTermsSuccess, setAcceptTermsFailure, cleanAcceptTerms,
} from './terms.reducers';

  // Update Terms and Conditions ====================================================================================================

  /**
   * @param data {
          @file terms,
          @string changes
      }
  * @returns True or false if the terms were updated
  */

 export const updateTermsAndConditions = (data) => async (dispatch) => {
  dispatch(setUpdateTermsLoading());

  try {

    await TermsService.updateTermsAndConditions(data);

    dispatch(setUpdateTermsSuccess(data));

  } catch(error) {
    dispatch(setUpdateTermsFailure(error.response?.data?.message || error.response?.data?.errors[0] || error.message));
  }

}

// Update Terms and Conditions Cleanup ====================================================================================================

/**
 * @description Cleans up Update Terms and Conditions store
 */

 export const updateTermsCleanup = () => (dispatch) => {
  dispatch(cleanUpdateTerms())

};


// Get Terms and Conditions ====================================================================================================

/**
  * @returns The url for the terms and conditions
*/

export const getTermsAndConditions = () => async (dispatch) => {
  dispatch(setGetTermsLoading());

  try {

    const response = await TermsService.getTermsAndConditions();

    dispatch(setGetTermsSuccess(response?.data));

  } catch(error) {
    dispatch(setGetTermsFailure(error.response?.data?.message || error.response?.data?.errors[0] || error.message));
  }

}

// Accept Terms and Conditions ====================================================================================================

/**
  * @returns True or false if the terms were accepted
*/

export const acceptTermsAndConditions = () => async (dispatch) => {
  dispatch(setAcceptTermsLoading());

  try {

    await TermsService.acceptTermsAndConditions();

    dispatch(setAcceptTermsSuccess());

  } catch(error) {
    dispatch(setAcceptTermsFailure(error.response?.data?.message || error.response?.data?.errors[0] || error.message));
  }

}

// Accept Terms and Conditions Cleanup ====================================================================================================

/**
 * @description Cleans up Accept Terms and Conditions store
 */

  export const acceptTermsCleanup = () => (dispatch) => {
  dispatch(cleanAcceptTerms())

};