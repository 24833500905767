import { createSlice } from '@reduxjs/toolkit'

// Setup the initial state

const initialState = {
  updateTerms: {},
  getTerms: {},
  acceptTerms: {},
};

export const termsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {

    // Update Terms and Conditions 

    setUpdateTermsLoading: (state) => {
      state.updateTerms = {
        isLoading: true,
      };
    },
    setUpdateTermsSuccess: (state, action) => {
      state.updateTerms = {
        isUpdated: true,
        data: action.payload
      };
    },
    setUpdateTermsFailure: (state, action) => {
      state.updateTerms = {
        error: action.payload,
      }
    },
    cleanUpdateTerms: (state) => {
      state.updateTerms = {}
    },

    // Get Terms and Conditions 

    setGetTermsLoading: (state) => {
      state.getTerms = {
        isLoading: true,
      };
    },
    setGetTermsSuccess: (state, action) => {
      state.getTerms = {
        data: action.payload
      };
    },
    setGetTermsFailure: (state, action) => {
      state.getTerms = {
        error: action.payload,
      }
    },

    // Accept Terms and Conditions 

    setAcceptTermsLoading: (state) => {
      state.acceptTerms = {
        isLoading: true,
      };
    },
    setAcceptTermsSuccess: (state, action) => {
      state.acceptTerms = {
        isAccepted: true,
        data: action.payload
      };
    },
    setAcceptTermsFailure: (state, action) => {
      state.acceptTerms = {
        error: action.payload,
      }
    },
    cleanAcceptTerms: (state) => {
      state.acceptTerms = {}
    },

  },
})

export const {
  setUpdateTermsLoading, setUpdateTermsSuccess, setUpdateTermsFailure, cleanUpdateTerms,
  setGetTermsLoading, setGetTermsSuccess, setGetTermsFailure,
  setAcceptTermsLoading, setAcceptTermsSuccess, setAcceptTermsFailure, cleanAcceptTerms,
} = termsSlice.actions

export default termsSlice.reducer;