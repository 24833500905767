import React, { useEffect } from 'react'
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

import { getBusinessDetail } from '../../store//business/business.actions';

// Components

import ClientCard from '../../components/cards/ClientCard/ClientCard';
import Loader from '../../components/UI/Loader/Loader';
import NoEntriesMessage from '../../components/UI/NoEntriesMessage/NoEntriesMessage';
import SectionHeader from '../../components/UI/SectionHeader/SectionHeader';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import ClientListToggleButtons from '../../components/UI/ClientListToggleButtons/ClientListToggleButtons';

export default function BusinessGroupDetail() {
    const { id } = useParams();
    const businessDetail = useSelector((state) => state.business.businessDetail);
    const clientListStyle = useSelector((state) => state.settings.clientListStyle);
    const dispatch = useDispatch();

    // Get all the customers on load

    useEffect(() => {
      dispatch(getBusinessDetail(id));
    }, [dispatch])
    
    return (
        <div className='dashboard-wrapper'>

            <PageWrapper>
                <SectionHeader title={`Clientes de ${businessDetail?.data?.name}`}>
                    <p>{businessDetail?.data?.user?.name} - {businessDetail?.data?.user?.email}</p>
                    <ClientListToggleButtons />
                </SectionHeader>

                {(businessDetail?.isLoading || businessDetail.error) &&
                <Loader
                    message={'Cargando clientes...'}
                    error={businessDetail.error}
                    action={() => dispatch(getBusinessDetail(id))}
                />}

                {!businessDetail?.isLoading && businessDetail?.data?.companies && businessDetail?.data?.companies?.length > 0 &&
                <div className="container">
                    <div className="row">

                        {businessDetail?.data?.companies?.map((entry, i) => (
                            <div key={i} className={clientListStyle ? "" : "col-md-4"}>
                                <ClientCard
                                    entry={entry}
                                    listStyle={clientListStyle}
                                />
                            </div>
                        ))}

                    </div>
                </div>}

                {!businessDetail?.isLoading && businessDetail?.data?.companies && businessDetail?.data?.companies?.length === 0 &&
                    <NoEntriesMessage message={'No se encontraron clientes para esta busqueda'} />
                }

            </PageWrapper>

        </div>
  )
}
