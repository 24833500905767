import { createSlice } from '@reduxjs/toolkit'

// Setup the initial state

const initialState = {
  clientListStyle: false,
  showAllAccountsListColumns: false
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {

    // Toggle Client List Style

    toggleClientListStyle: (state, action) => {
      state.clientListStyle = action.payload;
    },

    // Show All Accounts List Columns

    showAllAccountsListColumns: (state, action) => {
      state.showAllAccountsListColumns = action.payload;
    },

  },
})

export const { 
  toggleClientListStyle,
  showAllAccountsListColumns,
} = settingsSlice.actions

export default settingsSlice.reducer;