import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss';

export default function Alert({message, isError}) {
  return (
    <div className={isError ? 'alert-message alert-danger' : 'alert-message'}>
        <p><FontAwesomeIcon className='alert-message-icon' icon={faInfoCircle} /> {message}</p>
    </div>
  )
}
