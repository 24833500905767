import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';

import Moment from 'moment';
import 'moment/locale/es';

import { uploadBalanceData, cleanUpUploadBalanceData } from '../../../store/balances/balances.actions';
import { getInitialBalanceTemplate } from '../../../store/balances/balances.actions';

// Components

import Loader from '../../UI/Loader/Loader';
import FileInput from '../../UI/FormInput/FileInput';
import Button from '../../UI/Button/Button';
import UploadBalanceAlert from '../../UI/UploadBalanceAlert/UploadBalanceAlert';
import DateInput from '../../UI/FormInput/DateInput';
import SelectInput from '../../UI/FormInput/SelectInput';

// Data

import { UPLOAD_TYPES } from '../../../config/constants';

// Utilities

import { setupFormData, getMaxDate } from '../../../utilities/helper-functions';

// Styles

import './Styles.scss';

export default function UploadBalanceForm({clientId, balanceTemplateUrl, balanceInitialTemplateUrl, longestBalancesArray, setShowUploadBalanceModal, hasData}) {
  const [showUploadResults, setShowUploadResults] = useState(false);
  const getBalanceTemplate = useSelector((state) => state.balances.getInitialBalanceTemplate);
  const balanceUpload = useSelector((state) => state.balances.uploadBalanceData);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  // Upload Balance
  
  const uploadBalance = async (values, actions) => {
    const balances = longestBalancesArray?.balances;
    let vals = { ...values }
    let monthRangeNames = [];

    if (balances && vals.upload_type === 2) {
      balances.forEach(item => {
        monthRangeNames?.push(item.title);
      });
      vals.months = JSON.stringify(monthRangeNames);
    }

    if (vals.upload_type === 2) {
      vals.start_date = Moment(vals.start_date).format('MM-yyyy');
    } else {
      delete vals.start_date;
    }

    const data = setupFormData(vals);
    dispatch(uploadBalanceData(data));
  }

  useEffect(() => {
    dispatch(cleanUpUploadBalanceData());
    dispatch(getInitialBalanceTemplate());
  }, [dispatch])

  useEffect(() => {
    if (balanceUpload.isUploaded) {
      if (balanceUpload.data?.logs?.length > 0) {
        setShowUploadResults(true);
      } else {
        setShowUploadBalanceModal(false);
      }
      // dispatch(cleanUpUploadBalanceData());
    }
  }, [dispatch, setShowUploadBalanceModal, balanceUpload])

  return (
    <>
      {(getBalanceTemplate?.isLoading || getBalanceTemplate.error) &&
      <Loader
          message={'Cargando...'}
          error={getBalanceTemplate.error}
          action={getInitialBalanceTemplate}
      />}

      {!getBalanceTemplate?.isLoading && getBalanceTemplate?.data && !showUploadResults &&
      <Formik
          initialValues={{
            isAdmin: user.data?.role === 1 ? true : false,
            upload_type: (user.data?.role === 1 && !hasData) ? 1 : 2,
            start_date: getMaxDate(),
            customer_id: clientId,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => uploadBalance(values, actions)}
        >

          {props => (
          <>
          
            {/* Show uploaded balance file errors or regular response error message */}
          
            {balanceUpload.error && (typeof balanceUpload.error === 'object') ? (

              <UploadBalanceAlert data={balanceUpload} />

            ) : balanceUpload.error && (typeof balanceUpload.error !== 'object') ? (
            
              <p className="form-error-message">{balanceUpload.error}</p>
            
            ): (null)}

            {/* Form Inputs */}
            
            <>

              {user.data?.role === 1 &&
              <SelectInput
                formik={props}
                fieldName={'upload_type'}
                label={'Tipo de carga'}
                placeholder={'Seleccione'}
                options={UPLOAD_TYPES}
                disabled={hasData}
              />}

              {props.values.upload_type === 2 &&
              <DateInput
                formik={props}
                fieldName={'start_date'}
                label={'Fecha'}
              />}

              <FileInput
                formik={props}
                fieldName={'balances'}
                label={'Archivo balance'}
                fileType={'excel'}
              />

              <div className='balance-template-download'>
                ¿No tiene el formato de archivo de su balanza?{' '}
                {balanceTemplateUrl &&
                <Link to={balanceTemplateUrl} className='balance-template-link' target="_blank" download>
                  Descarge el template
                </Link>}
                {' '}para subir los datos de sus cuentas.
                <br/><br/>

                {/* Inicial Balance Upload Template Link */}

                {user.data?.role === 1 &&
                <>
                  ¿Desea subir una carga inicial?{' '}
                  {getBalanceTemplate?.data?.url &&
                  <a href={getBalanceTemplate?.data?.url} className='balance-template-link' target="_blank">
                    Descarge el template de carga inicial
                  </a>}
                  {' '}para subir los datos.
                </>}
              </div>
            
              <div className='form-actions'>

                <Button
                  action={() => setShowUploadBalanceModal(false)}
                  label={'Cancelar'}
                  secondaryStyle={true}
                  disabled={balanceUpload?.isLoading}
                />

                <Button
                  action={props.handleSubmit}
                  label={'Enviar'}
                  disabled={balanceUpload?.isLoading}
                  isLoading={balanceUpload?.isLoading}
                />

              </div>
            </>

          </>)}

      </Formik>}

      {!getBalanceTemplate?.isLoading && getBalanceTemplate?.data && showUploadResults &&
      <div className='upload-balance-results'>
        <h2>Resultados</h2>
        <p>La balanza fue cargada exitosamente, sin embargo la misma presenta los siguientes issues:</p>

        <ul>
          {balanceUpload.data?.logs && balanceUpload.data?.logs.map((log, i) =>
            <li key={i}><span className='upload-balance-results-issue-number'>{i + 1}</span> <span className='upload-balance-results-issue-message'>{log.message}</span></li>
          )}
        </ul>

        <div className='upload-balance-results-actions'>
          <Button
            action={() => setShowUploadBalanceModal(false)}
            label={'Cerrar'}
          />
        </div>
      </div>
      }
    </>
  )
}

// Form initial values and validaiton schemas

const validationSchema = yup.object().shape({
  upload_type: yup.string().when('isAdmin', {
    is: (val) => val === true,
    then: yup.string().required('Este campo es requerido'),
    otherwise: yup.string(),
  }),
  start_date: yup.string().when('upload_type', {
    is: (val) => val === 2,
    then: yup.string().required('Este campo es requerido'),
    otherwise: yup.string(),
  }),
  balances: yup
    .mixed()
    .required('Este campo es requerido'),
});