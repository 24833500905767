
import api from "../config/api";
import BaseService from './base-service';

export default class UsersService extends BaseService {

    // Get Customers With Users ====================================================================================================

    /**
     * @param page optional page number
     * @description Gets the list of all customers with their users
     */

     static getCustomersWithUsers(page) {
        const url = page ? `/all/customers/users?page=${page}` : `/all/customers/users`;

        return api.get(url);
    }


    // Search Customers with Users ====================================================================================================

    /**
     * @param query the terms of the search query
     * @description Gets the list of all the customers with users that match the search query
     */

     static searchCustomersWithUsers(query) {
        const url = `/all/customers/users?search=${query}`;

        return api.get(url);
    }

    
    // Get Single Customer With Users ====================================================================================================

    /**
    * @param customerId the id of the customer
    * @returns Gets the list of all users of a customer
    */

     static getSingleCustomerWithUsers(customerId) {
        const url = `/customers/users/${customerId}`

        return api.get(url);
    }


    // Get All Users ====================================================================================================

    /**
     * @param page optional page number
     * @description Gets the list of all the users
     */

    static getUsers(page) {
        const url = page ? `/users?page=${page}` : `/users`;

        return api.get(url);
    }

    
    // Get All Admin Users ====================================================================================================

    /**
     * @description Gets the list of all the admin users
     */

    static getAllAdminUsers() {
        const url = `/business/all-users`;

        return api.get(url);
    }

    
    // Search All Users ====================================================================================================

    /**
     * @param query the terms of the search query
     * @description Gets the list of all the users that match the search query
     */

     static searchUsers(query) {
        const url = `/users?search=${query}`;

        return api.get(url);
    }

     
    // Create a User ====================================================================================================

    /**
     * @param data {
          @number role,
          @number customer_id
          @string name,
          @string lastname,
          @string email,
          @string password,
          @number status,
        }
    * @returns Creates a new user
    */

     static createUser(data) {
        const url = `/users`

        return api.post(url, data);
    }


    // Edit a User ====================================================================================================

    /**
     * @param data {
          @number role,
          @number customer_id
          @string name,
          @string lastname,
          @string email,
          @string password,
          @number status,
        }
    * @param userId the id of the user
    * @returns Edits a user info
    */

    static editUser(data, userId) {
        const url = `/users/${userId}`

        return api.put(url, data);
    }


    // Delete a User ====================================================================================================

    /**
    * @param userId the id of the user
    * @returns True or false if the user was deleted
    */

    static deleteUser(userId) {
        const url = `/users/${userId}`

        return api.delete(url);
    }


    // Transfer all clients from one user's wallet to another ====================================================================================================

    /**
     * @param data {
            @number user_from,
            @number user_to
        }
    * @returns True or false if the request was succesful
    */

    static transferWalletClientsToUser(data) {
        const url = `/transfer-all-customers`

        return api.post(url, data);
    }


    // Get user's roles and permissions ====================================================================================================

    /**
    * @returns A list of all the user roles with thier permissions
    */

    static getUserRolesAndPermissions() {
        const url = `/permissions`

        return api.get(url);
    }


    // Get user's info ====================================================================================================

    /**
    * @returns The info of the logged in user
    */

    static getUserInfo() {
        const url = `/user`

        return api.get(url);
    }

}
