import React from 'react'

import { useSelector } from 'react-redux'

import Moment from 'moment';
import 'moment/locale/es';

import { LOG_TYPES, LOG_STATUS } from '../../../config/constants';

import './Styles.scss';
import LogStatusLabel from '../../UI/LogStatusLabel/LogStatusLabel';

// Utilities

import { formatCurrency, setupPermissionsNamesArray } from '../../../utilities/helper-functions';

export default function LogsList({data}) {
    const user = useSelector((state) => state.auth.user);
    const userPermissions = user?.data?.permissionsData;
    const userPermissionsNames = setupPermissionsNamesArray(userPermissions);

    return (
        <div className='table-list'>

            <table>
                <thead>
                    <tr className='table-list-headers'>
                        <th>Cliente</th>
                        {/* <th>Tipo</th> */}
                        <th>Descripción</th>
                        <th>Fecha</th>
                        <th>Estado</th>
                    </tr>
                </thead>

                <tbody>

                    {data.map((entry, i) => (
                        <LogEntry
                            key={i}
                            entry={entry}
                            userPermissionsNames={userPermissionsNames}
                        />
                    ))}

                </tbody>
                
            </table>

        </div>
    )
}

const LogEntry = ({entry, userPermissionsNames}) => {
    const type = LOG_TYPES.find(item => item.value === Number(entry.log_type));
    const status = LOG_STATUS.find(item => item.value === Number(entry.resolved));
    const canViewLog = type?.key ? userPermissionsNames?.includes(type?.key) : true;

    if (canViewLog) {
        return (
            <tr className='table-entry'>
                <td className='log-customer'>
                    <p><strong>{entry.customer?.name}</strong></p>
                    <p><small>{entry.user?.name}</small></p>
                </td>
                {/* <td className='log-type'><p>{type?.label}</p></td> */}
                <td className='log-message'>
                    <p><strong>Tipo: {type?.label}</strong></p>
                    <p>{entry.message}</p>
                    {entry.changes &&
                    <div className='log-message-changes'>
                        <p>Balance anterior: {entry.changes?.old?.balance ? formatCurrency(String(entry.changes?.old?.balance)) : formatCurrency(0)}</p>
                        <p>Balance actual: {entry.changes?.new?.balance ? formatCurrency(String(entry.changes?.new?.balance)) : formatCurrency(0)}</p>
                    </div>}
                    {entry.account &&
                    <div className='log-message-changes'>
                        <p>Cuenta: {entry.account}</p>
                    </div>}
                </td>
                <td className='log-date'>
                    <p>{Moment(entry.created_at).format('MM/DD/YYYY, h:mm a')}</p>
                </td>
                <td className='log-status'>
                    <LogStatusLabel status={status} />
                    {entry.resolved_by_user?.name &&
                    <div className='log-message-changes'>
                        <p>Por {entry.resolved_by_user?.name}</p>
                    </div>}
                </td>
            </tr>
        )
    } else {
        return null;
    }
}