import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'


import { bulkEditBalanceAccounts, cleanUpBulkEditBalanceAccounts } from '../../../store/balances/balances.actions';
import { getDropDownOptions } from '../../../store/options/options.actions';

// Components

import FormInput from '../../UI/FormInput/FormInput';
import FormSteps from '../../UI/FormSteps/FormSteps';
import SelectInput from '../../UI/FormInput/SelectInput';
import Button from '../../UI/Button/Button';
import Loader from '../../UI/Loader/Loader';
import AccountsList from '../../lists/AccountsList/AccountsList';

// Data

import {
  EXPENSE_TYPE_FORMATTED 
} from '../../../config/constants';

const steps = [
  'Cuentas',
  'Clasificaciones'
]

export default function ClassifyBalanceAccountsForm({clientId, accounts, setClassifyBalanceAccountsModal}) {
  const [formStep, setFormStep] = useState(0);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const bulkBalanceAccountEdit = useSelector((state) => state.balances.bulkBalanceAccountEdit);
  const dropDownOptions = useSelector((state) => state.options.dropDownOptions);
  const dispatch = useDispatch();

  // Edit a client account

  const clientAccountEdit = async (values, actions) => {
    if (formStep === 1) {
      dispatch(bulkEditBalanceAccounts(values));
    } else {
      setFormStep(formStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  useEffect(() => {
    if (accounts) {
      setFilteredAccounts(accounts);
    }
  }, [accounts])

  // Search Accounts

  const searchAccounts = (value) => {
    if (value !== '') {
      const data = filteredAccounts.filter((item) => {
          return Object.values(item).join('').toLowerCase().includes(value.toLowerCase())
      })
      setFilteredAccounts(data)
    } else {
      setFilteredAccounts(accounts)
    }
  }

  useEffect(() => {
    dispatch(getDropDownOptions())
    dispatch(cleanUpBulkEditBalanceAccounts())
  }, [dispatch])

  useEffect(() => {
    if (bulkBalanceAccountEdit.isEdited) {
      setClassifyBalanceAccountsModal(false);
      dispatch(cleanUpBulkEditBalanceAccounts());
    }
  }, [dispatch, setClassifyBalanceAccountsModal, bulkBalanceAccountEdit])

  return (
    <>

      {(dropDownOptions?.isLoading || dropDownOptions?.error) &&
        <Loader
          message={'Cargando...'}
          error={dropDownOptions.error}
          action={() =>  dispatch(getDropDownOptions())}
          dark={true}
      />}

      {!dropDownOptions?.isLoading && dropDownOptions?.data &&
      <>
        <FormSteps steps={steps} currentStep={formStep} />

        <Formik
          initialValues={{ 
            customer_id: clientId,
            account: [],
            account_classification: '',
            active_passive_si: '',
            account_eeff: '',
            fixed_variable_ex_type: '',
            flow_heading: '',
            partida_gp: '',
            tax_risk: '',
          }}
          validationSchema={validationSchema[formStep]}
          onSubmit={(values, actions) => clientAccountEdit(values, actions)}
        >

          {props => (
          <>

            {bulkBalanceAccountEdit.error && <p className="form-error-message">{bulkBalanceAccountEdit.error}</p>}

            {/* Form Inputs */}

            {formStep === 0 &&
            <>
              <AccountsList
                data={filteredAccounts}
                formik={props}
                fieldName={'account'}
                searchAction={searchAccounts}
              />

              <div className='form-actions'>

                <Button
                  action={() => setClassifyBalanceAccountsModal(false)}
                  label={'Cancelar'}
                  secondaryStyle={true}
                />

                <Button
                  action={props.handleSubmit}
                  label={'Continuar'}
                />

              </div>
            </>}            

            {formStep === 1 &&
            <>

              <SelectInput
                formik={props}
                fieldName={'account_classification'}
                label={'Clasificación Cuenta'}
                placeholder={'Seleccione'}
                options={dropDownOptions?.data ? dropDownOptions?.data?.account_classifications.map(option => ({ value: option.name, label: option.name })) : []}
              />

              <SelectInput
                formik={props}
                fieldName={'active_passive_si'}
                label={'Partidas Estructura'}
                placeholder={'Seleccione'}
                options={dropDownOptions?.data ? dropDownOptions?.data?.structure_items.map(option => ({ value: option.name, label: option.name })) : []}
              />

              <SelectInput
                formik={props}
                fieldName={'flow_heading'}
                label={'Partida Flujo'}
                placeholder={'Seleccione'}
                options={dropDownOptions?.data ? dropDownOptions?.data?.flow_headings.map(option => ({ value: option.name, label: option.name })) : []}
              />

              <SelectInput
                formik={props}
                fieldName={'account_eeff'}
                label={'Cuenta EEFF'}
                placeholder={'Seleccione'}
                options={dropDownOptions?.data ? dropDownOptions?.data?.eeff_accounts.map(option => ({ value: option.name, label: option.name })) : []}
              />

              <SelectInput
                formik={props}
                fieldName={'partida_gp'}
                label={'Partida G&P'}
                placeholder={'Seleccione'}
                options={dropDownOptions?.data ? dropDownOptions?.data?.gp_items.map(option => ({ value: option.name, label: option.name })) : []}
              />

              <FormInput
                  formik={props}
                  fieldName={'tax_risk'}
                  label={'Cuentas de Análisis'}
              />

              <SelectInput
                formik={props}
                fieldName={'fixed_variable_ex_type'}
                label={'Tipo Gasto'}
                placeholder={'Seleccione'}
                options={EXPENSE_TYPE_FORMATTED}
              />
            
              <div className='form-actions'>

                <Button
                  action={() => setFormStep(formStep - 1)}
                  label={'Atrás'}
                  disabled={bulkBalanceAccountEdit?.isLoading}
                  secondaryStyle={true}
                />

                <Button
                  action={props.handleSubmit}
                  label={'Editar'}
                  disabled={bulkBalanceAccountEdit?.isLoading}
                  isLoading={bulkBalanceAccountEdit?.isLoading}
                />

              </div>

            </>}

          </>)}

        </Formik>
      </>}
    </>
  )
}

// Form initial values and validaiton schemas

const validationSchema = [
  yup.object().shape({
    account: yup
      .array()
      .min(1, 'Seleccione por lo menos una cuenta')
      .required('Este campo es requerido'),
  }),
  yup.object().shape({
  }),
];