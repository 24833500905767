import {combineReducers} from 'redux';

import authReducer from './auth/auth.reducers';
import customersReducer from './customers/customers.reducers';
import businessReducer from './business/business.reducers';
import usersReducer from './users/users.reducers';
import balancesReducer from './balances/balances.reducers';
import logsReducer from './logs/logs.reducers';
import reportsReducer from './reports/reports.reducers';
import optionsReducer from './options/options.reducers';
import settingsReducer from './settings/settings.reducers';
import termsReducer from './terms/terms.reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  customers: customersReducer,
  business: businessReducer,
  users: usersReducer,
  balances: balancesReducer,
  logs: logsReducer,
  reports: reportsReducer,
  options: optionsReducer,
  settings: settingsReducer,
  terms: termsReducer
});

export default rootReducer;
