import React, {useState} from 'react'

import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faClipboardUser, faCaretRight, faCaretDown, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import {USER_ROLES_OPTIONS} from '../../../config/constants';

import './Styles.scss';
import StatusLabel from '../../UI/StatusLabel/StatusLabel';

// Constants

import { VALLGES_CLIENT_ID } from '../../../config/constants';

export default function UserCard({entry, openEditUserModal, openEditClientPortfolioModal, openEditClientUserPortfolioModal, clientId, hasGroup}) {
  const [toggleInfo, setToggleInfo] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const userPermissions = user?.data?.permissionsData;
  const role = USER_ROLES_OPTIONS.find(item => item.value === Number(entry.role));

  return (
    <div className='user-card'>
      <div className='user-card-content'>
        {clientId === VALLGES_CLIENT_ID && userPermissions.AsignarYEditarCarterasDeClientes ? (
          <div className='user-card-name' onClick={() => setToggleInfo(!toggleInfo)}>
            <h2><FontAwesomeIcon className='user-card-toggle-icon' icon={toggleInfo ? faCaretDown : faCaretRight} /> {entry.name} {entry.lastname}</h2>
            <p>{entry.wallet?.length} cliente(s) en cartera • {entry.permissions.length} permisos</p>
          </div>
        ) : (
          <div className='user-card-name' onClick={() => setToggleInfo(!toggleInfo)}>
            <h2><FontAwesomeIcon className='user-card-toggle-icon' icon={toggleInfo ? faCaretDown : faCaretRight} /> {entry.name} {entry.lastname}</h2>
            <p>{entry.permissions?.length} permisos</p>
          </div>
        )}
        <div className='user-card-email'>
          <p>{entry.email}</p>
        </div>
        <div className='user-card-role'>
          <p>{role?.label}</p>
        </div>
        <div className='user-card-status'>
          <StatusLabel status={entry.status} />
        </div>

        {clientId === VALLGES_CLIENT_ID && userPermissions.AsignarYEditarCarterasDeClientes &&
        <button
          title='Editar cartera de clientes'
          className='user-card-edit-porfolio-button'
          onClick={() => openEditClientPortfolioModal(entry)}
        >
          <FontAwesomeIcon icon={faClipboardUser} />
        </button>}

        {clientId === VALLGES_CLIENT_ID && userPermissions.CrearYModificarUsuarioVallgesGroup &&
        <button
          title='Editar usuario'
          className='user-card-edit-button'
          onClick={() => openEditUserModal(entry)}
        >
          <FontAwesomeIcon icon={faGear} />
        </button>}

        {clientId !== VALLGES_CLIENT_ID && (userPermissions.CrearYModificarUsuarioAdministradorDelCliente || userPermissions.CrearYModificarUsuarioAdministradorDelCliente || userPermissions.CrearYModificarUsuarioCliente) &&
        <button
          title='Editar usuario'
          className='user-card-edit-button'
          onClick={() => openEditUserModal(entry)}
        >
          <FontAwesomeIcon icon={faGear} />
        </button>}

        {clientId !== VALLGES_CLIENT_ID && user.data?.role === 4 && hasGroup &&
        <button
          title='Editar cartera de clientes'
          className='user-card-edit-porfolio-button'
          onClick={() => openEditClientUserPortfolioModal(entry)}
        >
          <FontAwesomeIcon icon={faClipboardUser} />
        </button>}
        
      </div>
      {toggleInfo &&
      <div className='user-card-info'>
        {clientId === VALLGES_CLIENT_ID && userPermissions.AsignarYEditarCarterasDeClientes &&
        <>
          {entry.wallet?.length > 0 && 
          <div className='clients-entries'>
            <p className='clients-entries-heading'>Clientes en cartera</p>
            <div>
            {entry.wallet.map((item, i) => (
              <div className='clients-entry' key={i}>
                {item.customer?.name}
              </div>
            ))}
            </div>
          </div>}

          {entry.wallet?.length === 0 &&
          <p className='no-clients-inwallet-message'>
            <FontAwesomeIcon className='no-clients-inwallet-message-icon' icon={faCircleExclamation} />
            Este ejecutivo no tiene clientes en su cartera.
          </p>}
        </>}

        {(userPermissions.VisualizarLosPermisosAsignadosAlUsuarioDeLaCarteraDeClientesAsignadas || userPermissions.VisualizarTodosPermisosAsignadosAlUsuarioParaTodosClientes) &&
        <>
          {entry.permissions?.length > 0 && 
          <div className='clients-entries'>
            <p className='clients-entries-heading'>Permisos de usuario</p>
            <div className='client-entries-permissions'>
            {entry.permissions.map((item, i) => (
              <div className='clients-entry' key={i}>
                {item.description}
              </div>
            ))}
            </div>
          </div>}

          {entry.permissions?.length === 0 &&
          <p className='no-clients-inwallet-message'>
            <FontAwesomeIcon className='no-clients-inwallet-message-icon' icon={faCircleExclamation} />
            Este usuario no tiene permisos asignados.
          </p>}
        </>}
      </div>}
    </div>
  )
}
