import React from 'react'

import Moment from 'moment';
import 'moment/locale/es';

import './Styles.scss';

export default function ClientBalanceSheet({customer, data}) {
    const currentMonth = Moment(data?.actual).format('MMM yyyy');
    const previousMonth = Moment(data?.last_year).format('MMM yyyy');

  return (
    <div className="container">
        <div className="client-balance-sheet">
            <div className="row">
                <div className="col-md-4">
                    <h2>Ingresos</h2>
                    <div className='balance-sheet-entries'>
                        <div className='balance-sheet-entry'>
                            <p className={data?.incomes >= data?.last_year_incomes ? 'balance-amount' : 'balance-amount balance-amount-danger'}>${data?.incomes}</p>
                            <p>{currentMonth}</p>
                        </div>
                        <div className='balance-sheet-entry'>
                            <p className='balance-amount-prev'>${data?.last_year_incomes}</p>
                            <p>Año anterior - {previousMonth}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <h2>Costo Mercancia vendida</h2>
                    <div className='balance-sheet-entries'>
                        <div className='balance-sheet-entry'>
                            <p className={data?.cost_of_merchandise_sold <= data?.last_year_cost_of_merchandise_sold ? 'balance-amount' : 'balance-amount balance-amount-danger'}>${data?.cost_of_merchandise_sold}</p>
                            <p>{currentMonth}</p>
                        </div>
                        <div className='balance-sheet-entry'>
                            <p className='balance-amount-prev'>${data?.last_year_cost_of_merchandise_sold}</p>
                            <p>Año anterior - {previousMonth}</p>
                        </div>
                    </div>

                    {customer.sector === 'Manufactura' &&
                    <small style={{textAlign: 'center', display: 'block'}}>Mercancia vendida Incluye los costos de producción</small>}
                </div>
                <div className="col-md-4">
                    <h2>Gastos</h2>
                    <div className='balance-sheet-entries'>
                        <div className='balance-sheet-entry'>
                            <p className={data?.expenses <= data?.last_year_expenses ? 'balance-amount' : 'balance-amount balance-amount-danger'}>${data?.expenses}</p>
                            <p>{currentMonth}</p>
                        </div>
                        <div className='balance-sheet-entry'>
                            <p className='balance-amount-prev'>${data?.last_year_expenses}</p>
                            <p>Año anterior - {previousMonth}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className='balance-sheet-admin'>
                        <h2>EBITDA</h2>
                        <div className='balance-sheet-entry'>
                            <p className='balance-amount'>${data?.ebitda}</p>
                            <p>{currentMonth}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className='balance-sheet-admin-prev'>
                        <p className='balance-amount'>${data?.last_year_ebitda}</p>
                        <p>Año anterior - {previousMonth}</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className='balance-sheet-admin'>
                        <h2 style={{marginRight: '1rem'}}>BENEFICIO NETO</h2>
                        <div className='balance-sheet-entry'>
                            <p className='balance-amount'>${data?.net_profit}</p>
                            <p>{currentMonth}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className='balance-sheet-admin-prev'>
                        <p className='balance-amount'>${data?.last_year_net_profit}</p>
                        <p>Año anterior - {previousMonth}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
