import React from 'react'

import './Styles.scss';

export default function LogStatusLabel({status}) {
  return (
    <p className={status?.type === 'success' ? 'type-label' : 'type-label type-label-danger'}>
        {status?.label}
    </p>
  )
}