import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { getCustomersWithUsers, searchCustomersWithUsers } from '../../store/users/users.actions';
import { getAllCustomers } from '../../store/customers/customers.actions';

// Components

import AddUserButton from '../../components/UI/AddUserButton/AddUserButton';
import Modal from '../../components/UI/Modal/Modal';
import AddUserForm from '../../components/forms/AddUserForm/AddUserForm';
import Loader from '../../components/UI/Loader/Loader';
import Search from '../../components/forms/Search/Search';
import Pagination from '../../components/UI/Pagination/Pagination';
import NoEntriesMessage from '../../components/UI/NoEntriesMessage/NoEntriesMessage';
import SectionHeader from '../../components/UI/SectionHeader/SectionHeader';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import Alert from '../../components/UI/Alert/Alert';
import UsersList from '../../components/lists/UsersList/UsersList';

export default function Users() {
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const customersUsers = useSelector((state) => state.users.customersUsers);
    const userCreate = useSelector((state) => state.users.createUser);
    const user = useSelector((state) => state.auth.user);
    const userPermissions = user?.data?.permissionsData;

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getCustomersWithUsers());
      dispatch(getAllCustomers());
    }, [dispatch])


    // Show alert message when an user is created

    useEffect(() => {
        if (userCreate.isCreated) {
            showAlertMessage('Usuario añadido exitosamente.')
        }
    }, [userCreate])


    // Show alert message

    const showAlertMessage = (message) => {
        setAlertMessage(message)
        setTimeout(() => {
            setAlertMessage(null)
        }, 4000);
    }

    return (
        <div className='dashboard-wrapper'>

            <PageWrapper>

                <SectionHeader title={'Listado de usuarios'}>
                    {(userPermissions.CrearYModificarUsuarioVallgesGroup || userPermissions.CrearYModificarUsuarioAdministradorDelCliente || userPermissions.CrearUsuarioAdministradorDelCliente || userPermissions.CrearYModificarUsuarioCliente) &&
                    <AddUserButton label={'Añadir usuario'} action={() => setShowAddUserModal(true)} />}

                    <Search
                        action={searchCustomersWithUsers}
                    />

                    <Pagination
                        action={getCustomersWithUsers}
                        pagination={customersUsers?.data}
                    />
                </SectionHeader>

                {alertMessage &&
                <Alert message={alertMessage} />}

                {(customersUsers?.isLoading || customersUsers.error) &&
                <Loader
                    message={'Cargando usuarios...'}
                    error={customersUsers.error}
                    action={() => dispatch(getCustomersWithUsers())}
                />}

                {!customersUsers?.isLoading && customersUsers?.data?.data && customersUsers?.data?.data?.length > 0 &&
                    <UsersList data={customersUsers?.data?.data} />
                }

                {!customersUsers?.isLoading && customersUsers?.data && customersUsers?.data?.data?.length === 0 &&
                    <NoEntriesMessage message={'No se encontraron usuarios para esta busqueda'} />
                }

            </PageWrapper>


            {/* Add User Modal */}

            <Modal show={showAddUserModal} title={'Añadir Usuario'}>
                <AddUserForm
                    setShowAddUserModal={setShowAddUserModal}
                />
            </Modal>

        </div>
  )
}
