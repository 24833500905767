import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import Moment from 'moment';
import 'moment/locale/es';

import { setupChangeLogValues } from '../../../utilities/helper-functions';

import './Styles.scss';

export default function ChangesLogsList({data}) {
    return (
        <div className='table-list'>

            <table>
                <thead>
                    <tr className='table-list-headers'>
                        <th>Usuario</th>
                        <th>Acción</th>
                        <th>Fecha</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>

                    {data.map((entry, i) => (
                        <LogEntry
                            key={i}
                            entry={entry}
                        />
                    ))}

                </tbody>
                
            </table>

        </div>
    )
}

const LogEntry = ({entry}) => {
    const [toggleInfo, setToggleInfo] = useState(false);
    const oldVals = entry.old_values ? setupChangeLogValues(entry.old_values) : null;
    const newVals = entry.new_values ? setupChangeLogValues(entry.new_values) : null;
 
    return (
        <tr className='table-entry table-changes'>
            <td className='log-customer'>
                <p><strong>{entry.user?.name}</strong></p>
                <p><small>{entry.user?.customer?.name ? entry.user?.customer?.name : 'Sin Cliente'}</small></p>
            </td>
            <td className='log-message'>
                <p><strong>{entry.event} {entry.object}</strong></p>

                {toggleInfo &&
                <div className='log-changes' style={{display:'flex', flexWrap: 'wrap'}}>
                    {oldVals?.length > 0 &&
                    <div className='log-message-changes' style={{width: '50%', display: 'block'}}>
                        <p><strong>Valor anterior</strong></p>
                        {oldVals.map((entry, i) => (
                            <p key={i}>{entry}</p>
                        ))}
                    </div>}

                    {newVals?.length > 0 &&
                    <div className='log-message-changes' style={{width: '50%', display: 'block'}}>
                        <p><strong>Valor nuevo</strong></p>
                        {newVals.map((entry, i) => (
                            <p key={i}>{entry}</p>
                        ))}
                    </div>}
                </div>}
            </td>
            <td className='log-date'>
                <p>{entry.created_at}</p>
            </td>


          <td className='log-action'>
            {(oldVals || newVals) &&
            <button
                title='Ver detalle de cambios'
                className='user-card-edit-porfolio-button'
                  onClick={() => setToggleInfo(!toggleInfo)}
            >
                <FontAwesomeIcon icon={faInfoCircle} />
            </button>}
          </td>

        </tr>
    )
}