import React, { useEffect } from 'react'

import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'


import { updateDropDownGroupOption, cleanUpUpdateOption } from '../../../store/options/options.actions';

// Components

import FormInput from '../../UI/FormInput/FormInput';
import SelectInput from '../../UI/FormInput/SelectInput';
import Button from '../../UI/Button/Button';

// Constants

import { ASSOCIATE_TYPE_FORMATTED } from '../../../config/constants';

export default function EditOptionForm({group, option, setShowUpdateOptionModal}) {
  const optionUpdate = useSelector((state) => state.options.updateOption);
  const dispatch = useDispatch();

  // Create an option

  const createNewOption = async (values, actions) => {
    dispatch(updateDropDownGroupOption(group, option.id, values));
  }

  useEffect(() => {
    if (optionUpdate.isUpdated) {
      setShowUpdateOptionModal(false);
      dispatch(cleanUpUpdateOption());
    }
  }, [dispatch, setShowUpdateOptionModal, optionUpdate])

  useEffect(() => {
    dispatch(cleanUpUpdateOption());
  }, [dispatch])

  return (
    <Formik
      initialValues={{ 
        name: option?.name,
        type: option?.type,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => createNewOption(values, actions)}
    >

      {props => (
      <>

        {optionUpdate.error && <p className="form-error-message">{optionUpdate.error}</p>}

        {/* Form Inputs */}

        <>
          <FormInput
              formik={props}
              fieldName={'name'}
              label={'Nombre'}
          />

          {group === 'associates' &&
          <SelectInput
              formik={props}
              fieldName={'type'}
              label={'Tipo asociado'}
              placeholder={'Seleccione'}
              options={ASSOCIATE_TYPE_FORMATTED}
          />}
        
          <div className='form-actions'>

            <Button
              action={() => setShowUpdateOptionModal(false)}
              label={'Cancelar'}
              disabled={optionUpdate?.isLoading}
              secondaryStyle={true}
            />

            <Button
              action={props.handleSubmit}
              label={'Editar'}
              disabled={optionUpdate?.isLoading}
              isLoading={optionUpdate?.isLoading}
            />

          </div>
        </>

      </>)}

    </Formik>
  )
}

// Form initial values and validaiton schemas

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Este campo es requerido'),
  type: yup
    .string()
});