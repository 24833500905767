import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss';

export default function AddUserButton({label, action}) {
  return (
    <button onClick={() => action()} className='add-user-button'>
        <FontAwesomeIcon icon={faCirclePlus} />
        <p>{label}</p>
    </button>
  )
}
