// API
import BusinessService from '../../services/business.service';

import { 
  setGetBusinessLoading, setGetBusinessScuccess, setGetBusinessFailure,
  setGetBusinessDetailLoading, setGetBusinessDetailScuccess, setGetBusinessDetailFailure,
  setGetCompaniesLoading, setGetCompaniesScuccess, setGetCompaniesFailure,
  setCreateBusinessLoading, setCreateBusinessScuccess, setCreateBusinessFailure, cleanCreateBusinessStore,
  setUpdateBusinessLoading, setUpdateBusinessScuccess, setUpdateBusinessFailure, cleanUpdateBusinessStore,
  setDeleteBusinessLoading, setDeleteBusinessScuccess, setDeleteBusinessFailure, cleanDeleteBusinessStore,
  setGetBusinessUsersLoading, setGetBusinessUsersScuccess, setGetBusinessUsersFailure,
  setUpdateAClientUserWalletLoading, setUpdateAClientUserWalletScuccess, setUpdateAClientUserWalletFailure, cleanUpdateAClientUserWalletStore,
 } from './business.reducers';



// Get Business With Pagination ====================================================================================================

/**
 * @param page optional page number
 * @description Gets the list of the business with pagination
*/

 export const getBusiness = (page) => async (dispatch) => {
  dispatch(setGetBusinessLoading());

  try {

    const response = await BusinessService.getBusiness(page);

    dispatch(setGetBusinessScuccess(response.data));

  } catch (error) {
    dispatch(setGetBusinessFailure(error.response?.data?.message || error.message));
  }

}

// Get a Business Detail ====================================================================================================

/**
 * @param id business id
 * @description Gets the detail data of a bussines
 */

export const getBusinessDetail = (id) => async (dispatch) => {
  dispatch(setGetBusinessDetailLoading());

  try {

    const response = await BusinessService.getBusinessDetail(id);

    dispatch(setGetBusinessDetailScuccess(response.data));

  } catch (error) {
    dispatch(setGetBusinessDetailFailure(error.response?.data?.message || error.message));
  }

}


// Get Companies With Pagination ====================================================================================================

/**
 * @param page optional page number
 * @description Gets the list of the companies with pagination
*/

export const getCompanies = (page) => async (dispatch) => {
  dispatch(setGetCompaniesLoading());

  try {

    const response = await BusinessService.getCompanies(page);

    dispatch(setGetCompaniesScuccess(response.data));

  } catch (error) {
    dispatch(setGetCompaniesFailure(error.response?.data?.message || error.message));
  }

}

// Create a Business ====================================================================================================

export const createBusiness = (data) => async (dispatch) => {
  dispatch(setCreateBusinessLoading());

  try {

    const response = await BusinessService.createBusiness(data);

    dispatch(setCreateBusinessScuccess(response.data?.data));

  } catch (error) {
    dispatch(setCreateBusinessFailure(error.response?.data?.message || error.message));
  }

}

// Clean Create a Business Store ====================================================================================================

export const cleanCreateBusiness = () => async (dispatch) => {
  dispatch(cleanCreateBusinessStore())
}


// Update a Business ====================================================================================================

export const updateBusiness = (id, data) => async (dispatch) => {
  dispatch(setUpdateBusinessLoading());

  try {

    const response = await BusinessService.updateBusiness(id, data);

    dispatch(setUpdateBusinessScuccess(response.data?.data));

  } catch (error) {
    dispatch(setUpdateBusinessFailure(error.response?.data?.message || error.message));
  }

}


// Clean Update a Business Store ====================================================================================================

export const cleanUpdateBusiness = () => async (dispatch) => {
  dispatch(cleanUpdateBusinessStore())
}


// Update a Business ====================================================================================================

export const deleteBusiness = (id) => async (dispatch) => {
  dispatch(setDeleteBusinessLoading());

  try {

    const response = await BusinessService.deleteBusiness(id);

    dispatch(setDeleteBusinessScuccess(response.data?.data));

  } catch (error) {
    dispatch(setDeleteBusinessFailure(error.response?.data?.message || error.message));
  }

}

// Clean Delete a Business Store ====================================================================================================

export const cleanDeleteBusiness = () => async (dispatch) => {
  dispatch(cleanDeleteBusinessStore())
}



// Get Business Users ====================================================================================================

/**
 * @description Gets the list of business users
*/

export const getBusinessUsers = () => async (dispatch) => {
  dispatch(setGetBusinessUsersLoading());

  try {

    const response = await BusinessService.getBusinessUsers();

    dispatch(setGetBusinessUsersScuccess(response.data?.data));

  } catch (error) {
    dispatch(setGetBusinessUsersFailure(error.response?.data?.message || error.message));
  }

}

// Update Client user Wallet ====================================================================================================

export const updateClientUserWallet = (data) => async (dispatch) => {
  dispatch(setUpdateAClientUserWalletLoading());

  try {

    const response = await BusinessService.updateClientUserWallet(data);

    dispatch(setUpdateAClientUserWalletScuccess(response.data?.data));

  } catch (error) {
    dispatch(setUpdateAClientUserWalletFailure(error.response?.data?.message || error.message));
  }

}

// Clean Update Client user Wallet Store ====================================================================================================

export const cleanUpdateClientUserWallet = () => async (dispatch) => {
  dispatch(cleanUpdateAClientUserWalletStore())
}
