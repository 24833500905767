import { createSlice } from '@reduxjs/toolkit'

// Setup the initial state

const initialState = {
  customers: {},
  customersAll: {},
  createCustomer: {},
  updateCustomer: {},
  deleteCustomer: {},
  customerDetail: {}
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {

    // Get Customers

    setGetCustomersLoading: (state) => {
      state.customers = {
        isLoading: true,
      };
    },
    setGetCustomersScuccess: (state, action) => {
      state.customers = {
        data: action.payload
      };
    },
    setGetCustomersFailure: (state, action) => {
      state.customers = {
        error: action.payload,
      }
    },

    // Get All Customers

    setGetAllCustomersLoading: (state) => {
      state.customersAll = {
        isLoading: true,
      };
    },
    setGetAllCustomersScuccess: (state, action) => {
      state.customersAll = {
        data: action.payload
      };
    },
    setGetAllCustomersFailure: (state, action) => {
      state.customersAll = {
        error: action.payload,
      }
    },

    // Create a Customer

    setCreateCustomerLoading: (state) => {
      state.createCustomer = {
        isLoading: true,
      };
    },
    setCreateCustomerScuccess: (state, action) => {
      state.createCustomer = {
        isCreated: true,
        data: action.payload
      };
    },
    setCreateCustomerFailure: (state, action) => {
      state.createCustomer = {
        error: action.payload,
      }
    },
    createCustomerCleanup: (state) => {
      state.createCustomer = {}
    },

    // Update a Customer

    setUpdateCustomerLoading: (state) => {
      state.updateCustomer = {
        isLoading: true,
      };
    },
    setUpdateCustomerScuccess: (state) => {
      state.updateCustomer = {
        isUpdated: true
      };
    },
    setUpdateCustomerFailure: (state, action) => {
      state.updateCustomer = {
        error: action.payload,
      }
    },
    updateCustomerCleanup: (state) => {
      state.updateCustomer = {}
    },

    // Delete a Customer

    setDeleteCustomerLoading: (state) => {
      state.deleteCustomer = {
        isLoading: true,
      };
    },
    setDeleteCustomerScuccess: (state) => {
      state.deleteCustomer = {
        isDeleted: true
      };
    },
    setDeleteCustomerFailure: (state, action) => {
      state.deleteCustomer = {
        error: action.payload,
      }
    },
    deleteCustomerCleanup: (state) => {
      state.deleteCustomer = {}
    },

    //  Get Customer Details

    setGetCustomerDetailLoading: (state) => {
      state.customerDetail = {
        isLoading: true,
      };
    },
    setGetCustomerDetailScuccess: (state, action) => {
      state.customerDetail = {
        data: action.payload
      };
    },
    setGetCustomerDetailFailure: (state, action) => {
      state.customerDetail = {
        error: action.payload,
      }
    },
  },
})

export const { 
  setGetCustomersLoading, setGetCustomersScuccess, setGetCustomersFailure,
  setGetAllCustomersLoading, setGetAllCustomersScuccess, setGetAllCustomersFailure,
  setCreateCustomerLoading, setCreateCustomerScuccess, setCreateCustomerFailure, createCustomerCleanup,
  setUpdateCustomerLoading, setUpdateCustomerScuccess, setUpdateCustomerFailure, updateCustomerCleanup,
  setDeleteCustomerLoading, setDeleteCustomerScuccess, setDeleteCustomerFailure, deleteCustomerCleanup,
  setGetCustomerDetailLoading, setGetCustomerDetailScuccess, setGetCustomerDetailFailure
} = customersSlice.actions

export default customersSlice.reducer;