import React, { useEffect, useState } from 'react'

import {
    useParams
} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'

import { getSingleCustomerWithUsers } from '../../store/users/users.actions';

// Components

import AddUserButton from '../../components/UI/AddUserButton/AddUserButton';
import Modal from '../../components/UI/Modal/Modal';
import AddUserForm from '../../components/forms/AddUserForm/AddUserForm';
import EditUserForm from '../../components/forms/EditUserForm/EditUserForm';
import Loader from '../../components/UI/Loader/Loader';
import NoEntriesMessage from '../../components/UI/NoEntriesMessage/NoEntriesMessage';
import SectionHeader from '../../components/UI/SectionHeader/SectionHeader';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import UserCard from '../../components/cards/UserCard/UserCard';
import ClientCardSimple from '../../components/cards/ClientCardSimple/ClientCardSimple';
import Alert from '../../components/UI/Alert/Alert';
import EditClientPortfolioForm from '../../components/forms/EditClientPortfolioForm/EditClientPortfolioForm';
import TransferClientPortfolioForm from '../../components/forms/TransferClientPortfolioForm/TransferClientPortfolioForm';
import EditClientUserPortfolioForm from '../../components/forms/EditClientUserPortfolioForm/EditClientUserPortfolioForm';

import './Styles.scss';

export default function ClientUsers() {
    const { id } = useParams();
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [showEditClientPortfolioModal, setShowEditClientPortfolioModal] = useState(false);
    const [showEditClientUserPortfolioModal, setShowEditClientUserPortfolioModal] = useState(false);
    const [userToEdit, setUserToEdit] = useState();
    const [alertMessage, setAlertMessage] = useState(null);
    const [switchClientPortfolioForms, setSwitchClientPortfolioForms] = useState(true);
    const singleCustomerUsers = useSelector((state) => state.users.singleCustomerUsers);
    const userUpdate = useSelector((state) => state.users.updateUser);
    const userCreate = useSelector((state) => state.users.createUser);
    const transferWallet = useSelector((state) => state.users.transferWallet);
    const updateAClientUserWallet = useSelector((state) => state.business.updateAClientUserWallet);
    const user = useSelector((state) => state.auth.user);
    const userPermissions = user?.data?.permissionsData;

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getSingleCustomerWithUsers(id))
    //   dispatch(getAllCustomers());
    }, [dispatch, id])


    // Show alert message when an user is updated

    useEffect(() => {
        if (userUpdate.isUpdated || transferWallet.isTransfered || updateAClientUserWallet.isUpdated) {
            showAlertMessage('Usuario editado exitosamente.')
            dispatch(getSingleCustomerWithUsers(id))
        }
    }, [dispatch, id, userUpdate, transferWallet, updateAClientUserWallet])

    // Show alert message when an user is created

    useEffect(() => {
        if (userCreate.isCreated) {
            showAlertMessage('Usuario añadido exitosamente.')
            dispatch(getSingleCustomerWithUsers(id))
        }
    }, [userCreate])


    // Reset Switch Client Porfolio Form Tabs

    useEffect(() => {
        setSwitchClientPortfolioForms(true);
    }, [showEditClientPortfolioModal])


    // Open Edit User Modal

    const openEditUserModal = (client) => {
        setUserToEdit(client);
        setShowEditUserModal(true)
    }

    // Open Edit User Client Portfolio Modal

    const openEditClientPortfolioModal = (client) => {
        setUserToEdit(client);
        setShowEditClientPortfolioModal(true)
    }

    // Open Edit User Client User Portfolio Modal

    const openEditClientUserPortfolioModal = (client) => {
        setUserToEdit(client);
        setShowEditClientUserPortfolioModal(true)
    }
    
    // Show alert message

    const showAlertMessage = (message) => {
        setAlertMessage(message)
        setTimeout(() => {
            setAlertMessage(null)
        }, 4000);
    }

    return (
        <div className='dashboard-wrapper'>

            <PageWrapper>

                <SectionHeader title={'Listado de usuarios'}>
                    {(userPermissions.CrearYModificarUsuarioVallgesGroup || userPermissions.CrearYModificarUsuarioAdministradorDelCliente || userPermissions.CrearUsuarioAdministradorDelCliente || userPermissions.CrearYModificarUsuarioCliente) &&
                    <AddUserButton label={'Añadir usuario'} action={() => setShowAddUserModal(true)} />}
                </SectionHeader>

                {!singleCustomerUsers?.isLoading && singleCustomerUsers?.data &&
                <ClientCardSimple data={singleCustomerUsers?.data} />}

                {alertMessage &&
                <Alert message={alertMessage} />}

                {(singleCustomerUsers?.isLoading || singleCustomerUsers.error) &&
                <Loader
                    message={'Cargando usuarios...'}
                    error={singleCustomerUsers.error}
                    action={() => dispatch(getSingleCustomerWithUsers(id))}
                />}

                {!singleCustomerUsers?.isLoading && singleCustomerUsers?.data &&
                <div className="container">

                    <SectionHeader
                        title={'Listado de usuarios'}
                        isSubsection={true}
                    />
                    
                    {singleCustomerUsers?.data?.users.map((entry, i) => (
                        <div key={i} className="">
                            <UserCard
                                entry={entry}
                                clientId={singleCustomerUsers?.data?.id}
                                hasGroup={(singleCustomerUsers?.data.bussiness_group_id !== null && singleCustomerUsers?.data.bussiness_group_id !== "0")}
                                openEditUserModal={openEditUserModal}
                                openEditClientPortfolioModal={openEditClientPortfolioModal}
                                openEditClientUserPortfolioModal={openEditClientUserPortfolioModal}
                            />
                        </div>
                    ))}
                </div>}

                {!singleCustomerUsers?.isLoading && singleCustomerUsers?.data?.users && singleCustomerUsers?.data?.users?.length === 0 &&
                    <NoEntriesMessage message={'No se encontraron usuarios para esta busqueda'} />
                }

            </PageWrapper>


            {/* Add User Modal */}

            <Modal show={showAddUserModal} title={'Añadir Usuario'}>
                <AddUserForm
                    setShowAddUserModal={setShowAddUserModal}
                />
            </Modal>


            {/* Edit User Modal */}

            <Modal show={showEditUserModal} title={'Editar Usuario'}>
                <EditUserForm
                    userData={userToEdit}
                    setShowEditUserModal={setShowEditUserModal}
                />
            </Modal>


            {/* Edit User Client Portfolio Modal */}

            <Modal show={showEditClientPortfolioModal} title={switchClientPortfolioForms ? 'Editar Cartera de Clientes' : 'Transferir Cartera de Clientes'}>
                <div className='switch-modal-forms-tabs'>
                    <button
                        className={switchClientPortfolioForms ? 'switch-modal-forms-tab switch-modal-forms-tab-active' : 'switch-modal-forms-tab'}
                        onClick={() => setSwitchClientPortfolioForms(!switchClientPortfolioForms)}
                    >
                        Editar Cartera
                    </button>
                    <button
                        className={!switchClientPortfolioForms ? 'switch-modal-forms-tab switch-modal-forms-tab-active' : 'switch-modal-forms-tab'}
                        onClick={() => setSwitchClientPortfolioForms(!switchClientPortfolioForms)}
                    >
                        Transferir Cartera
                    </button>
                </div>

                {switchClientPortfolioForms ? (
                    <EditClientPortfolioForm
                        user={userToEdit}
                        setShowEditClientPortfolioModal={setShowEditClientPortfolioModal}
                    />
                ) : (
                    <TransferClientPortfolioForm
                        user={userToEdit}
                        usersData={singleCustomerUsers?.data?.users}
                        setShowEditClientPortfolioModal={setShowEditClientPortfolioModal}
                    />
                )}
            </Modal>


            {/* Edit Client User Portfolio Modal */}

            <Modal show={showEditClientUserPortfolioModal} title={'Editar Cartera de Clientes'}>
                <EditClientUserPortfolioForm
                    user={userToEdit}
                    setShowEditClientUserPortfolioModal={setShowEditClientUserPortfolioModal}
                />
            </Modal>

        </div>
  )
}
