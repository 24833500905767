
// Turn Form Values To Form Data Format

export const setupFormData = (values) => {
    let formData = new FormData();
    
    Object.keys(values).forEach(key => {
        formData.append(key, values[key]);
    });

    return formData;
};


// Truncate a string to a given lenght

export const truncateString = (str, n) => {
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
};


// Format number to currency

export const formatCurrency = (value) => {
    return '$' + new Intl.NumberFormat().format(value);
}


// Set Nested Field Name

export const setNestedFieldName = (fieldName, formValues) => {
    return fieldName.split('.').reduce((o,i)=> o[i], formValues);
}


// Create object with the user's permissions

export const createUserPermissionsData = (permissions) => {
    const permissionsData = {};

    permissions.forEach(item => {
        const name = item?.name;
        if (name) {
            permissionsData[name] = true;
        }
    });

    return permissionsData;
}


// Get the max date for the date input field on the upload balance form

export const getMaxDate = () => {
    var dt = new Date();
    return dt.setMonth(dt.getMonth()-1);
}


// Format Roles Options Data

export const formatRolesOptionsData = (data, permissions) => {
    const roleOptions = data?.map(option => ({ value: option.id, label: option.name }));
    let roles = [];

    if (permissions?.CrearYModificarUsuarioVallgesGroup) {
        const role1 = roleOptions?.find(item => item.value === 1);
        const role2 = roleOptions?.find(item => item.value === 2);

        roles?.push(role1);
        roles?.push(role2);
    }

    if (permissions?.CrearYModificarUsuarioAdministradorDelCliente || permissions?.CrearUsuarioAdministradorDelCliente) {
        const role = roleOptions?.find(item => item.value === 3);

        roles?.push(role);
    }

    if (permissions?.CrearYModificarUsuarioCliente) {
        const role = roleOptions?.find(item => item.value === 4);

        roles?.push(role);
    }

    return roles;
}


// Format Permission Options Data

export const formatPermissionsOptionsData = (data, role) => {
    const rolePermissions = data.find(item => item.id === role)?.permissions;

    if (rolePermissions?.length > 0) {
        return rolePermissions?.map(option => ({ value: option.name, label: option.description }));
    } else {
        return [];
    }
}


// Set Up User Permission Array

export const setUpUserPermissionsArray = (permissions) => {
    let permissionsData = [];
    
    permissions.forEach(item => {
        permissionsData.push(item?.name);
    });
    
    return permissionsData;
}

// Set Up Permissions Names Array

export const setupPermissionsNamesArray = (permissions) => {
    let permissionsData = [];
    
    Object.keys(permissions).forEach(key => {
        permissionsData.push(key);
    });

    return permissionsData;
};


// Set Up Change Log Values

export const setupChangeLogValues = (values) => {
    let data = [];
    
    Object.keys(values).forEach(key => {
        if (key !== 'updated_at' && key !== 'created_at' && key !== 'password') {
            const entry = `${key}: ${values[key]}`;
            data.push(entry);
        }
    });

    return data;
};
