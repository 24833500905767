import React, { useEffect, useState } from 'react'

import {
    useParams
} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';

import { getCustomerDetails } from '../../store/customers/customers.actions';
import { getBalanceCalculation, calculateBalanceFlow, cleanUpCalculateBalanceFlow } from '../../store/balances/balances.actions';

// Components

import SectionHeader from '../../components/UI/SectionHeader/SectionHeader';
import ButtonSmall from '../../components/UI/ButtonSmall/ButtonSmall';
import ClientBalanceSheet from '../../components/cards/ClientBalanceSheet/ClientBalanceSheet';
import ClientAccountsList from '../../components/lists/ClientAccountsList/ClientAccountsList';
import ClientSelectHeading from '../../components/UI/ClientSelectHeading/ClientSelectHeading';
import Search from '../../components/forms/Search/Search';
import Modal from '../../components/UI/Modal/Modal';
import Loader from '../../components/UI/Loader/Loader';
import EditBalanceAccountForm from '../../components/forms/EditBalanceAccountForm/EditBalanceAccountForm';
import ClassifyBalanceAccountsForm from '../../components/forms/ClassifyBalanceAccountsForm/ClassifyBalanceAccountsForm';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import UploadBalanceForm from '../../components/forms/UploadBalanceForm/UploadBalanceForm';
import Alert from '../../components/UI/Alert/Alert';
import ClientAccountsListSimple from '../../components/lists/ClientAccountsListSimple/ClientAccountsListSimple';
import UploadRecordsButton from '../../components/UI/UploadRecordsButton/UploadRecordsButton';
import DashboardButton from '../../components/UI/DashboardButton/DashboardButton';
import ToggleAccountIssuesButton from '../../components/UI/ToggleAccountIssuesButton/ToggleAccountIssuesButton';
import ExportClientBalance from '../../components/forms/ExportClientBalance/ExportClientBalance';

// Constants

import { BALANCE_OPTIONS } from '../../config/constants';

const balanceTemplate = [
    require('../../assets/d/template-carga-recurrente-con-y-sin-naturaleza.xlsx'),
    require('../../assets/d/template-carga-recurrente-con-y-sin-naturaleza.xlsx'),
    require('../../assets/d/template-carga-recurrente-sin-naturaleza-sin-saldo.xlsx')
]

const balanceInitialTemplate = require('../../assets/d/template-carga-inicial.xlsx');

export default function ClientDetail() {
    const { id } = useParams();
    const [filteredData, setFilteredData] = useState([]);
    const [accountsWithIssues, setAccountsWithIssues] = useState([]);
    const [showAccountsWithIssues, setShowAccountsWithIssues] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(0);
    const [accountToEdit, setAccountToEdit] = useState();
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertMessageError, setAlertMessageError] = useState(null);
    const [longestBalancesArray, setLongestBalancesArray] = useState({});
    const [showUploadBalanceModal, setShowUploadBalanceModal] = useState(false);
    const [showEditBalanceAccountModal, setShowEditBalanceAccountModal] = useState(false);
    const [showExportClientBalancetModal, setShowExportClientBalancetModal] = useState(false);
    const [showClassifyBalanceAccountsModal, setClassifyBalanceAccountsModal] = useState(false);
 
    const user = useSelector((state) => state.auth.user);
    const customer = useSelector((state) => state.customers.customerDetail);
    const balanceCalculation = useSelector((state) => state.balances.balanceCalculation);
    const balanceUpload = useSelector((state) => state.balances.uploadBalanceData);
    const balanceAccountEdit = useSelector((state) => state.balances.balanceAccountEdit);
    const bulkBalanceAccountEdit = useSelector((state) => state.balances.bulkBalanceAccountEdit);
    const calculateBalance = useSelector((state) => state.balances.calculateBalanceFlow);
    const userPermissions = user?.data?.permissionsData;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBalanceCalculation(id));
        dispatch(getCustomerDetails(id));
    }, [dispatch, id])

    useEffect(() => {
        if (balanceAccountEdit.isEdited || bulkBalanceAccountEdit.isEdited) {
            dispatch(getCustomerDetails(id));
        }
    }, [dispatch, id, balanceAccountEdit, bulkBalanceAccountEdit])

    // Show alert message when the balance data is uploaded

    useEffect(() => {
        if (balanceUpload.isUploaded) {
            showAlertMessage('Data de balanza subida exitosamente.')
            dispatch(getBalanceCalculation(id));
            dispatch(getCustomerDetails(id));
        }
    }, [dispatch, id, balanceUpload])

    // Set Filtered Data On Load

    useEffect(() => {
        if (customer?.data) {
            setFilteredData(customer?.data?.check_balances);
            setAccountsWithIssues(customer?.data?.check_balances?.filter(item => item.logs?.length > 0));
        }
    }, [dispatch, customer])

    // Show alert message

    const showAlertMessage = (message) => {
        setAlertMessage(message)
        setTimeout(() => {
            setAlertMessage(null)
        }, 4000);
    }

    const showAlertMessageError = (message) => {
        setAlertMessageError(message)
        setTimeout(() => {
            setAlertMessageError(null)
        }, 4000);
    }

    // Open Edit Client Account Modal

    const openEditClienAccounttModal = (account) => {
        setAccountToEdit(account);
        setShowEditBalanceAccountModal(true)
    }

    // Search Accounts

    const searchAcounts = (value) => {
        if (value !== '') {
            const data = filteredData.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(value.toLowerCase())
            })
            setFilteredData(data)
        } else {
            setFilteredData(customer?.data?.check_balances)
            setShowAccountsWithIssues(false);
        }
    }

    // Toggle Show Accounts With Issues

    const filterAcountsWithIssues = () => {
        if (showAccountsWithIssues) {
            setFilteredData(customer?.data?.check_balances);
            setShowAccountsWithIssues(false);
        } else {
            const data = filteredData.filter(item => item.logs.length > 0);
            setFilteredData(data)
            setShowAccountsWithIssues(true);
        }
    }

    // Calculate Balance Flow

    const handleCalculateBalanceFlow = () => {
        const data = {
            customer_id: customer?.data.id,
            month: longestBalancesArray?.balances[currentMonth]?.title
        };
        dispatch(calculateBalanceFlow(data));
    }

    useEffect(() => {
        if (calculateBalance.isCalculated) {
            dispatch(getCustomerDetails(id));
            showAlertMessage(calculateBalance.data);
            dispatch(cleanUpCalculateBalanceFlow());
        } else if (calculateBalance.error) {
            showAlertMessageError(calculateBalance.error);
            dispatch(cleanUpCalculateBalanceFlow());
        }
    }, [dispatch, id, calculateBalance])

    return (
        <div className='dashboard-wrapper'>

            <PageWrapper>
                
                {customer?.data &&
                <SectionHeader hideHeading={true}>
                    <ClientSelectHeading
                        data={customer?.data}
                    />

                    {(userPermissions.VisualizarTodosLasInformacionesClientes || userPermissions.VisualizarTodasLasInformacionesDelClienteMenosElId) &&
                    <p>{BALANCE_OPTIONS[customer?.data?.balance_type - 1]?.label}</p>}

                    {userPermissions.CalcularFlujosDeCajas && !customer?.isLoading && customer?.data && customer?.data?.check_balances?.length > 0 &&
                    <ButtonSmall
                        label={'Calcular Flujo de Caja'}
                        icon={'calculate'}
                        isLoading={calculateBalance?.isLoading}
                        disabled={calculateBalance?.isLoading}
                        secondaryStyle={true}
                        action={() => handleCalculateBalanceFlow()}
                    />}

                    {(userPermissions.ExportarBalanzasComprobacion || userPermissions.ExportarFlujosDeCajas || userPermissions.ExportarBalanzasComprobacionYFlujosDeCajas) && !customer?.isLoading && customer?.data && customer?.data?.check_balances?.length > 0 &&
                    <ButtonSmall
                        label={'Exportar Balanza'}
                        icon={'export'}
                        action={() => setShowExportClientBalancetModal(true)}
                    />}
                </SectionHeader>}

                {(customer?.isLoading || customer.error) &&
                <Loader 
                    message={'Cargando datos...'}
                    error={customer.error}
                    action={getCustomerDetails(1)}
                />}


                {/* Client with no Balance Data */}

                {!customer?.isLoading && customer?.data && customer?.data?.check_balances?.length === 0 &&
                <>
                    <div className='row'>
                        <div className='col'>
                            <UploadRecordsButton action={() => setShowUploadBalanceModal(true)} />
                        </div>
                        <div className='col'>
                            <DashboardButton
                                title='Cambiar contraseña'
                                description='Cambio de conraseña de su usuario'
                                icon='profile'
                                action={null}
                            />
                            <DashboardButton
                                title='Descargar template de carga'
                                description='Formato de ejemplo para subir balanza'
                                icon='download'
                                isFile={true}
                                fileUrl={balanceTemplate[customer?.data?.balance_type - 1]}
                            />
                            {/* {user.data?.role === 1 &&
                            <DashboardButton
                                title='Descarge el template de carga inicial'
                                description='Formato de ejemplo para subir carga inicial de balanza'
                                icon='download'
                                isFile={true}
                                fileUrl={balanceInitialTemplate}
                            />} */}
                        </div>
                    </div>
                </>}


                {/* Client with Balance Data */}

                {!customer?.isLoading && customer?.data && customer?.data?.check_balances?.length > 0 &&
                <>

                    {userPermissions.VisualizarIndicadoresFinancieros &&
                    <ClientBalanceSheet
                        customer={customer?.data}
                        data={balanceCalculation?.data}
                    />}

                    {(alertMessage || alertMessageError) &&
                    <Alert message={alertMessage || alertMessageError} isError={alertMessageError} />}

                    <>
                        <SectionHeader
                            title={'Detalle de cuentas'}
                            isSubsection={true}
                        >

                            {accountsWithIssues?.length > 0 &&
                            <ToggleAccountIssuesButton
                                showingAccountsWithIssues={showAccountsWithIssues}
                                numberofIssues={accountsWithIssues?.length}
                                action={() => filterAcountsWithIssues()}
                            />}

                            <Search onChange={searchAcounts} />

                            {userPermissions.VisualizarYModificarClasificacionesDeCategoriasCampos &&
                            <ButtonSmall
                                label={'Clasificar cuentas'}
                                secondaryStyle={true}
                                action={() => setClassifyBalanceAccountsModal(true)}
                            />}

                            {userPermissions.SubirArchivos &&
                            <ButtonSmall
                                label={'Subir archivo'}
                                icon={'upload'}
                                action={() => setShowUploadBalanceModal(true)}
                            />}

                        </SectionHeader>

                        {(user.data?.role !== 3 && user.data?.role !== 8) ? (
                            <ClientAccountsList
                                data={filteredData}
                                reconciliation={customer?.data?.reconciliation}
                                currentMonth={currentMonth}
                                setCurrentMonth={setCurrentMonth}
                                longestBalancesArray={longestBalancesArray}
                                setLongestBalancesArray={setLongestBalancesArray}
                                openEditClienAccounttModal={openEditClienAccounttModal}
                            />
                        ) : (
                            <ClientAccountsListSimple
                                data={filteredData}
                                reconciliation={customer?.data?.reconciliation}
                                currentMonth={currentMonth}
                                setCurrentMonth={setCurrentMonth}
                                longestBalancesArray={longestBalancesArray}
                                setLongestBalancesArray={setLongestBalancesArray}
                                openEditClienAccounttModal={openEditClienAccounttModal}
                            />
                        )}

                    </>

                </>}

            </PageWrapper>


            {/* Upload Balance Modal */}
            
            <Modal show={showUploadBalanceModal} title={'Subir Balance'}>
                <UploadBalanceForm
                    clientId={customer?.data?.id}
                    balanceTemplateUrl={balanceTemplate[customer?.data?.balance_type - 1]}
                    balanceInitialTemplateUrl={balanceInitialTemplate}
                    setShowUploadBalanceModal={setShowUploadBalanceModal}
                    longestBalancesArray={longestBalancesArray}
                    hasData={customer?.data?.check_balances?.filter(item => item.balances?.length > 0)?.length > 0}
                />
            </Modal>


            {/* Edit Balance Account Modal */}

            <Modal show={showEditBalanceAccountModal} title={'Editar Cuenta'}>
                <EditBalanceAccountForm
                    account={accountToEdit}
                    currentMonth={currentMonth}
                    setShowEditBalanceAccountModal={setShowEditBalanceAccountModal}
                />
            </Modal>


            {/* Classify Balance Accounts Modal */}

            <Modal show={showClassifyBalanceAccountsModal} title={'Clasificar Cuentas'}>
                <ClassifyBalanceAccountsForm
                    clientId={customer?.data?.id}
                    accounts={customer?.data?.check_balances}
                    setClassifyBalanceAccountsModal={setClassifyBalanceAccountsModal}
                />
            </Modal>


            {/* Export Client Balance Modal */}

            <Modal show={showExportClientBalancetModal} title={'Exportar Balanza'}>
                <ExportClientBalance
                    clientId={customer?.data?.id}
                    customerName={customer?.data?.name}
                    longestBalancesArray={longestBalancesArray}
                    setShowExportClientBalancetModal={setShowExportClientBalancetModal}
                />
            </Modal>

        </div>
  )
}