import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { getCustomers, searchCustomers } from '../../store/customers/customers.actions';

// Components

import ClientCard from '../../components/cards/ClientCard/ClientCard';
import Modal from '../../components/UI/Modal/Modal';
import AddClientForm from '../../components/forms/AddClientForm/AddClientForm';
import EditClientForm from '../../components/forms/EditClientForm/EditClientForm';
import Loader from '../../components/UI/Loader/Loader';
import Pagination from '../../components/UI/Pagination/Pagination';
import Search from '../../components/forms/Search/Search';
import NoEntriesMessage from '../../components/UI/NoEntriesMessage/NoEntriesMessage';
import SectionHeader from '../../components/UI/SectionHeader/SectionHeader';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import Alert from '../../components/UI/Alert/Alert';
import ClientListToggleButtons from '../../components/UI/ClientListToggleButtons/ClientListToggleButtons';
import AddUserButton from '../../components/UI/AddUserButton/AddUserButton';

export default function Dashboard() {
    const [showAddClientModal, setShowAddClientModal] = useState(false);
    const [showEditClientModal, setShowEditClientModal] = useState(false);
    const [clientToEdit, setClientToEdit] = useState();
    const [alertMessage, setAlertMessage] = useState(null);
    const user = useSelector((state) => state.auth.user);
    const userPermissions = user?.data?.permissionsData;
    const customers = useSelector((state) => state.customers.customers);
    const customerUpdate = useSelector((state) => state.customers.updateCustomer);
    const customerCreate = useSelector((state) => state.customers.createCustomer);
    const clientListStyle = useSelector((state) => state.settings.clientListStyle);
    const dispatch = useDispatch();

    // Get all the customers on load

    useEffect(() => {
      dispatch(getCustomers());
    }, [dispatch])


    // Show alert message when the customer is created or updated

    useEffect(() => {
        if (customerCreate.isCreated) {
            const message = `Cliente añadido exitosamente. ${customerCreate?.data?.email_exists ? ' Este correo esta asociado a otros clientes.' : ''}`;
            showAlertMessage(message)
        } else if (customerUpdate.isUpdated) {
            showAlertMessage('Cliente editado exitosamente.')
        }
    }, [customerCreate, customerUpdate])


    // Open Edit Client Modal

    const openEditClientModal = (client) => {
        setClientToEdit(client);
        setShowEditClientModal(true)
    }


    // Show alert message

    const showAlertMessage = (message) => {
        setAlertMessage(message)
        setTimeout(() => {
            setAlertMessage(null)
        }, 4000);
    }

    return (
        <div className='dashboard-wrapper'>

            <PageWrapper>
                <SectionHeader title={'Listado de clientes'}>

                    {userPermissions.CrearClientes &&
                    <AddUserButton label={'Anadir cliente'} action={() => setShowAddClientModal(true)} />}

                    <Search
                        action={searchCustomers}
                    />
                
                    <Pagination
                        action={getCustomers}
                        pagination={customers?.data}
                    />

                    <ClientListToggleButtons />
                </SectionHeader>

                {alertMessage &&
                <Alert message={alertMessage} />}

                {(customers?.isLoading || customers.error) &&
                <Loader
                    message={'Cargando clientes...'}
                    error={customers.error}
                    action={() => dispatch(getCustomers())}
                />}

                {!customers?.isLoading && customers?.data?.data && customers?.data?.data?.length > 0 &&
                <div className="container">
                    <div className="row">

                        {customers?.data?.data.map((entry, i) => (
                            <div key={i} className={clientListStyle ? "" : "col-md-4"}>
                                <ClientCard
                                    entry={entry}
                                    listStyle={clientListStyle}
                                    openEditClientModal={openEditClientModal}
                                />
                            </div>
                        ))}

                    </div>
                </div>}

                {!customers?.isLoading && customers?.data?.data && customers?.data?.data?.length === 0 &&
                    <NoEntriesMessage message={'No se encontraron clientes para esta busqueda'} />
                }

            </PageWrapper>


            {/* Add Client Modal */}

            <Modal show={showAddClientModal} title={'Añadir Cliente'}>
                <AddClientForm
                    setShowAddClientModal={setShowAddClientModal}
                />
            </Modal>


            {/* Edit Client Modal */}

            <Modal show={showEditClientModal} title={'Editar Cliente'}>
                <EditClientForm
                    client={clientToEdit}
                    setShowEditClientModal={setShowEditClientModal}
                />
            </Modal>

        </div>
  )
}
