import React from 'react'

import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';

import Moment from 'moment';
import 'moment/locale/es';

import './Styles.scss';

export default function BusinessGroupCard({entry, listStyle, openEditBusinessGroupModal}) {
    const user = useSelector((state) => state.auth.user);
    const userPermissions = user?.data?.permissionsData;
  return (
    listStyle ? (
        <ListCard
            entry={entry}
            openEditBusinessGroupModal={openEditBusinessGroupModal}
            userPermissions={userPermissions}
            user={user}
        />
    ) : (
        <GridCard
            entry={entry}
            openEditBusinessGroupModal={openEditBusinessGroupModal}
            userPermissions={userPermissions}
            user={user}
        />
    )
  )
}

const ListCard = ({entry, openEditBusinessGroupModal, userPermissions, user}) => (
    <div className='client-card client-card-list-style'>
        <Link className='client-card-link' to={`/business-group-detail/${entry.id}`}>
            <div className='client-card-details'>
                <div className='client-card-name'>
                    <h2>{entry.name}</h2>
                </div>

                {(userPermissions.EditarDatosGeneralesClientes || userPermissions.EditarDatosFinancierosClientes) &&
                <button className='client-card-edit-button' onClick={(e) => {e.preventDefault(); openEditBusinessGroupModal(entry)}}><FontAwesomeIcon icon={faGear} /></button>}
            </div>
            <p>{entry?.user?.name} - {entry?.user?.email}</p>
            <div className='client-card-balance'>
                {entry?.updated_at ? (
                    <div className='client-card-balance-entry'>
                        <p><strong>Ultima actualización</strong></p>
                        <p>{Moment(entry?.updated_at).format('MMM yyyy')}</p>
                    </div>
                ) : (
                    <div className='client-card-balance-entry'>
                        <p><strong>Ultima actualización</strong></p>
                        <p>N/D</p>
                    </div>
                )}
            </div>
        </Link>
    </div>
);

const GridCard = ({entry, openEditBusinessGroupModal, userPermissions, user}) => (
    <div className='client-card'>
        <Link className='client-card-link' to={`/business-group-detail/${entry.id}`}>
            <div className='client-card-category'>
                <h2>{entry.name}</h2>

                {(userPermissions.EditarDatosGeneralesClientes || userPermissions.EditarDatosFinancierosClientes) &&
                <button className='client-card-edit-button' onClick={(e) => {e.preventDefault(); openEditBusinessGroupModal(entry)}}><FontAwesomeIcon icon={faGear} /></button>}
                
            </div>
            <p>{entry?.user?.name} - {entry?.user?.email}</p>
            <div className='client-card-balance'>
                {entry?.updated_at ? (
                    <div className='client-card-balance-entry'>
                        <p><strong>Ultima actualización</strong></p>
                        <p>{Moment(entry?.updated_at).format('MMM yyyy')}</p>
                    </div>
                ) : (
                    <div className='client-card-balance-entry'>
                        <p><strong>Ultima actualización</strong></p>
                        <p>N/D</p>
                    </div>
                )}
            </div>
        </Link>
    </div>
);