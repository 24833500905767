import React from 'react'

import { useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss';

export default function OptionsList({group, data, openUpdateOptionModal, deleteOption}) {
    const user = useSelector((state) => state.auth.user);

    return (
        <div className='table-list'>

            <table>
                <thead>
                    <tr className='table-list-headers'>
                        <th>Nombre</th>
                        {(user.data?.role === 1 && group === 'fiscal_risks') && <th></th>}
                    </tr>
                </thead>

                <tbody>

                    {data.map((entry, i) => (
                        <OptionEntry
                            key={i}
                            group={group}
                            entry={entry}
                            editAction={openUpdateOptionModal}
                            deleteAction={deleteOption}
                            user={user}
                        />
                    ))}

                </tbody>
                
            </table>

        </div>
    )
}

const OptionEntry = ({group, entry, editAction, deleteAction, user}) => {
    return (
        <tr className='table-entry'>
            <td className='option-name'>
                <p>
                    <strong>
                        {entry.name}
                    </strong>
                </p>
            </td>
            {(user.data?.role === 1 && group === 'fiscal_risks') &&
            <td className='table-action'>
                <p>
                    <button className='table-entry-button' onClick={() => editAction(entry)}>
                        <FontAwesomeIcon className='table-entry-button-icon' icon={faEdit} />
                    </button>

                    <button className='table-entry-button' onClick={() => deleteAction(entry.id)}>
                        <FontAwesomeIcon className='table-entry-button-icon' icon={faTrashCan} />
                    </button>
                </p>
            </td>}
        </tr>
    )  
}