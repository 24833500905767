import React from 'react'

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss';

export default function SectionButton({title, path}) {
  return (
    <Link to={path} className='section-button'>
        <h2>{title}</h2>
        <FontAwesomeIcon className='section-button-icon' icon={faUpRightFromSquare} />
    </Link>
  )
}
