import React from 'react'

import { useSelector } from 'react-redux';

import './Styles.scss';

export default function ClientSelectHeading({data}) {
  const user = useSelector((state) => state.auth.user);
  const userPermissions = user?.data?.permissionsData;

  return (
    <div className='client-select-heading'>

      {(userPermissions.VisualizarTodosLasInformacionesClientes || userPermissions.VisualizarTodasLasInformacionesDelClienteMenosElId) ? (
        <>
          <div className={data.logo ? 'client-select-heading-logo client-select-heading-logo-clear' : 'client-select-heading-logo'}>
            {data.logo ? <img src={data.logo} alt={data.name} /> : null}
          </div>

          <h1 className='section-header-no-margins'>{data.name}</h1>
        </>
      ) : (
        <h1 className='section-header-no-margins'>{data.id}</h1>
      )}

    </div>
  )
}
