import { createSlice } from '@reduxjs/toolkit'

// Setup the initial state

const initialState = {
  business: {},
  businessDetail: {},
  companies: {},
  createBusiness: {},
  updateBusiness: {},
  deleteBusiness: {},
  businessUsers: {},
  updateAClientUserWallet: {},
};

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {

    // Get Business

    setGetBusinessLoading: (state) => {
      state.business = {
        isLoading: true,
      };
    },
    setGetBusinessScuccess: (state, action) => {
      state.business = {
        data: action.payload
      };
    },
    setGetBusinessFailure: (state, action) => {
      state.business = {
        error: action.payload,
      }
    },

    // Get a Business detail

    setGetBusinessDetailLoading: (state) => {
      state.businessDetail = {
        isLoading: true,
      };
    },
    setGetBusinessDetailScuccess: (state, action) => {
      state.businessDetail = {
        data: action.payload
      };
    },
    setGetBusinessDetailFailure: (state, action) => {
      state.businessDetail = {
        error: action.payload,
      }
    },

    // Get Companies

    setGetCompaniesLoading: (state) => {
      state.companies = {
        isLoading: true,
      };
    },
    setGetCompaniesScuccess: (state, action) => {
      state.companies = {
        data: action.payload
      };
    },
    setGetCompaniesFailure: (state, action) => {
      state.companies = {
        error: action.payload,
      }
    },

    // Create a Business

    setCreateBusinessLoading: (state) => {
      state.createBusiness = {
        isLoading: true,
      };
    },
    setCreateBusinessScuccess: (state) => {
      state.createBusiness = {
        isCreated: true
      };
    },
    setCreateBusinessFailure: (state, action) => {
      state.createBusiness = {
        error: action.payload,
      }
    },

    cleanCreateBusinessStore: (state) => {
      state.createBusiness = {}
    },

    // Update a Business

    setUpdateBusinessLoading: (state) => {
      state.updateBusiness = {
        isLoading: true,
      };
    },
    setUpdateBusinessScuccess: (state) => {
      state.updateBusiness = {
        isUpdated: true
      };
    },
    setUpdateBusinessFailure: (state, action) => {
      state.updateBusiness = {
        error: action.payload,
      }
    },

    cleanUpdateBusinessStore: (state) => {
      state.updateBusiness = {}
    },

    // Delete a Business

    setDeleteBusinessLoading: (state) => {
      state.deleteBusiness = {
        isLoading: true,
      };
    },
    setDeleteBusinessScuccess: (state) => {
      state.deleteBusiness = {
        isDeleted: true
      };
    },
    setDeleteBusinessFailure: (state, action) => {
      state.deleteBusiness = {
        error: action.payload,
      }
    },

    cleanDeleteBusinessStore: (state) => {
      state.deleteBusiness = {}
    },

    // Get Business Users

    setGetBusinessUsersLoading: (state) => {
      state.businessUsers = {
        isLoading: true,
      };
    },
    setGetBusinessUsersScuccess: (state, action) => {
      state.businessUsers = {
        data: action.payload
      };
    },
    setGetBusinessUsersFailure: (state, action) => {
      state.businessUsers = {
        error: action.payload,
      }
    },

    // Update Client user Wallet

    setUpdateAClientUserWalletLoading: (state) => {
      state.updateAClientUserWallet = {
        isLoading: true,
      };
    },
    setUpdateAClientUserWalletScuccess: (state) => {
      state.updateAClientUserWallet = {
        isUpdated: true
      };
    },
    setUpdateAClientUserWalletFailure: (state, action) => {
      state.updateAClientUserWallet = {
        error: action.payload,
      }
    },

    cleanUpdateAClientUserWalletStore: (state) => {
      state.updateAClientUserWallet = {}
    },

  },
})

export const { 
  setGetBusinessLoading, setGetBusinessScuccess, setGetBusinessFailure,
  setGetBusinessDetailLoading, setGetBusinessDetailScuccess, setGetBusinessDetailFailure,
  setGetCompaniesLoading, setGetCompaniesScuccess, setGetCompaniesFailure,
  setCreateBusinessLoading, setCreateBusinessScuccess, setCreateBusinessFailure, cleanCreateBusinessStore,
  setUpdateBusinessLoading, setUpdateBusinessScuccess, setUpdateBusinessFailure, cleanUpdateBusinessStore,
  setDeleteBusinessLoading, setDeleteBusinessScuccess, setDeleteBusinessFailure, cleanDeleteBusinessStore,
  setGetBusinessUsersLoading, setGetBusinessUsersScuccess, setGetBusinessUsersFailure,
  setUpdateAClientUserWalletLoading, setUpdateAClientUserWalletScuccess, setUpdateAClientUserWalletFailure, cleanUpdateAClientUserWalletStore,
} = businessSlice.actions

export default businessSlice.reducer;