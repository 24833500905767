import React, { useEffect } from 'react'

import './Styles.scss';

export default function Modal({show, title, children}) {

  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : 'auto';
  }, [show])
  
  return (
    show ? (
    <div className='modal'>
        <div className='modal-inner'>
            <div className='modal-heading'>
                <h1>{title}</h1>
            </div>
            <div className='modal-content'>
                {children}
            </div>
        </div>
    </div>
    ) : (null)
  )
}
