import React, { useState, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faCaretLeft, faCaretRight, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import ToggleAccountsListColumnsButton from '../../UI/ToggleAccountsListColumnsButton/ToggleAccountsListColumnsButton';

// Utilities

import { formatCurrency } from '../../../utilities/helper-functions';

import './Styles.scss';

export default function ClientAccountsList({data, reconciliation, currentMonth, setCurrentMonth, openEditClienAccounttModal, longestBalancesArray, setLongestBalancesArray}) {
    const user = useSelector((state) => state.auth.user);
    const userPermissions = user?.data?.permissionsData;
    const showAllAccountsListColumns = useSelector((state) => state.settings.showAllAccountsListColumns);
    const [showFlow, setShowFlow] = useState(false);

    const getlongestBalancesArray =() => {
        const length = Math.max.apply(Math,data.map(function(o){return o.balances.length;}));
        const obj = data.find(function(x){ return x.balances.length == length;});

        setLongestBalancesArray(obj);
    }

    useEffect(() => {
        getlongestBalancesArray()
    }, [getlongestBalancesArray])
    
  return (
    <div className='container'>

        <div className='client-account-list-tabs'>
            <button
                className={!showFlow ? 'client-account-list-tab client-account-list-tab-active' : 'client-account-list-tab'}
                onClick={() => setShowFlow(!showFlow)}
                >
                Saldos de Cuentas
            </button>

            {userPermissions.VisualizarFlujosDeCajas &&
            <button
                className={showFlow ? 'client-account-list-tab client-account-list-tab-active' : 'client-account-list-tab'}
                onClick={() => setShowFlow(!showFlow)}
            >
                Flujo de Caja
            </button>}
        </div>

        <div className={`client-accounts-list ' ${showAllAccountsListColumns ? 'client-accounts-list-expanded' : ''}`}>

            <table>
                <thead>
                    <tr className='client-accounts-list-headers'>
                        <th>Cuenta</th>
                        <th>Tipo de Cuenta</th>
                        {showAllAccountsListColumns && <th>Estructura Activo/Pasivo</th>}
                        {showAllAccountsListColumns && <th>Cuenta EEFF</th>}
                        {showAllAccountsListColumns && <th>Partida G&P</th>}
                        {showAllAccountsListColumns && <th>Cuentas de Análisis</th>}
                        {showAllAccountsListColumns && <th>Tipo de gasto</th>}
                        <th>Partida flujo</th>
                        <th className='list-header-balance'>
                            {longestBalancesArray?.balances &&
                            <>
                            <button 
                                className='btn-account-balance' 
                                disabled={currentMonth === 0} 
                                onClick={() => setCurrentMonth(currentMonth - 1)}
                            >
                                <FontAwesomeIcon className='btn-account-balance-icon' icon={faCaretLeft} />
                            </button>

                            <p>{longestBalancesArray?.balances[currentMonth]?.title}</p>

                            <button
                                className='btn-account-balance'
                                disabled={currentMonth === (longestBalancesArray?.balances?.length - 1)}
                                onClick={() => setCurrentMonth(currentMonth + 1)}
                            >
                                <FontAwesomeIcon className='btn-account-balance-icon' icon={faCaretRight} />
                            </button>
                            </>}
                        </th>
                        {userPermissions.VisualizarFlujosDeCajas && showFlow && <th>Flujo de Caja</th>}
                        {userPermissions.VisualizarYModificarClasificacionesDeCategoriasCampos &&
                        <th>
                            <ToggleAccountsListColumnsButton />
                        </th>}
                    </tr>
                </thead>
                
                <tbody>

                    {data && data?.map((entry, i) => (
                        <ClientAccountEntry
                            key={i}
                            entry={entry}
                            currentMonth={currentMonth}
                            action={openEditClienAccounttModal}
                            userPermissions={userPermissions}
                            showAllAccountsListColumns={showAllAccountsListColumns}
                            showFlow={showFlow}
                        />
                    ))}

                </tbody>

                {reconciliation &&
                <tfoot>
                    <tr className={reconciliation[currentMonth]?.sum === 0 ? 'client-accounts-reconciliation' : 'client-accounts-reconciliation reconcicliation-error'}>
                        <td className='reconciliation-name'>Reconclliación</td>
                        <td></td>
                        <td></td>
                        {showAllAccountsListColumns && <td></td>}
                        {showAllAccountsListColumns && <td></td>}
                        {showAllAccountsListColumns && <td></td>}
                        {showAllAccountsListColumns && <td></td>}
                        {showAllAccountsListColumns && <td></td>}
                        {reconciliation[currentMonth]?.sum !== null ? (
                            <td className='reconciliation-balance'>${reconciliation[currentMonth]?.sum}</td>
                        ) : (
                            <td className='reconciliation-balance'>Sin monto</td>
                        )}
                        {userPermissions.VisualizarFlujosDeCajas && showFlow && <td></td>}
                        {userPermissions.VisualizarYModificarClasificacionesDeCategoriasCampos &&
                        <td></td>}
                    </tr>
                </tfoot>}
                
            </table>

        </div>

    </div>
  )
}

function ClientAccountEntry ({entry, currentMonth, action, userPermissions, showAllAccountsListColumns, showFlow}) {
    const [showIssues, setShowIssues] = useState(false);

    return (
        <tr className='client-account-entry'>
            <td className='account-name'>
                <div className='account-name-inner'>
                    {entry.logs?.length > 0 &&
                    <button onClick={() => setShowIssues(!showIssues)} className='show-account-issues-button'>
                        <FontAwesomeIcon className='account-issue-icon' icon={faCircleExclamation} />
                    </button>}
                    <div>
                        <p><strong>{entry.description}</strong></p>
                        <p><span>{entry.account}</span></p>
                    </div>

                </div>

                {showIssues &&
                <ul className='account-issues-list'>
                    {entry.logs?.length > 0 && entry.logs?.map((entry, i) => (
                        <li key={i} className='account-issue'>{entry?.message}</li>
                    ))}
                </ul>}
            </td>
            {showAllAccountsListColumns && <td className='account-clasification'><p>{entry.account_classification}</p></td>}
            <td className='account-structure'><p>{entry.active_passive_si}</p></td>
            {showAllAccountsListColumns && <td className='account-eeff'><p>{entry.account_eeff}</p></td>}
            {showAllAccountsListColumns && <td className='account-gp'><p>{entry.partida_gp}</p></td>}
            {showAllAccountsListColumns && <td className='account-fiscal-risk'><p>{entry.tax_risk}</p></td>}
            {showAllAccountsListColumns && <td className='account-type'><p>{entry.fixed_variable_ex_type}</p></td>}
            <td className='account-flow'><p>{entry.flow_heading}</p></td>
            <td className='account-balance'>
                <p className={entry.balances[currentMonth]?.balance < 0 ? 'negative-balance' : ''}>
                    <strong>
                        {entry.balances[currentMonth]?.balance ? formatCurrency(entry.balances[currentMonth]?.balance) : formatCurrency(0)}
                    </strong>
                </p>
            </td>
            {userPermissions.VisualizarFlujosDeCajas && showFlow &&
                <td className='account-balance account-balance-cashflow'>
                    <p className={entry.balances[currentMonth]?.cashflow < 0 ? 'negative-cashflow' : ''}>
                        <strong>
                            {entry.balances[currentMonth]?.cashflow ? formatCurrency(entry.balances[currentMonth]?.cashflow) : formatCurrency(0)}
                        </strong>
                    </p>
                </td>
            }
            {userPermissions.VisualizarYModificarClasificacionesDeCategoriasCampos &&
            <td className='account-edit'>
                <button className='btn-edit-account-entry' onClick={() => action(entry)}>
                    <FontAwesomeIcon className='btn-edit-account-entry-icon' icon={faPenToSquare} />
                </button>
            </td>}
        </tr>
    )
}